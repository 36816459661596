import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';

import Select from 'components/Select';
import Button from 'components/Button';
import { capitalize } from 'utils/capitalize';
import { cpfMask, valueToCurrency } from 'utils/mask';
import { useAuth } from 'hooks/auth';
import { useSubscriptions } from 'hooks/subscriptions';

import { chackoutPaymentSchema } from 'validations';

import { ICards } from 'hooks/cards/types';
import { format, parseISO } from 'date-fns';
import { useContract } from 'hooks/contracts';
import Loading from 'components/Loading';
import { IEmployees } from 'hooks/employees/types';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Container, Divider, ContainerButton } from './styles';

interface IPayment {
  contractCustomer: any;
  children?: any;
  cards: ICards[] | undefined;
  employees: IEmployees[];
}

const Purchase: React.FC<IPayment> = ({
  contractCustomer,
  cards,
  employees,
}) => {
  const { loadingContractCustomer } = useContract();
  const { handlePayment, errorPayment } = useSubscriptions();
  const { user } = useAuth();
  const [showError, setShowError] = useState(false);
  const [cardActive, setCardActive] = useState<ICards | undefined>();
  const [loadingPayment, setLoadingPayment] = useState(false);

  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    setShowError(false);
    if (cards !== undefined) {
      setCardActive(cards.find(item => item.isActive === true));
    }
  }, [cards]);

  const handleFinalyPayment = async (values: any) => {
    if (!cardActive) {
      setShowError(true);
    } else {
      await handlePayment(values);
      setLoadingPayment(true);

      if (errorPayment === undefined) {
        addToast('Assinatura contratado com sucesso!', {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push('/signatures');
      }
    }
  };

  const formikPayment = useFormik({
    initialValues: {
      employed: '',
    },
    validationSchema: chackoutPaymentSchema,
    onSubmit: value =>
      handleFinalyPayment({
        ...value,
        ...cardActive,
        identifier: contractCustomer.customer.identifier,
        plan: contractCustomer.plan.id,
      }),
  });

  return (
    <Container>
      <form onSubmit={formikPayment.handleSubmit}>
        {loadingContractCustomer ? (
          <Loading />
        ) : (
          Object.keys(contractCustomer).length > 0 && (
            <div>
              <div className="unit">
                <h3>Unidade</h3>
                <p>{user.email}</p>
              </div>
              <Divider />
              <Select
                name="employed"
                label="Selecione uma funcionária"
                onChange={formikPayment.handleChange}
                error={formikPayment.errors.employed}
              >
                <option value="">...</option>
                {employees.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>

              <Divider />
              <div className="customer-data">
                <h3>Dados do(a) cliente</h3>
                <p>Nome: {contractCustomer.customer.name}</p>
                <p>CPF: {cpfMask(contractCustomer.customer.identifier)}</p>
              </div>

              <Divider />
              <div className="card-data">
                <h3>Dados do cartão</h3>
                {cardActive !== undefined ? (
                  <div className="data">
                    <p>
                      Nome: <span>{capitalize(cardActive.name)}</span>{' '}
                    </p>
                    <p>
                      Final do cartão: <span>{cardActive.cardNumberLast}</span>{' '}
                    </p>
                    <p>
                      Vencimento:{' '}
                      <span>
                        {format(parseISO(cardActive.cardExpiration), 'MM/yyyy')}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>Não há cartão habilitado</p>
                )}
              </div>

              <Divider />
              <div className="plan-detail">
                <h3>Plano Selecionado</h3>
                <div className="detail-content">
                  <div className="plan-description">
                    <p>{contractCustomer.plan.name}</p>
                    {contractCustomer.plan.services.map((item: any) => (
                      <ul>
                        <li>
                          {item.quantity} - {item.name}
                        </li>
                      </ul>
                    ))}
                  </div>
                  <div className="values-content">
                    <div className="values original-price">
                      <p>Preço original</p>
                      <span className="dotted" />
                      <span>
                        {valueToCurrency(contractCustomer.plan.original_price)}
                      </span>
                    </div>
                    <div className="values discount">
                      <p>Desconto de 25%</p>
                      <span className="dotted" />
                      <span>
                        {valueToCurrency(
                          contractCustomer.plan.price -
                            contractCustomer.plan.original_price,
                        )}
                      </span>
                    </div>
                    <div className="values price">
                      <p>Total a pagar</p>
                      <span className="dotted" />
                      <span>
                        {contractCustomer.plan.price &&
                          valueToCurrency(contractCustomer.plan.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {showError && (
                <div
                  className="cardError"
                  style={{
                    color: 'red',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <p>Cartão não preenchido.</p>
                </div>
              )}
            </div>
          )
        )}

        <ContainerButton>
          <Button type="submit">Finalizar</Button>
        </ContainerButton>
      </form>
    </Container>
  );
};

export default Purchase;
