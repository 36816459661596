import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  min-width: 830px;

  .header {
    display: flex;
    justify-content: space-between;

    .search {
      width: 600px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .btncontent {
    display: flex;
    align-items: center;
  }

  .input {
    width: 250px;
    margin-left: 10px;
  }
`;

export const ContainerTab = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  .form-client-body {
    :hover {
      background-color: #f9f9f9;
    }
  }

  .see-more-icon {
    cursor: pointer;
    :hover {
      color: #39a2db;
    }
  }
`;
