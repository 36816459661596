import { format, getHours, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import { cpfMask, cnpjMask, valueToCurrency } from './mask';

function replacePlanDetails({ plan, servicesName }: any): string {
  const isInfinitePlan = plan.timeLimit === 0;
  if (isInfinitePlan && servicesName.length === 1) {
    return `01 (um) serviço de ${servicesName[0]}`;
  }
  if (isInfinitePlan && servicesName.length === 2) {
    return `01 (um) serviço de ${servicesName[0]} e ${servicesName[1]}`;
  }
  if (isInfinitePlan && servicesName?.length === 3) {
    return `01 (um) serviço de ${servicesName[0]}, ${servicesName[1]}, ${servicesName[2]} e ${servicesName[3]}`;
  }
  if (isInfinitePlan && servicesName?.length >= 4) {
    return `01 (um) serviço de ${servicesName[0]}, ${servicesName[1]}, ${servicesName[2]} e ${servicesName[3]}`;
  }

  if (servicesName?.length === 1) {
    return `03 (três) serviço de ${servicesName[0]}`;
  }

  if (servicesName?.length === 2) {
    if (servicesName[1]?.includes('1º')) {
      return `03 (três) serviço de ${servicesName[0]} e 01 (um) serviço de ${servicesName[1]}`;
    }
    return `03 (três) serviço de ${servicesName[0]} e 03 (três) serviço de ${servicesName[1]}`;
  }
  if (servicesName?.length >= 3) {
    return `03 (três) serviço de ${servicesName[0]}, 03 (três) serviço de ${servicesName[1]}, 01 (um) serviço de ${servicesName[2]} e 01 (um) serviço de ${servicesName[3]}`;
  }
  return ''
}

function replacePlanUniqueService(servicesName: string[]): string {
  const uniqueServices = servicesName.filter((item: string) => !item.includes('1º'))
  return `01 (um) serviço de ${uniqueServices.join(', ')}`
}

export function contractTemplate({
  template,
  plan,
  servicesName,
  customerContract,
  createdAt,
  customer,
  user,
  unit,
  acceptAt,
  companyName,
}: any) {
  let insertDataContract = template;

  const cityUnit = '';
  const createdAtContract = format(
    parseISO(createdAt),
    "dd 'de' MMMM 'de' yyyy",
    {
      locale: ptLocale,
    },
  );
  const { log } = console;
  log({ plan, servicesName });
    console.log(plan, servicesName)
  insertDataContract = insertDataContract.replaceAll(
    '{{planDetails}}',
    replacePlanDetails({ plan, servicesName }).toLowerCase()
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planUniqueService}}',
    replacePlanUniqueService(servicesName).toLowerCase()
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planTimeLimit}}',
    plan.timeLimit,
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planService}}',
    servicesName.join(' + ').toLowerCase(),
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{priceValue}}',
    valueToCurrency(plan.price).toString(),
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{planPrice}}',
    valueToCurrency(plan.price).toString(),
  );

  // 12.4. As Partes elegem o foro da comarca de
  insertDataContract = insertDataContract.replaceAll(
    '{{unitLocalizationCityState}}',
    cityUnit || '____________________________',
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{unitCity}}',
    cityUnit || '____________________________',
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{userName}}',
    customerContract.name,
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{userIdentifier}}',
    cpfMask(customerContract.identifier),
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{date}}',
    `${cityUnit} ${createdAtContract}`,
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{unitName}}',
    unit.name || '_____________________________',
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{unitCnpj}}',
    cnpjMask(unit.cnpj) || '______________________________',
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{unitCompanyName}}',
    unit.companyName || '______________________________',
  );


  insertDataContract = insertDataContract.replaceAll(
    '{{unitFiscalNumber}}',
    cnpjMask(unit.cnpj) || '______________________________',
  );

  const userAcceptAt = acceptAt ? format(parseISO(acceptAt),"dd'/'MM'/'yyyy HH':'MM':'SS ")  : '______________________________';
  insertDataContract = insertDataContract.replaceAll(
    '{{useracceptAt}}',
    userAcceptAt,
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{unitcreatedAt}}',
    cnpjMask(createdAt) || '______________________________',
  );

  insertDataContract = insertDataContract.replaceAll(
    '{{userAddress}}',
    customer.address,
  );

  return insertDataContract;
}
