import React from 'react';

import Navigation from '../navigation';
import DataCustomer from '../pages/dataCustomer';

import { Container, Body, Main } from './styles';

const PageLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <Navigation />
      <Main>
        <DataCustomer />
        <Body>{children}</Body>
      </Main>
    </Container>
  );
};

export default PageLayout;
