import React from 'react';

import Contract from 'components/Contract';

import { Container } from './styles';

interface IModalPrintContract {
  openModal?: any;
  contractHash: string;
}

const ModalPrintContract: React.FC<IModalPrintContract> = ({
  openModal,
  contractHash,
}) => {
  return (
    <Container>
      <Contract hash={contractHash} openModal={openModal} />
    </Container>
  );
};

export default ModalPrintContract;
