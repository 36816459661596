import React from 'react';
import { cpfMask } from 'utils/mask';
import { t } from 'ab18n';
import { useSubscriptions } from 'hooks/subscriptions';
import { capitalize } from 'utils/capitalize';
import Loading from 'components/Loading';
import { format, parseISO } from 'date-fns';
import { ButtonStatus } from 'components/Button/styles';
import { Container, Grid, ContentStatus } from './styles';

const DataCustomer: React.FC = () => {
  const {
    subscription,
    loadingUpdate,
    loadingModalDetail,
  } = useSubscriptions();

  const id = Number(localStorage.getItem('@ClubSd:subscriptionId'));

  return (
    <Container>
      {subscription.id !== id || loadingUpdate || loadingModalDetail ? (
        <Loading />
      ) : (
        Object.keys(subscription).length > 0 && (
          <Grid>
            <div className="content--tab">
              <span>
                Nome: <p>{capitalize(subscription.customer.name)}</p>
              </span>
              <span>
                CPF: <p>{cpfMask(subscription.customer.identifier)}</p>
              </span>
            </div>
            <div className="content--tab">
              <span style={{ marginBottom: '-5px' }}>
                Plano: <p>{subscription.plan?.name}</p>
              </span>
              <span>
                {!subscription.isInfinite && (
                  <div className="due-payment">
                    <span>
                      {' '}
                      Vencimento:{' '}
                      <p>
                        {format(parseISO(subscription.dueDate), 'dd-MM-yyyy')}
                      </p>
                    </span>
                  </div>
                )}
              </span>
            </div>
            <ContentStatus>
              <span className="id">
                # <p>{subscription.id}</p>
              </span>
              <span>
                <ButtonStatus
                  color={subscription.status}
                  style={{ width: '100px' }}
                >
                  {t(`pages.subscription.status.${subscription.status}`)}
                </ButtonStatus>
              </span>
            </ContentStatus>
          </Grid>
        )
      )}
    </Container>
  );
};

export default DataCustomer;
