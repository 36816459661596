import React, { useEffect } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { Container, Content } from './styles';

interface IModal {
  children: any;
  status: boolean;
  handleClose?: any;
  width?: string;
}

const Modal: React.FC<IModal> = ({
  children,
  status,
  handleClose,
  width = '700px',
}) => {
  useEffect(() => {
    const onEsc = (e: any) => {
      if (e.keyCode === 27) handleClose();
    };

    window.addEventListener('keydown', onEsc);

    return () => {
      window.removeEventListener('keydown', onEsc);
    };
  }, [handleClose]);

  const onClickContainerModal = () => {
    handleClose();
  };

  const onClickContentModal = (e: any) => {
    e.stopPropagation();
  };

  return (
    <Container width={width} status={status} onClick={onClickContainerModal}>
      <Content width={width} status onClick={onClickContentModal}>
        <Zoom className="zoom" duration={650} fraction={0}>
          {children}
        </Zoom>
      </Content>
    </Container>
  );
};

export default Modal;
