import React, { createContext, useCallback, useState, useContext } from 'react';

export interface UnitSelectedContextData {
  unitSelected: string;
  handleSelectedUnit(email: string): void;
}

const UnitSelectedContext = createContext<UnitSelectedContextData>(
  {} as UnitSelectedContextData,
);

const UnitSelectedProvider: React.FC = ({ children }) => {
  const [unitSelected, setUnitSelected] = useState(() => {
    const unit = localStorage.getItem('@ClubSdAdmin:unitSelected');

    if (unit) {
      return unit;
    }
    return '';
  });

  const handleSelectedUnit = useCallback(email => {
    if (!email) {
      localStorage.removeItem('@ClubSdAdmin:unitSelected');
      setUnitSelected('');
      return;
    }
    localStorage.setItem('@ClubSdAdmin:unitSelected', email);
    setUnitSelected(email);
  }, []);

  return (
    <UnitSelectedContext.Provider
      value={{
        unitSelected,
        handleSelectedUnit,
      }}
    >
      {children}
    </UnitSelectedContext.Provider>
  );
};

function useUnitSelected(): UnitSelectedContextData {
  const context = useContext(UnitSelectedContext);

  if (!context) {
    throw new Error(
      ' useUnitSelected must be used within an UnitSelectedProvider ',
    );
  }
  return context;
}
export { UnitSelectedProvider, useUnitSelected };
