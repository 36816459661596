import styled from '@emotion/styled';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;

  .lottie {
    width: 20%;
  }

  @media (min-width: 320px) {
    padding-top: 30%;
    .lottie {
      width: 70%;
    }
  }

  @media (min-width: 600px) {
    padding-top: 30%;
    .lottie {
      width: 50%;
    }
  }

  @media (min-width: 801px) {
    .lottie {
      width: 50%;
    }
  }

  @media (min-width: 1024px) {
    padding-top: 20%;
    .lottie {
      width: 30%;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 10%;
    .lottie {
      width: 20%;
    }
  }
`;
