import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

interface ISwitche {
  name: string;
  handleChange: any;
  label: string;
  value: boolean;
}

const Switche: React.FC<ISwitche> = ({ name, handleChange, label, value }) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    </div>
  );
};

export default Switche;
