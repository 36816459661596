import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IProps {
  heigth?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    button + button {
      margin-left: 8px;
    }
  }

  .customer-unit-selected {
    display: flex;
    padding: 10px;

    span {
      margin-left: 10px;
    }
  }

  .btn-check {
    cursor: pointer;

    :hover {
      color: #39a2db;
    }
  }

  .form-control {
    margin: 15px 0;
  }

  .form-control-send {
    display: flex;
    margin: 15px 0;

    .form-email,
    .form-sms {
      display: flex;
      flex-direction: column;
    }

    .form-email {
      margin-right: 30px;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  .grid {
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .description {
      display: flex;
      flex-direction: column;

      span {
        font-size: 11px;
      }

      .total {
        text-align: right;
        font-size: 36px;
        font-weight: bold;
      }

      .green {
        color: #00b300;
      }

      .red {
        color: #ff4d4d;
      }

      .blue {
        color: #4d94ff;
      }
    }
  }
`;

export const Content = styled.div`
  background: transparent;
  border-radius: 8px;
  margin-top: 20px;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .card-plan {
      background-color: #fff;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      position: relative;
      flex-direction: column;
      cursor: pointer;

      .hover {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(21, 151, 229, 0.8);
        z-index: 1;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.5s;
        border-radius: 8px;

        :hover {
          opacity: 1;
        }

        .icon-add {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          width: 60px;
          height: 60px;

          svg {
            width: 70%;
            height: 70%;
            transition: all ease 0.3s;
            color: rgba(255, 255, 255, 0.8);

            :hover {
              width: 100%;
              height: 100%;
              color: #fff;
            }
          }
        }

        span {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }
      }

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .plan-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .detail {
          margin-top: 8px;

          .green-price {
            font-weight: bold;
            font-size: 21px;
          }
        }
      }

      .table {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        .tab-info {
          display: flex;
          flex-direction: column;
          min-width: 400px;
          border: 1px solid #ccc;
          padding: 40px 30px;
          border-radius: 12px;
          position: relative;

          .service {
            position: absolute;
            top: -15px;
            background-color: #fff;
            border: 5px solid #fff;
          }
        }

        span {
          margin-bottom: 5px;
        }
      }
    }
  }

  .btn-action {
    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }
`;

export const ContentCustomer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-check {
    cursor: pointer;

    :hover {
      color: #39a2db;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .btncontent {
    display: flex;
    align-items: center;
  }

  .input {
    width: 250px;
    margin-left: 10px;
  }
`;

interface IPropsFound {
  display: string;
}

export const NotFound = styled.div<IPropsFound>`
  width: 100%;
  justify-content: center;
  margin-top: 100px;
  font-size: 22px;

  ${props =>
    props.display &&
    css`
      display: ${`${props.display}`};
    `}
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 15px;
`;

export const SubTitle = styled.div`
  margin-top: 15px;
`;

export const SendForm = styled.div``;
