import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom';

import Create from "../pages/Signature/create";
import Dashboard from "../pages/Dashboard";
import Signature from "../pages/Signature";
import SubscriptionDetail from "../pages/Signature/details";
import Purchase from "../pages/Purchase";
import Plans from "../pages/Plans";
import PlansNewPage from "../pages/PlansNewPage";
import Customer from "../pages/Customer";
import CustomerId from "../pages/Customer/Show";
import ContractNotFound from "../pages/NotFound";
import Contract from "../pages/Contract";

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Create} />
      <Route path="/unit/:id" exact component={Dashboard} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/signatures" exact component={Signature} />
      <Route
        path="/signature/detail"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/revenue"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/visits"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/fees"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/refunds"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/cards"
        exact
        component={SubscriptionDetail}
      />
      <Route
        path="/signature/renews"
        exact
        component={SubscriptionDetail}
      />
      <Route path="/signatures/create" exact component={Create} />
      <Route path="/purchase/:hash" component={Purchase} />
      <Route path="/plans" component={Plans} />
      <Route path="/plansnew" component={PlansNewPage} />
      <Route path="/customers" component={Customer} />
      <Route path="/customer/:id" component={CustomerId} />
      <Route path="/contracts" component={Contract} />
      <Route path="/404" component={ContractNotFound} />
      <Redirect to="/" />
    </Switch>
  )
}

export default App
