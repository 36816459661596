import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { t } from 'ab18n';

import Button from 'components/Button';
import Input from 'components/Input';
import DateRange from 'components/Date/range';
import Select from 'components/Select';

import { useFormik } from 'formik';
import { cpfMask } from 'utils/mask';
import { queryParams, queryObjectURI } from 'queryparamsuri';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import { IEmployees } from 'hooks/employees/types';
import { startOfMonth, endOfToday, format } from 'date-fns';
import { Container } from './styles';

interface ISearch {
  handleSearch: any;
  setPage: any;
  optionEmployees: IEmployees[];
  dataRange: any[];
}

const Search: React.FC<ISearch> = ({
  handleSearch,
  setPage,
  optionEmployees,
  dataRange,
}) => {
  const { user } = useAuth();

  const optionsStatus = [
    {
      status: '',
      name: 'Todos',
    },
    {
      status: 'success',
      name: 'Ativo',
    },
    {
      status: 'delayed',
      name: 'Atrasado',
    },
    {
      status: 'canceled',
      name: 'Cancelado',
    },
    {
      status: 'expired',
      name: 'Concluído',
    },
    {
      status: 'renew',
      name: 'Renovado',
    },
    {
      status: 'canceled_by_not_renew',
      name: t('pages.subscription.status.canceled_by_not_renew')
    },
    {
      status: 'canceled_by_delayed',
      name: t('pages.subscription.status.canceled_by_delayed')
    },
    {
      status: 'canceled_by_not_visit',
      name: t('pages.subscription.status.canceled_by_not_visit')
    }
  ];

  const optionsPlataform = [
    {
      status: '',
      name: 'Todas',
    },
    {
      status: 1,
      name: 'Personnalite',
    },
    {
      status: 2,
      name: 'Portal SD Lover',
    },
  ];

  const { searchURI, page, limit, query } = queryParams({
    params: [
      'customer_identifier',
      'status',
      'unit_email',
      'gateway_id',
      'employees',
      'initial_date',
      'final_date',
    ],
  });

  useEffect(() => {
    handleSearch({
      data: {
        ...query,
        page,
        limit,
      },
    });
  }, [searchURI]); //  eslint-disable-line

  const history = useHistory();

  const handleSearchSubscriptions = (values: any) => {
    setPage(1);
    const params = queryObjectURI(values);
    history.push(params);
  };

  const startMonth = startOfMonth(new Date());
  const endToday = endOfToday();

  const initial_date =
    dataRange[0] === null
      ? format(startMonth, 'yyyy-MM-dd')
      : format(dataRange[0], 'yyyy-MM-dd');
  const final_date =
    dataRange[1] === null
      ? format(endToday, 'yyyy-MM-dd')
      : format(dataRange[1], 'yyyy-MM-dd');

  const formik = useFormik({
    initialValues: user.roles.includes('ROLE_ADMIN')
      ? {
          customer_identifier: '' || query.customer_identifier,
          status: '',
          plataform: '',
          unit_email: '',
          gateway_id: '',
          employees: '',
          initial_date,
          final_date,
        }
      : {
          customer_identifier: '' || query.customer_identifier,
          status: '',
        },
    onSubmit: value => handleSearchSubscriptions(value),
  });

  const handleCleanFiltered = () => {
    const params = queryObjectURI('');
    history.push(params);
    window.location.reload();
  };

  return (
    <Container>
      <div className="content">
        <form onSubmit={formik.handleSubmit}>
          <div className="identifier">
            <Input
              size="small"
              label="CPF"
              name="customer_identifier"
              value={cpfMask(formik.values.customer_identifier)}
              onChange={formik.handleChange}
            />
          </div>

          {user.roles.includes('ROLE_ADMIN') && (
            <div className="unit" style={{ width: '150%' }}>
              <Input
                size="small"
                label="Unidade"
                name="unit_email"
                value={formik.values.unit_email}
                onChange={formik.handleChange}
              />
            </div>
          )}

          {user.roles.includes('PROXIMA_VERSAO') && (
            <div className="employees" style={{ width: '90%' }}>
              <Select
                size="small"
                name="employees"
                label="Funcionários"
                onChange={formik.handleChange}
              >
                <option value="">Todos</option>
                {optionEmployees.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          )}

          {user.roles.includes('PROXIMA_VERSAO') && (
            <div className="plataform" style={{ width: '90%' }}>
              <Select
                size="small"
                name="plataform"
                label="Plataforma"
                onChange={formik.handleChange}
              >
                {optionsPlataform.map(item => (
                  <option key={item.name} value={item.status}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div className="status" style={{ width: '80%' }}>
            <Select
              size="small"
              name="status"
              label="Status"
              onChange={formik.handleChange}
            >
              {optionsStatus.map(item => (
                <option key={item.name} value={item.status}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          {/* <div className="data--range" style={{ width: '150%' }}>
            <DateRange viwerTo={false} />
          </div> */}

          <div className="buttons">
            <Button type="submit">
              <Tooltip title="Buscar">
                <SearchIcon />
              </Tooltip>
            </Button>

            <Button color="danger" onClick={handleCleanFiltered}>
              <Tooltip title="Limpar">
                <CloseIcon />
              </Tooltip>
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Search;
