import React from 'react';

import { useContract } from 'hooks/contracts';
import AlertSuccess from 'components/Lottie/AlertSuccess';
import LoadingCustomer from 'components/Lottie/LoadingCustomer';

const CustomerTerm: React.FC = () => {
  const { loadingContractCustomer } = useContract();

  return (
    <>
      {loadingContractCustomer ? (
        <LoadingCustomer />
      ) : (
        <AlertSuccess menseger="Termo aceito com sucesso" />
      )}
    </>
  );
};

export default CustomerTerm;
