import React from 'react';

import Card from 'components/cards';
import { ICards } from 'hooks/cards/types';
import { Container } from './styles';

interface IPayment {
  cards: ICards[];
  identifier: string;
}

const CardContainer: React.FC<IPayment> = ({ identifier, cards }) => {
  return (
    <Container>
      <Card cards={cards} identifier={identifier} />
    </Container>
  );
};

export default CardContainer;
