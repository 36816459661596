import styled from '@emotion/styled';

export const Conteiner = styled.div`
  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 10px;
    }
  }
`;
export const Content = styled.div`
  .signature-customer {
    display: flex;
    width: 500px;
    justify-content: center;
    border-top: 1px solid #999;
    margin: 70px auto;
    padding-top: 5px;
    font-size: 13px;
    font-family: tahoma, geneva, sans-serif;
    color: rgb(0, 0, 0);
  }

  @media print {
    @page {
      margin: 20px;
    }
  }
`;
