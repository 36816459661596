import React from "react";
import {Route, Switch} from "react-router-dom";

import SelectFranchise from "../pages/SelectFranchise";

const Franchise: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SelectFranchise} />
    </Switch>
  )
};

export default Franchise;
