import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { t } from 'ab18n';

import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { useSubscriptions } from 'hooks/subscriptions';
import { ISubscriptions } from 'hooks/subscriptions/types';
import { valueToCurrency } from 'utils/mask';
import { useAuth } from 'hooks/auth';
import Button from 'components/Button';
import ButtonStatus from 'components/Button/btnStatus';

import Modal from 'components/Modal';
import ModalCancel from './cancel';

import { Container, Card, Expand } from './styles';

interface ISignature {
  signature: ISubscriptions;
  expand: boolean;
  handleExpand(data: number): any;
}

const SignatureCard: React.FC<ISignature> = ({
  signature,
  expand,
  handleExpand,
}) => {
  const servicesMap = signature.customerService.map(item => item.service);
  const uniqueServices = servicesMap.filter((item, pos) => {
    return servicesMap.indexOf(item) === pos;
  });
  const [isVisible, setIsVisible] = useState(false);
  const [subscription, setSubscription] = useState<ISubscriptions>(
    {} as ISubscriptions,
  );
  const { update } = useSubscriptions();
  const { user } = useAuth();

  const handleOpenModal = (data: any) => {
    setIsVisible(!isVisible);
    setSubscription(data);
  };

  const handleClose = () => {
    setIsVisible(!isVisible);
  };

  const handleCancelSignature = async ({ values }: any) => {
    await update({ data: values, id: subscription.id });
    setIsVisible(!isVisible);
  };

  return (
    <>
      <Container>
        <Card>
          <div className="top">
            <span>
              <h2>{signature.plan.name}</h2>
            </span>
            <span>
              <p style={{ fontSize: 22, marginBottom: 5 }}>
                <p>{uniqueServices.join(' + ')}</p>
              </p>
            </span>
          </div>
          <div className="top">
            <span className="due-date">
              <span>
                Início: {format(parseISO(signature.createdAt), 'dd-MM-yyyy')}
              </span>
              <span>
                Vencimento: {format(parseISO(signature.dueDate), 'dd-MM-yyyy')}
              </span>
            </span>
            <span className="btn-status">
              {signature.status === 'finished' && (
                <ButtonStatus color="finished" className="btn margin-btnstatus">
                  {t(`pages.signatures.status.${signature.status}`)}
                </ButtonStatus>
              )}
              {signature.status === 'delayed' && (
                <ButtonStatus color="delayed" className="btn margin-btnstatus">
                  {t(`pages.signatures.status.${signature.status}`)}
                </ButtonStatus>
              )}
              {signature.status === 'success' && (
                <ButtonStatus color="success" className="btn margin-btnstatus">
                  {t(`pages.signatures.status.${signature.status}`)}
                </ButtonStatus>
              )}
              {signature.status === 'expired' && (
                <ButtonStatus color="expired" className="btn margin-btnstatus">
                  {t(`pages.signatures.status.${signature.status}`)}
                </ButtonStatus>
              )}
              {signature.status === 'refunded' && (
                <span>
                  <ButtonStatus
                    color="refunded"
                    style={{
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      borderRadius: '8px',
                      fontSize: '16px',
                    }}
                  >
                    {t(`pages.signatures.status.${signature.status}`)}
                  </ButtonStatus>
                </span>
              )}
              {signature.status === 'canceled' && (
                <span>
                  <ButtonStatus
                    color="canceled"
                    style={{
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      borderRadius: '8px',
                      fontSize: '16px',
                    }}
                  >
                    {t(`pages.signatures.status.${signature.status}`)}
                  </ButtonStatus>
                </span>
              )}
              <span>
                <Button
                  className="btn"
                  onClick={() => handleExpand(signature.id)}
                >
                  {expand ? (
                    <>
                      Ver menos detalhes <MdExpandMore />
                    </>
                  ) : (
                    <>
                      Ver mais detalhes <MdExpandLess />
                    </>
                  )}
                </Button>
              </span>
              {user.roles?.includes('ROLE_USER') &&
                signature.status === 'delayed' && (
                  <span>
                    <Button
                      color="danger"
                      className="btn"
                      onClick={() => handleOpenModal(signature)}
                    >
                      Cancelar assinatura
                    </Button>
                  </span>
                )}
              {user.roles?.includes('ROLE_USER') &&
                signature.status === 'success' && (
                  <span>
                    <Button
                      color="danger"
                      className="btn"
                      onClick={() => handleOpenModal(signature)}
                    >
                      Cancelar assinatura
                    </Button>
                  </span>
                )}
            </span>
          </div>
          <div className="top">
            <span className="green-p">
              Procedimentos realizados:{' '}
              {
                signature.customerService?.filter(
                  (item: any) => item.status !== 'enabled',
                ).length
              }{' '}
              de {signature.customerService?.length}
            </span>
          </div>
          <Expand expand={expand}>
            <div className="table">
              {signature.customerService?.filter(
                (item: any) => item.when !== null,
              ).length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td width="88%">Data da Visita</td>
                      <td className="rh-table"> </td>
                      <td align="right">status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {signature.customerService.map((item: any) => (
                      <>
                        {item.status !== 'enabled' && (
                          <tr key={item.id}>
                            <td>
                              <span className="flex">
                                {item.when
                                  ? format(parseISO(item.when), 'dd-MM-yyyy')
                                  : ''}
                              </span>
                            </td>
                            <td className="rh-table">
                              <hr />
                            </td>
                            {item.status === 'canceled' ? ( // remover validação depois, pois a base do Edmilson está imcompleta.
                              <td
                                className={
                                  item.status === 'canceled' ? 'red' : 'green'
                                }
                                align="right"
                              >
                                {t(`pages.signatures.services.${item.status}`)}
                              </td>
                            ) : (
                              ''
                            )}
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td width="88%">Data do procedimento</td>
                        <td align="right">status</td>
                      </tr>
                    </thead>
                  </table>
                  <div className="empty">Nenhum consumo.</div>
                </>
              )}
            </div>

            {signature.revenue.length > 0 && (
              <div className="table">
                <p>Detalhe de pagamento:</p>

                <table>
                  <thead>
                    <tr>
                      <td>Vencimento</td>
                      <td className="rh-payment-detail"> </td>
                      <td>Pago em</td>
                      <td className="rh-payment-detail"> </td>
                      <td>Valor</td>
                      <td className="rh-payment-detail"> </td>
                      <td style={{ width: '4%' }} align="right">
                        Status
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {signature.revenue.map((item: any) => (
                      <tr key={item.id}>
                        <td>
                          <span className="flex">
                            <span>
                              {item.scheduledDate
                                ? format(
                                    parseISO(item.scheduledDate),
                                    'dd-MM-yyyy',
                                  )
                                : ''}
                            </span>
                          </span>
                        </td>
                        <td className="rh-payment-detail">
                          <hr />
                        </td>
                        <td>
                          <span className="flex">
                            <span>
                              {item.paymentDate
                                ? format(
                                    parseISO(item.paymentDate),
                                    'dd-MM-yyy',
                                  )
                                : ''}
                            </span>
                          </span>
                        </td>
                        <td className="rh-payment-detail">
                          <hr />
                        </td>
                        <td>
                          <span className="flex">
                            <span>{valueToCurrency(item.amount)}</span>
                          </span>
                        </td>
                        <td className="rh-payment-detail">
                          <hr />
                        </td>
                        {item.status === 'scheduled' ? ( // remover validação depois, pois a base do Edmilson está imcompleta.
                          <td
                            style={{ width: '6%' }}
                            width="10%"
                            align="right"
                            className={
                              item.status === 'scheduled' ? 'orange' : 'green'
                            }
                          >
                            {t(`pages.signatures.revenue.${item.status}`)}
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Expand>
        </Card>
      </Container>
      {isVisible && (
        <Modal status={isVisible}>
          <ModalCancel
            subscription={subscription}
            handleClose={handleClose}
            handleCancelSignature={handleCancelSignature}
          />
        </Modal>
      )}
    </>
  );
};

export default SignatureCard;
