import React, { createContext, useState, useContext } from 'react';
import { useCallback } from 'react';

import api from 'services/api';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from 'hooks/auth';
import { IPlans, IPlanUpdate } from './types';

interface PlansContextData {
  loading: boolean;
  loadingPlanCustomer: boolean;
  loadingSubmitOrUpdate: boolean;
  newPlans: IPlans[];
  newPlan: IPlans;
  plan: IPlans;
  setPlan: any;
  setPlansCustomer: any;
  plansCustomer: IPlans[];
  plans: IPlans[];
  fetchPlansCustomer(identifier: string): Promise<void>;
  fetchPlans(): Promise<void>;
  fetchNewPlans(): Promise<void>;
  submit(values: IPlans): Promise<void>;
  update(values: IPlanUpdate): Promise<void>;
  show(id: number): Promise<void>;
}

const PlansContext = createContext<PlansContextData>({} as PlansContextData);

const PlansProvider: React.FC = ({ children }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [loadingPlanCustomer, setLoadingPlanCustomer] = useState(false);
  const [loadingSubmitOrUpdate, setLoadingSubmitOrUpdate] = useState(false);
  const [newPlans, setNewPlans] = useState<IPlans[]>([] as IPlans[]);
  const [newPlan, setNewPlan] = useState<IPlans>({} as IPlans);
  const [plan, setPlan] = useState<IPlans>({} as IPlans);
  const [plans, setPlans] = useState<IPlans[]>([] as IPlans[]);
  const [plansCustomer, setPlansCustomer] = useState<IPlans[]>([] as IPlans[]);
  const { user } = useAuth();

  const fetchNewPlans = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`plans`);

      setNewPlans(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlans = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('plans');

      setPlans(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlansCustomer = useCallback(async (identifier: string) => {
    try {
      setLoadingPlanCustomer(true);
      const response = await api.get(`customers/${identifier}/plans`);

      setPlansCustomer(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPlanCustomer(false);
    }
  }, []);

  const submit = useCallback(
    async (values: any) => {
      try {
        setLoadingSubmitOrUpdate(true);
        const data = {
          ...values,
          price: 52.4,
          original_price: 65.5,
          image_url: '',
        };

        const response = await api.post('plans', data);
        addToast('Plano criado com sucesso.', {
          appearance: 'success',
          autoDismiss: true,
        });

        setNewPlans([...newPlans, response.data]);
      } catch (err) {
        addToast('Algo du errado.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
        setLoadingSubmitOrUpdate(false);
      }
    },
    [addToast, newPlans],
  );

  const update = useCallback(
    async ({ data, id }: IPlanUpdate) => {
      try {
        const services = newPlans.find(item => item.id === id)?.services; // retirar depois
        setLoadingSubmitOrUpdate(true);
        const response = await api.patch(`plans/${id}`, {
          ...data,
          services,
        });

        const indexPlans = newPlans.findIndex(item => item.id === id);
        newPlans[indexPlans] = response.data;

        addToast('Atualizado com sucesso.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingSubmitOrUpdate(false);
      }
    },
    [newPlans, addToast],
  );

  const show = useCallback(async (id: number) => {
    try {
      setLoadingSubmitOrUpdate(true);
      const { data } = await api.get(`plans/${id}`);

      setNewPlan(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSubmitOrUpdate(false);
    }
  }, []);

  return (
    <PlansContext.Provider
      value={{
        fetchPlansCustomer,
        fetchNewPlans,
        submit,
        update,
        show,
        loading,
        loadingPlanCustomer,
        loadingSubmitOrUpdate,
        newPlans,
        newPlan,
        plan,
        setPlan,
        plansCustomer,
        setPlansCustomer,
        plans,
        fetchPlans,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

function usePlans(): PlansContextData {
  const context = useContext(PlansContext);

  if (!context) {
    throw new Error(' Plans must be used within an plansProvider ');
  }
  return context;
}
export { PlansProvider, usePlans };
