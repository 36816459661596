import styled from '@emotion/styled';

export const Container = styled.div`
  div + div {
    margin-left: 6px;
  }
`;

export const Error = styled.small`
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-left: 14px;
  margin-top: 5px;
`;
