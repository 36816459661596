import React from 'react';

import { Formik } from 'formik';

import { useSubscriptions } from 'hooks/subscriptions';
import { ISubscriptions } from 'hooks/subscriptions/types';

import Input from 'components/Input';
import Button from 'components/Button';
import Loading from 'components/Loading';

import { valueToCurrency } from 'utils/mask';
import { cancelSubscriptionSchema } from 'validations';
import { Container, ContainerBody, ContainerBtn } from './styles';

interface ISubscriptionCancel {
  handleClose: any;
  handleCancelSignature: any;
  subscription: ISubscriptions;
}

const ModalCancel: React.FC<ISubscriptionCancel> = ({
  handleClose,
  handleCancelSignature,
  subscription,
}) => {
  const { loadingSubscriptioUpdate } = useSubscriptions();

  return (
    <>
      {loadingSubscriptioUpdate ? (
        <Loading />
      ) : (
        <Container>
          <Formik
            initialValues={{
              cancellationReason: '',
            }}
            validationSchema={cancelSubscriptionSchema}
            onSubmit={values => handleCancelSignature({ values })}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <ContainerBody>
                  <div className="card-cancel">
                    <div className="title">Cancelar plano</div>
                    <div className="ditail">
                      <div className="plan-describe">
                        <div>
                          <span>Plano</span>
                          <h1>{subscription.plan.name}</h1>
                          <span>{subscription.plan.description}</span>
                        </div>
                        {/* <div>
                          {subscription.estimatedCancelationFee === null && (
                            <div className="value-mult">
                              <h1 style={{ color: '#58a82d' }}>
                                {subscription.estimatedCancelationFee
                                  ? valueToCurrency(
                                      subscription.estimatedCancelationFee,
                                    )
                                  : 'R$ 0,00'}
                              </h1>
                              <span>Valor a ser pago pela(o) cliente</span>
                            </div>
                          )}
                          {subscription.estimatedRefund === null && (
                            <div className="value-mult">
                              <h1 style={{ color: '#df8350' }}>
                                {subscription.estimatedCancelationFee
                                  ? valueToCurrency(
                                      subscription.estimatedCancelationFee,
                                    )
                                  : 'R$ 0,00'}
                              </h1>
                              <span>Valor a ser devolvido ao(a) cliente</span>
                            </div>
                          )}
                        </div> */}
                      </div>
                      <div className="payment-and-use">
                        <table className="table table-striped table-hover">
                          <tbody style={{ fontSize: '15px' }}>
                            <tr>
                              <td>
                                {`Utilizações: ${
                                  subscription.customerService?.filter(
                                    (item: any) => item.status !== 'enabled',
                                  ).length
                                }`}{' '}
                                de {subscription.customerService?.length}
                              </td>
                              <td>
                                {`Pagamentos: ${
                                  subscription.revenue?.filter(
                                    (item: any) => item.status === 'paid',
                                  ).length
                                }`}{' '}
                                de {subscription.revenue?.length}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="text-area">
                        <Input
                          name="cancellationReason"
                          label="Motivo do cancelamento"
                          placeholder="Motivo do cancelamento"
                          error={props.errors.cancellationReason}
                          value={props.values.cancellationReason}
                          onChange={props.handleChange}
                        />
                      </div>
                      <div />
                    </div>
                  </div>
                  <ContainerBtn>
                    <Button className="btn-exit" onClick={handleClose}>
                      Sair
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => props.validateForm()}
                      color="danger"
                      cursor={
                        props.values.cancellationReason.length === 0
                          ? 'not-allowed'
                          : 'pointer'
                      }
                    >
                      Confirmar cancelamento
                    </Button>
                  </ContainerBtn>
                </ContainerBody>
              </form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
};

export default ModalCancel;
