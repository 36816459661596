import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface IPropsRotation {
  animation?: boolean;
}

export const Container = styled.div<IPropsRotation>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2e2e2e;

  @keyframes loading {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  ${Props =>
    Props.animation &&
    css`
      animation: loading 2s linear infinite;
    `}
`;
