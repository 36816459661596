import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';

interface IProps {
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'green';
  cursor: 'pointer' | 'not-allowed';
}

interface IPropsStatus {
  color?: string;
}

export const ButtonCustom = styled.button<IProps>`
  width: 100%;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;

  ${Props =>
    Props.color === 'default' &&
    css`
      background: #9ea58a;
      color: #fff;

      &:hover {
        background: ${shade(0.3, '#9ea58a')};
      }
    `}

  ${Props =>
    Props.color === 'danger' &&
    css`
      background: #df8350;
      color: #fff;

      &:hover {
        background: ${shade(0.3, '#df8350')};
      }
    `}

  ${Props =>
    Props.color === 'secondary' &&
    css`
      background: #fff;
      color: #666;

      &:hover {
        background: ${shade(0.2, '#fff')};
      }
    `}

  ${Props =>
    Props.color === 'warning' &&
    css`
      background: #ff3333;
      color: #fff;

      &:hover {
        background: ${shade(0.3, '#ff3333')};
      }
    `}

  ${Props =>
    Props.color === 'green' &&
    css`
      background: #4aa96c;
      color: #fff;

      &:hover {
        background: ${shade(0.3, '#4aa96c')};
      }
    `}

  ${Props =>
    Props.cursor === 'pointer' &&
    css`
      cursor: pointer;
    `}

  ${Props =>
    Props.cursor === 'not-allowed' &&
    css`
      cursor: not-allowed;
    `}
`;

export const ButtonStatus = styled.button<IPropsStatus>`
  width: auto;
  padding: 2px 15px;
  font-size: 14px;
  border: none;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: default;

  ${Props =>
    Props.color === 'paid' &&
    css`
      background: #4aa96c;
    `}

  ${Props =>
    Props.color === 'agreed' &&
    css`
      background: #4aa96c;
    `}

  ${Props =>
    Props.color === 'active' &&
    css`
      background: #4aa96c;
    `}

  ${Props =>
    Props.color === 'success' &&
    css`
      background: #4aa96c;
    `}

  ${Props =>
    Props.color === 'renew' &&
    css`
      background: #688053;
    `}

  ${Props =>
    Props.color === 'finished' &&
    css`
      background: #688053;
    `}

  ${Props =>
    Props.color === 'processing' &&
    css`
      background: #688053; ;
    `}

  ${Props =>
    Props.color === 'delayed' &&
    css`
      background: #bb371a;
      color: #fff;
    `}

  ${Props =>
    Props.color === 'denied' &&
    css`
      background: #ff3333;
    `}

  ${Props =>
    Props.color === 'canceled' &&
    css`
      background: #444444;
    `}

  ${Props =>
    Props.color === 'refunded' &&
    css`
      background: #999;
    `}

  ${Props =>
    Props.color === 'expired' &&
    css`
      background: #999;
    `}

  ${Props =>
    Props.color === 'scheduled' &&
    css`
      background: #e2b213;
    `}

  ${Props =>
    Props.color === 'pending' &&
    css`
      background: #e2b213;
    `}

  ${Props =>
    Props.color === 'danger' &&
    css`
      background: #ff3333;
    `}
`;
