import React, { useEffect } from 'react';

import Card from 'components/cards';
import { useSubscriptions } from 'hooks/subscriptions';
import { useCards } from 'hooks/cards';
import { Container } from './styles';

const Cards: React.FC = () => {
  const { fetchCards, cards } = useCards();
  const { identifierCustomer } = useSubscriptions();

  useEffect(() => {
    fetchCards(identifierCustomer);
  }, [fetchCards, identifierCustomer]);

  return (
    <Container>
      <Card cards={cards} identifier={identifierCustomer} />
    </Container>
  );
};

export default Cards;
