import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IPropsStatus {
  color?: string;
}

export const Span = styled.span<IPropsStatus>`
  ${Props =>
    Props.color === 'fraud_review' &&
    css`
      color: var(--red);
    `}

  ${Props =>
    Props.color === 'canceled' &&
    css`
      color: var(--red);
    `}

  ${Props =>
    Props.color === 'denied' &&
    css`
      color: var(--red);
    `}

  ${Props =>
    Props.color === 'blocked' &&
    css`
      color: var(--red);
    `}

  ${Props =>
    Props.color === 'disabled' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'scheduled' &&
    css`
      color: var(--orange);
    `}

  ${Props =>
    Props.color === 'pending' &&
    css`
      color: var(--orange);
    `}

  ${Props =>
    Props.color === 'pending_renewal' &&
    css`
      color: var(--orange);
    `}

  ${Props =>
    Props.color === 'enabled' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'processing' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'paid' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'refund' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'used' &&
    css`
      color: var(--orange);
    `}



  ${Props =>
    Props.color === 'active' &&
    css`
      color: var(--green);
    `}

  ${Props =>
    Props.color === 'processed' &&
    css`
      color: var(--green);
    `}
`;
