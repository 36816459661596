import Button from 'components/Button';
import React from 'react';

import { useFormik } from 'formik';

import { ISubscriptions } from 'hooks/subscriptions/types';
import { useSubscriptions } from 'hooks/subscriptions';

import { cancelSubscriptionSchema } from 'validations';

import { valueToCurrency } from 'utils/mask';
import Loading from 'components/Loading';
import Input from 'components/Input';
import { Container, Title } from './styles';

export interface ModalConfirmCancel {
  show: boolean;
  setShow: any;
  handleCancelSignature: any;
  subscription: ISubscriptions;
}

const ModalConfirmCancel: React.FC<ModalConfirmCancel> = ({
  show,
  setShow,
  handleCancelSignature,
  subscription,
}) => {
  const { loadingSubscriptioUpdate } = useSubscriptions();

  const formik = useFormik({
    initialValues: {
      cancellationReason: '',
    },
    validationSchema: cancelSubscriptionSchema,
    onSubmit: value => handleCancelSignature(value),
  });

  return (
    <>
      {loadingSubscriptioUpdate ? (
        <Loading />
      ) : (
        <Container>
          <div className="modal-confirm-body">
            <Title>Deseja mesmo cancelar?</Title>
            <table className="table-custom table table-striped table-hover">
              <thead>
                <tr>
                  <td>Tipo de cancelamento</td>
                  <td>Valor</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Restituição de desconto à ser cobrado da(o) cliente</td>
                  <td style={{ color: '#4aa96c' }}>
                    {valueToCurrency(subscription.amountToFee)}
                  </td>
                </tr>
                <tr>
                  <td>Estorno à ser devolvido para a(o) cliente</td>
                  <td style={{ color: '#df8350' }}>
                    {valueToCurrency(subscription.amountToRefund)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-area">
              <form onSubmit={formik.handleSubmit}>
                <Input
                  name="cancellationReason"
                  label="Motivo do cancelamento"
                  value={formik.values.cancellationReason}
                  error={formik.errors.cancellationReason}
                  onChange={formik.handleChange}
                />
                <div className="buttons">
                  <Button color="danger" type="submit">
                    Sim
                  </Button>

                  <Button color="default" onClick={() => setShow(!show)}>
                    Não
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default ModalConfirmCancel;
