import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1240px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .header {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
  }

  .data-customer {
    display: flex;
    margin-top: 10%;

    font-size: 18px;

    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    span {
      white-space: nowrap;
    }

    .data-customer-detail {
      display: flex;
      flex-direction: column;
    }

    .unit {
      margin-left: 50px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 50px;

    .data {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .data-card {
        display: flex;
        margin-left: 20px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
    }

    .data-customer {
      display: flex;
      align-items: flex-start;
      width: calc(100% - 200px);
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 10px;
      margin-top: 10%;
      box-shadow: none;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 20px 100px;
      margin-top: 0px;
      border: none;
      box-shadow: none;

      .css-1nrlq1o-MuiFormControl-root {
        width: 100%;
      }

      .data {
        display: flex;
        flex-direction: column;

        .data-card {
          margin-top: 20px;
          margin-left: 0px;

          .grid {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 770px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 20px 100px;
      margin-top: 0px;

      .css-1nrlq1o-MuiFormControl-root {
        width: 100%;
      }

      .data {
        display: flex;
        flex-direction: column;

        .data-card {
          margin-top: 20px;
          margin-left: 0px;

          .grid {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
    }

    .data-customer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 40px);

      border: 1px solid #ccc;
      border-radius: 8px;

      padding: 10px;
      margin-top: 30px;

      .unit {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-top: 0px;

      .css-1nrlq1o-MuiFormControl-root {
        width: 100%;
      }

      .data {
        display: flex;
        flex-direction: column;

        .data-card {
          margin-top: 20px;
          margin-left: 0px;

          .grid {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-top: 0px;

      .css-1nrlq1o-MuiFormControl-root {
        width: 100%;
      }

      .data {
        display: flex;
        flex-direction: column;

        .data-card {
          margin-top: 20px;
          margin-left: 0px;

          .grid {
            width: 100%;
          }
        }
      }
    }
  }
`;

type IGridProps = {
  grid: number;
};

export const Grid = styled.div<IGridProps>`
  display: grid;
  gap: 12px;

  ${props =>
    props.grid === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.grid === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

    ${props =>
    props.grid === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 12px;
    `}


  @media only screen and (max-width: 400px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;

    ${props =>
      props.grid === 1 &&
      css`
        grid-template-columns: 1fr;
      `}

    ${props =>
      props.grid === 3 &&
      css`
        grid-template-columns: 1fr 1fr;
        margin-top: 12px;

        .cvc {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
        }
      `}
  }
`;
