import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import Loading from 'components/Loading';
import Button from 'components/Button';
import { useContract } from 'hooks/contracts';

import { phoneMask } from 'utils/mask';

import { Container, Title, SendForm } from './styles';
import ModalValidate from './modalValidate';

interface IModalInfo {
  setIsVisible: any;
  planId: number;
  customerIdentifier: string;
}

const ModalInfo: React.FC<IModalInfo> = ({
  setIsVisible,
  planId,
  customerIdentifier,
}) => {
  const {
    loading,
    dataForContract,
    submit,
    contractErros,
    fetchValidateContract,
    contract,
  } = useContract();
  const [load, setLoading] = useState(false);
  const [sendOptionSelected, setSendOptionSelected] = useState('0');
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [disabledSms, setDisabledSms] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const cursor = disabledBtn ? 'not-allowed' : 'pointer';

  const [checkedOptionSend, setCheckOptionOfSend] = useState({
    email: false,
    sms: false,
  });

  useEffect(() => {
    fetchValidateContract(planId, customerIdentifier);
  }, [fetchValidateContract, planId, customerIdentifier]);

  const history = useHistory();

  const handleTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendOptionSelected(event.target.value);
    setCheckOptionOfSend({
      ...checkedOptionSend,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === 'email') {
      setDisabledSms(!disabledSms);
      setDisabledBtn(!disabledBtn);
    }
    if (event.target.name === 'sms') {
      setDisabledEmail(!disabledEmail);
      setDisabledBtn(!disabledBtn);
    }
  };

  const handleClickSend = async () => {
    setLoading(true);

    if (sendOptionSelected === dataForContract.customer.email) {
      const data = {
        contract: dataForContract,
        notificationMethod: 0,
        sendOptionSelected,
      };
      await submit(data);
    }
    if (sendOptionSelected === dataForContract.customer.phone) {
      const data = {
        contract: dataForContract,
        notificationMethod: 1,
        sendOptionSelected,
      };
      await submit(data);
    }

    if (!contractErros) {
      history.push('/contracts');
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Title>Selecione a forma de envio do termo para o aceite:</Title>
          <Divider />
          <SendForm>
            <div className="form-control-send">
              <div className="form-email">
                E-mail
                <FormControlLabel
                  control={
                    <Checkbox
                      name="email"
                      color="primary"
                      checked={checkedOptionSend.email}
                      onChange={handleTerm}
                      value={dataForContract.customer.email}
                      disabled={disabledEmail}
                    />
                  }
                  label={
                    dataForContract.customer?.email
                      ? dataForContract.customer.email
                      : 'E-mail não informado no Avant'
                  }
                />
              </div>
              <div className="form-sms">
                SMS
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sms"
                      color="primary"
                      checked={checkedOptionSend.sms}
                      onChange={handleTerm}
                      disabled={disabledSms}
                      value={dataForContract.customer.phone}
                    />
                  }
                  label={
                    dataForContract.customer?.phone
                      ? phoneMask(dataForContract.customer.phone)
                      : 'Telefone não informado no Avant'
                  }
                />
              </div>
            </div>
          </SendForm>

          <div className="buttons">
            <Button color="danger" onClick={() => setIsVisible(false)}>
              Fechar
            </Button>
            <Button
              disabled={disabledBtn}
              cursor={cursor}
              onClick={handleClickSend}
            >
              Enviar
            </Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default ModalInfo;
