import * as Yup from 'yup';

export const paymentSchema = Yup.object().shape({
  employed: Yup.string().required('Escolha uma funcionária'),
  name: Yup.string().required('Preencha o campo nome.'),
  identifier: Yup.string()
    .min(11, 'CPF incompleto.')
    .required('Preencha o campo CPF.'),
  number: Yup.string()
    .min(17, 'Número Cartão de crédito incompleto')
    .required('Preencha o campo cartão de crédito.'),
  month: Yup.string().required('Escolha o mês'),
  year: Yup.string().required('Escolha o ano'),
  cvc: Yup.string().min(3, 'CVC incompleto.').required('Digite o CVC'),
});

export const searchSchema = Yup.object().shape({
  customer_identifier: Yup.string().min(14, 'CPF incompleto.'),
});

export const chackoutPaymentSchema = Yup.object().shape({
  employed: Yup.string().required('Escolha uma funcionária'),
  identifier: Yup.string(),
});

export const addNewCardSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o campo nome.'),
  number: Yup.string()
    .min(17, 'Número Cartão de crédito incompleto')
    .required('Preencha o campo cartão de crédito.'),
  month: Yup.string().required('Escolha o mês'),
  year: Yup.string().required('Escolha o ano'),
  cvc: Yup.string().min(3, 'CVC incompleto.').required('Digite o CVC'),
});

export const createOrEditSchema = Yup.object().shape({
  name: Yup.string().required('Preencha o campo nome.'),
  timeLimit: Yup.string().required('Preencha o campo quantidade.'),
  description: Yup.string().required('Preencha o campo descrição.'),
  discount: Yup.string().required('Preencha o campo desconto.'),
  services: Yup.array().min(1, 'Adicione um serviço.').required(),
});

export const createOrEditSchemaUsers = Yup.object().shape({
  name: Yup.string().required('Preencha o campo nome.'),
  email: Yup.string().required('Preencha o campo e-mail.'),
});

export const validationCodTerm = Yup.object().shape({
  printObservation: Yup.string()
    .min(10, 'Campo incompleto, mínimo de 10 caracteres.')
    .required('Preencha o campo com as observações.'),
  // validationCod: Yup.string().required('Informe o código.'), SEGUNDA VERSÃO
});

export const cancelSubscriptionSchema = Yup.object().shape({
  cancellationReason: Yup.string()
    .min(15, 'Campo incompleto, mínimo de 15 caracteres')
    .required('Preencha o campo com as observações.'),
  // validationCod: Yup.string().required('Informe o código.'), SEGUNDA VERSÃO
});
