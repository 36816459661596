import styled from '@emotion/styled';

export const Container = styled.div``;

export const Title = styled.h4`
  margin-bottom: 20px;
`;

export const ContainerModalConfirm = styled.div`
  .buttons {
    button {
      margin-bottom: 0px;
    }
  }
`;
