import React from 'react';

import { Tooltip } from '@material-ui/core';
import Replay from '@material-ui/icons/Replay';
import { t } from 'ab18n';
import { format, parseISO } from 'date-fns';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Span from 'components/Span';
import DataNotFound from 'components/Lottie/DataNotFound';
import Loading from 'components/Loading';
import { valueToCurrency } from 'utils/mask';
import { useSubscriptions } from 'hooks/subscriptions';

import { Container } from './styles';

const Bill: React.FC = () => {
  const {
    subscription,
    revenueRetry,
    fetchSubscriptionDetail,
    loadingModalDetail,
  } = useSubscriptions();
  const incomingBills = subscription?.bills?.filter(
    (bill) => bill.origin === 'subscription'
  ).sort((bill) => bill.period?.cycle || 200) || [];

  const fecth = () => {
    fetchSubscriptionDetail(subscription.id);
  };

  const handleRevenueRetry = async (id: number) => {
    await revenueRetry(id);
    fecth();
  };

  return (
    <Container>
      {incomingBills && (
        <>
          {loadingModalDetail ? (
            <Loading />
          ) : (
            <>
              {Object.keys(incomingBills).length > 0 ? (
                <table className="table-custom table table-striped table-hover">
                  <thead>
                  <tr>
                    <td>Parcela</td>
                    <td>ID da fatura</td>
                    <td>Data do pagamento</td>
                    <td>Pagamento agendado</td>
                    <td>Valor</td>
                    <td>Status</td>
                    <td align="center">Pagamento</td>
                  </tr>
                  </thead>
                  <tbody>
                  {incomingBills.map((item) => (
                    <tr key={item.id}>
                      <td>{item.period?.cycle || 0}ª</td>
                      <td>{item.id}</td>
                      <td>
                        {!item.billingAt
                          ? ''
                          : format(parseISO(item.billingAt), 'dd-MM-yyyy')}
                      </td>
                      <td>
                        {!item.period?.billingAt
                          ? ''
                          : format(
                            parseISO(item.period.billingAt),
                            'dd-MM-yyyy',
                          )}
                      </td>
                      <td>{valueToCurrency(item.price)}</td>
                      <td>
                        {item.status && (
                          <Span color={item.status}>
                            {t(`pages.subscription.revenue.${item.status}`)}
                          </Span>
                        )}
                      </td>

                      <td align="center">
                        <div className="icons">
                            <span>
                              <Tooltip title="Link de pagamento">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="icon-btn">
                                    <MonetizationOnIcon />
                                  </span>
                                </a>
                              </Tooltip>
                            </span>
                          {item.status === 'pending' && (
                            <span>
                                <Tooltip
                                  title="Cobrar agora"
                                  onClick={() => handleRevenueRetry(item.charges[item.charges.length - 1].id)}
                                >
                                  <span className="icon-btn">
                                    <Replay />
                                  </span>
                                </Tooltip>
                              </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              ) : (
                <DataNotFound />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Bill;
