import React, { createContext, useCallback, useState, useContext } from 'react';

import api from 'services/api';
import { IRevenues } from './types';

export interface RevenuesContextData {
  revenues: IRevenues[];
  loading: boolean;
  fetchRevenues(): Promise<void>;
}

const RevenuesContext = createContext<RevenuesContextData>(
  {} as RevenuesContextData,
);

const RevenuesProvider: React.FC = ({ children }) => {
  const [revenues, setRevenues] = useState<IRevenues[]>([] as IRevenues[]);
  const [loading, setLoading] = useState(false);

  const fetchRevenues = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`revenues`);

      setRevenues(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <RevenuesContext.Provider
      value={{
        revenues,
        loading,
        fetchRevenues,
      }}
    >
      {children}
    </RevenuesContext.Provider>
  );
};

function useRevenues(): RevenuesContextData {
  const context = useContext(RevenuesContext);

  if (!context) {
    throw new Error(' useRevenues must be used within an RevenuesProvider ');
  }
  return context;
}
export { RevenuesProvider, useRevenues };
