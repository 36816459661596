import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { queryURI } from 'queryparamsuri';

import { Container } from './styles';

type IPagination = {
  total: number;
  itensPerPage: number;
  setItensPerPage: any;
  setPage: any;
};

export const PaginationComponent: React.FC<IPagination> = ({
  total,
  itensPerPage,
  setItensPerPage,
  setPage,
}) => {
  const history = useHistory();

  useEffect(() => {
    const itensPerPageString = itensPerPage.toString();

    const params = queryURI({
      name: 'limit',
      value: itensPerPageString,
    });

    history.push(params);
  }, [itensPerPage]); // eslint-disable-line

  const handleItensPerPage = (e: any) => {
    setItensPerPage(e.target.value);
    setPage(1);
  };

  return (
    <Container>
      <div className="select">
        <select onChange={(e: any) => handleItensPerPage(e)}>
          <option value="10">10 por página</option>
          <option value="20">20 por página</option>
          <option value="30">30 por página</option>
          <option value="40">40 por página</option>
          <option value="50">50 por página</option>
        </select>
      </div>

      <p>de {total}</p>
    </Container>
  );
};

export default PaginationComponent;
