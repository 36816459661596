import styled from '@emotion/styled';

export const Container = styled.div`
  h3 {
    padding: 0;
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  p {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 18px;
  }

  .btn {
    display: flex;
    width: 100%;

    button + button {
      margin-left: 10px;
    }
  }

  .change-info {
    border: 1px solid red;
    background-color: rgba(252, 210, 209, 0.2);
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
    text-align: justify;
  }
`;

export const Title = styled.div`
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
`;
