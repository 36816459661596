import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useToasts } from 'react-toast-notifications';

import { useCustomer } from 'hooks/customer';
import { useContract } from 'hooks/contracts';
import { usePosition } from 'hooks/position'

import LoadingCustomer from 'components/Lottie/LoadingCustomer';
import Button from 'components/Button';
import privacy_tools_api from 'services/privacy_tools_api'

import { contractTemplate } from 'utils/contractTemplate';
import { useAuth } from 'hooks/auth';
import { Container, Content, Title } from './styles';
import api from 'services/api';

const CustomerTerm: React.FC = () => {
  const {
    fetchContractCustomer,
    loadingContractCustomer,
    setLoadingContractCustomer,
    contractCustomer,
    agreedContract,
  } = useContract();
  const { user } = useAuth();
  const { customer } = useCustomer();
  const [position, positionError] = usePosition()
  const { addToast } = useToasts();
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [contractRes, setContractRes] = useState('');

  const { hash }: any = useParams();
  const history = useHistory();
  const cursor = disabled ? 'not-allowed' : 'pointer';

  useEffect(() => {
    fetchContractCustomer(hash);
  }, [fetchContractCustomer, hash]);

  useEffect(() => {
    if (Object.keys(contractCustomer).length > 0) {
      const servicesName = contractCustomer.plan.services
        ?.filter((item: any) => item.name)
        .map((item: any) => item.name);

      const insertDataContract = contractTemplate({
        template: contractCustomer.template,
        plan: contractCustomer.plan,
        user,
        servicesName,
        customer,
        customerContract: contractCustomer.customer,
        createdAt: contractCustomer.createdAt,
        unit: contractCustomer.unit,
      });

      setContractRes(insertDataContract);
    }
  }, [contractCustomer, customer, user]);

  const validContract = !!Object.keys(contractCustomer).length;

  const handleClickAccepted = async () => {
    if (positionError || !position) {
      addToast('Por gentileza, permita acesso a localização e recarregue a página.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return
    }
    setLoadingContractCustomer(true);

    const values = {
      hash,
      agreeMethod: 1,
      position
    };

    const authorizeInPrivacyTools = async () => {
      const authParams = new FormData();
      authParams.append('username', process.env.REACT_APP_USERNAME as string);
      authParams.append('password', process.env.REACT_APP_PASSWORD as string);
      authParams.append('grant_type', 'password');

      const { data } = await privacy_tools_api.post('/oauth/token', authParams, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_HEADER_USERNAME}:${process.env.REACT_APP_HEADER_PASSWORD}`,
          )}`,
        },
      });
      return data;
    };

    const sendToPrivacyTools = async () => {
      try {
        const authData = await authorizeInPrivacyTools();
          const data = {
            consentValue: true,
            document: contractCustomer.customer.identifier,
            email: contractCustomer.customer.email,
            name: contractCustomer.customer.name,
            phone: contractCustomer.customer.phone,
            updateUserData: true,
            websiteOrigin: 'Portal Assinaturas/Personnalite',
            formOrigin: 'Aceite por dispositivo do cliente',
            templateHash: process.env.REACT_APP_HASH_TEMPLATE,
          };

          await api.post('/integration/privacytools', {data, token_access: authData.access_token}, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.log('Erro na integração com a Privacy Tools.', error);
      }
    };

    await sendToPrivacyTools();

    await agreedContract(values);

    fetchContractCustomer(hash);

    if (contractCustomer.status.id === 1) {
      history.push(`/integration/contracts/${hash}/finished`);
    }
  };

  const handleChecked = () => {
    setChecked(!checked);
    setDisabled(!disabled);
  };

  return (
    <>
      {loadingContractCustomer ? (
        <LoadingCustomer />
      ) : (
        <Container>
          {!validContract ? (
            <LoadingCustomer />
          ) : (
            <>
              {contractCustomer.status?.id === 1 ? (
                <Redirect to={`/integration/contracts/${hash}/finished`} />
              ) : (
                <>
                  <Title>Termo de adesão Assinatura SD</Title>

                  <Content>
                    <div className="contract">
                      {ReactHtmlParser(contractRes)}
                    </div>
                  </Content>
                  <div className="form">
                    <FormControlLabel
                      className="form-control-label"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChecked}
                          name="accepted"
                          color="primary"
                        />
                      }
                      label="Eu aceito."
                    />
                    <Button
                      onClick={() => handleClickAccepted()}
                      disabled={disabled}
                      cursor={cursor}
                    >
                      Enviar
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default CustomerTerm;
