import styled from '@emotion/styled';
import { NONAME } from 'dns';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #585656;

  select {
    height: 30px;
    width: 130px;
    padding-left: 10px;
    -webkit-padding-start: 10px;
    -moz-padding-start: 10px;
    padding-right: 10px;
    -webkit-padding-end: 10px;
    -moz-padding-end: 10px;
    font-size: 14px;
    color: #585656;
    border: transparent;
    background: #fff;
    border-radius: 20px;
  }

  p {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 20px;
  }
`;

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  margin-right: 20px;
`;

export const PaginationButton = styled.div`
  display: flex;
`;

interface IPaginationItem {
  isSelect?: boolean;
}

export const PaginationItem = styled.div<IPaginationItem>`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  background: ${props => (props.isSelect ? '#9ea58a' : '')};
  color: ${props => (props.isSelect ? '#fff' : '')};
  border-radius: 50%;
  margin-right: 10px;
  transition: all ease 0.2s;
  cursor: pointer;

  :hover {
    background: #ddd;
  }
`;

export const PaginationReticence = styled.div`
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all ease 0.2s;
`;

interface IPaginationChange {
  disabled: boolean;
}

export const PaginationChange = styled.div<IPaginationChange>`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #ddd;
  cursor: pointer;

  svg {
    color: #999;
  }

  ${props =>
    props.disabled && {
      opacity: '0.5',
      pointerEvents: 'none',
    }}

  :hover {
    background: #ddd;
  }
`;
