import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useFormik } from 'formik';

import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { validationCodTerm } from 'validations';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';
import { IContract } from 'hooks/contracts/types';
import { useContract } from 'hooks/contracts';
import { usePosition } from 'hooks/position'
import { cpfMask, phoneMask, valueToCurrency } from 'utils/mask';

import privacy_tools_api from 'services/privacy_tools_api';
import api from 'services/api';

import Modal from 'components/Modal';
import ModalPrintContract from 'components/modalPrintContract';
import {
  Container,
  Content,
  Title,
  FormTermoData,
  Grid,
  ResendTerm,
  AceptPhysical,
} from './styles';

interface IModalInfo {
  setIsVisible: any;
  contract: IContract;
}

const ModalInfo: React.FC<IModalInfo> = ({ setIsVisible, contract }) => {
  const { addToast } = useToasts();
  const [position, positionError] = usePosition()
  const { fetchContractCustomer, setContractCustomer, update, loading } =
    useContract();
  const [load, setLoading] = useState(false);
  const [disabledSms, setDisabledSms] = useState(false);
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [sendOptionSelected, setSendOptionSelected] = useState('0');
  const [resend, setResend] = useState(false);
  const [aceptPhysical, setAceptPhysical] = useState(false);
  const [hash, setHash] = useState('');
  const [viewerContract, setViewerContract] = useState(false);
  const cursor = disabledBtn ? 'not-allowed' : 'pointer';

  const [checkedOptionSend, setCheckedOptionSend] = useState({
    email: false,
    sms: false,
  });

  const history = useHistory();

  const handleOptionSend = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendOptionSelected(event.target.value);
    setCheckedOptionSend({
      ...checkedOptionSend,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === 'email') {
      setDisabledSms(!disabledSms);
      setDisabledBtn(!disabledBtn);
    }
    if (event.target.name === 'sms') {
      setDisabledEmail(!disabledEmail);
      setDisabledBtn(!disabledBtn);
    }
  };

  const handleResendContract = async () => {
    setLoading(true);
    const notificationMethod = sendOptionSelected === contract.customer.email
      ? 0 : 1;
    const values = { contract, notificationMethod, sendOptionSelected };
    await update(values);
    setIsVisible(false);
  };

  const handlePurchase = async (value: any) => {

    const authorizeInPrivacyTools = async () => {
      const authParams = new FormData();
      authParams.append('username', process.env.REACT_APP_USERNAME as string);
      authParams.append('password', process.env.REACT_APP_PASSWORD as string);
      authParams.append('grant_type', 'password');

      const { data } = await privacy_tools_api.post('/oauth/token', authParams, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_HEADER_USERNAME}:${process.env.REACT_APP_HEADER_PASSWORD}`,
          )}`,
        },
      });
      return data;
    };
    const sendToPrivacyTools = async () => {
      try {
        const authData = await authorizeInPrivacyTools();
          const data = {
            consentValue: true,
            document: contract.customer.identifier,
            email: contract.customer.email,
            name: contract.customer.name,
            phone: contract.customer.phone,
            websiteOrigin: 'Portal Assinaturas/Personnalite',
            formOrigin: 'Aceite em loja',
            templateHash: process.env.REACT_APP_HASH_TEMPLATE,
          };

          await api.post('/integration/privacytools', {data, token_access: authData.access_token}, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.log('Erro na integração com a Privacy Tools.', error);
      }
    };

    await sendToPrivacyTools();

    if (aceptPhysical && (positionError || !position)) {
      addToast('Por gentileza, permita acesso a localização e recarregue a página.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return
    }

    if (aceptPhysical) {
      const values = {
        contract,
        position,
        printObservation: value,
        agreeMethod: 0
      };
      await update(values);
    }

    addToast('Contrato validado com sucesso.', {
      appearance: 'success',
      autoDismiss: true,
    });

    setLoading(true);
    setContractCustomer(contract);
    history.push(`/purchase/${contract.hash}`);
  };

  const formik = useFormik({
    initialValues: {
      printObservation: '',
      // validationCod: '',  SEGUNDA VERSÃO
    },
    validationSchema: validationCodTerm,
    onSubmit: value => handlePurchase(value),
  });

  const handlePrintContract = async (hash: string) => {
    setViewerContract(true);
    setHash(hash);
    await fetchContractCustomer(hash);
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Title>Informações para a contratação</Title>
          <Divider />

          <FormTermoData>
            <div className="grid">
              <Title>Cliente</Title>
              <Grid grid={2}>
                <Input
                  name="name"
                  label="Nome"
                  value={contract.customer.name}
                  size="small"
                  disabled
                />
                <Input
                  name="identifier"
                  label="CPF"
                  value={cpfMask(contract.customer.identifier)}
                  size="small"
                  disabled
                />
              </Grid>
              <Grid grid={2}>
                <Input
                  name="email"
                  label="E-mail"
                  value={contract.customer.email}
                  size="small"
                  disabled
                />
                <Input
                  name="phone"
                  label="Telefone"
                  value={phoneMask(contract.customer.phone)}
                  size="small"
                  disabled
                />
              </Grid>
              <Divider className="divider" />
              <Title>Plano</Title>
              <Grid grid={1}>
                <Input
                  name="plan"
                  label="Plano"
                  value={contract.plan.name}
                  size="small"
                  disabled
                />
              </Grid>
              <Grid grid={1}>
                {contract.plan.services?.map((item: any) => (
                  <Input
                    key={item.id}
                    name="service"
                    label="Seviço"
                    value={item.name}
                    size="small"
                    disabled
                  />
                ))}
              </Grid>
              <Grid grid={2}>
                <Input
                  name="price"
                  label="Preço"
                  value={
                    contract.plan?.price
                      ? valueToCurrency(contract.plan.price)
                      : 'Preço não informado'
                  }
                  size="small"
                  disabled
                />
                <Input
                  name="discount"
                  label="Desconto"
                  value={`${contract.plan?.discount}%`}
                  size="small"
                  disabled
                />
              </Grid>
            </div>
          </FormTermoData>

          {contract.status.id === 0 && (
            <Content>
              <Title>Ações</Title>
              <div className="check-actions">
                <div className="check-resend">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="resend"
                        color="primary"
                        checked={resend}
                        onChange={e => setResend(e.target.checked)}
                        value="resend"
                        disabled={aceptPhysical}
                      />
                    }
                    label="Reenviar"
                  />
                </div>
                <div className="check-physic">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="physic"
                        color="primary"
                        checked={aceptPhysical}
                        onChange={e => setAceptPhysical(e.target.checked)}
                        value="physic"
                        disabled={resend}
                      />
                    }
                    label="Aceitar fisicamente"
                  />
                </div>

                <Button
                  onClick={() => handlePrintContract(contract.hash)}
                  className="btn-print"
                >
                  Imprimir termo
                </Button>
              </div>
            </Content>
          )}

          {resend && (
            <>
              <Divider />
              <ResendTerm>
                <div className="form-email">
                  E-mail
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="email"
                        color="primary"
                        checked={checkedOptionSend.email}
                        onChange={handleOptionSend}
                        value={contract.customer.email}
                        disabled={disabledEmail}
                      />
                    }
                    label={
                      contract.customer.email
                        ? contract.customer.email
                        : 'E-mail não informado no Avant'
                    }
                  />
                </div>
                <div className="form-sms">
                  SMS
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="sms"
                        color="primary"
                        checked={checkedOptionSend.sms}
                        onChange={handleOptionSend}
                        disabled={disabledSms}
                        value={contract.customer.phone}
                      />
                    }
                    label={
                      contract.customer.phone
                        ? phoneMask(contract.customer.phone)
                        : 'Telefone não informado no Avant'
                    }
                  />
                </div>
              </ResendTerm>
            </>
          )}

          {aceptPhysical && (
            <>
              <Divider />
              <AceptPhysical>
                <form onSubmit={formik.handleSubmit}>
                  <div className="print-observation">
                    <Title>Qual o motivo do aceite ser fisicamente?</Title>
                    <div className="div-print-observation">
                      <Input
                        name="printObservation"
                        label="Motivo do aceito de termo impresso"
                        value={formik.values.printObservation}
                        error={formik.errors.printObservation}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  {/* VALIDAÇÃO DO CÓDIGO DO CONTRATO, FICA PARA A SEGUNDA VERSÃO
              <div className="div-validation">
                <Title>Qual o código do contrato impresso?</Title>
                <div className="validation-area">
                  <div className="input-validation">
                    <Input
                      name="validationCod"
                      label="Código"
                      value={formik.values.validationCod}
                      error={formik.errors.validationCod}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <Button className="btn-acept" type="submit">
                    Validar
                  </Button>
                </div>
              </div> */}
                  <div className="buttons-area">
                    <Button color="danger" onClick={() => setIsVisible(false)}>
                      Fechar
                    </Button>
                    <Button type="submit">Contratar plano</Button>
                  </div>
                </form>
              </AceptPhysical>
            </>
          )}

          <div className="buttons-area">
            {!resend && !aceptPhysical && (
              <Button color="danger" onClick={() => setIsVisible(false)}>
                Fechar
              </Button>
            )}

            {contract.agreeMethod && (
              <Button onClick={() => handlePurchase(contract)}>
                Contratar plano
              </Button>
            )}

            {resend && (
              <>
                <Button color="danger" onClick={() => setIsVisible(false)}>
                  Fechar
                </Button>
                <Button
                  onClick={handleResendContract}
                  cursor={cursor}
                  disabled={disabledBtn}
                >
                  Reenviar termo
                </Button>
              </>
            )}
          </div>

          {viewerContract && (
            <div className="modal-print-contract">
              <Modal
                width="1200px"
                status={viewerContract}
                handleClose={() => setViewerContract(false)}
              >
                <ModalPrintContract
                  openModal={setViewerContract}
                  contractHash={hash}
                />
              </Modal>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default ModalInfo;
