import styled from '@emotion/styled';

export const Container = styled.div`
  .header {
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    .card-plan {
      background-color: #fff;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      min-height: 350px;
      height: 100%;
      position: relative;
      flex-direction: column;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .plan-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .detail {
          margin-top: 8px;

          .green-price {
            font-weight: bold;
            font-size: 21px;
          }
        }
      }
      .table {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin-top: 30px;

        .tab-info {
          display: flex;
          flex-direction: column;
          min-width: 400px;
          border: 1px solid #ccc;
          padding: 40px 30px;
          border-radius: 12px;
          position: relative;

          .service {
            position: absolute;
            top: -15px;
            background-color: #fff;
            border: 5px solid #fff;
          }
        }

        span {
          margin-bottom: 5px;
        }
      }
    }
  }
`;
