import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    .cards-saved-area {
      display: grid;
      height: 100%;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      margin-bottom: 20px;

      .cards-saved {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;

        .active {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 0;
          border: 1px solid #2fdd92;
          border-radius: 12px;
          box-shadow: 0px 0px 10px rgba(47, 221, 146, 0.5);
          background-color: rgba(47, 221, 146, 0.1);
        }

        .switch {
          display: flex;
          width: 270px;
          justify-content: space-between;
          align-items: center;
          margin-right: 10px;

          .delete-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            transition: all 0.5s ease;

            :hover {
              background-color: #ddd;
            }
          }

          svg {
            color: var(--red);
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
`;
