import React, { ButtonHTMLAttributes } from 'react';

import Loading from 'components/Loading';

import { ButtonCustom } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'green';
  cursor?: 'pointer' | 'not-allowed';
  loading?: boolean;
};

const CustomButton: React.FC<ButtonProps> = ({
  children,
  color = 'default',
  loading = false,
  cursor = 'pointer',
  ...rest
}) => {
  return (
    <ButtonCustom {...rest} color={color} cursor={cursor}>
      {loading ? <Loading color="#fff" border={2} height={15} /> : children}
    </ButtonCustom>
  );
};

export default CustomButton;
