import React, { useState, useEffect } from 'react';

import { MdAddCircle } from 'react-icons/md';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Tooltip from '@material-ui/core/Tooltip';

import Input from 'components/Input';
import Loading from 'components/Loading';
import SelectAutoComplete from 'components/SelectAutoComplete';
import CustomerNotFound from 'components/Lottie/CustomerNotFound';
import CustomerSearch from 'components/Lottie/identifierSearch';
import Modal from 'components/Modal';

import { stringNumber, cpfMask, valueToCurrency } from 'utils/mask';

import { usePlans } from 'hooks/Plans';
import { ICustomer } from 'hooks/customer/types';
import { useCustomer } from 'hooks/customer';
import { useContract } from 'hooks/contracts';
import ModalCreate from './modalCreate';

import { Container, Content, ContentCustomer, Search } from './styles';

const Plans: React.FC = () => {
  const {
    plansCustomer,
    setPlansCustomer,
    setPlan,
    plan,
    fetchPlans,
    fetchPlansCustomer,
    loadingPlanCustomer,
  } = usePlans();
  const { fetchCustomer, customer, setCustomer, loading } = useCustomer();
  const [display, setDisplay] = useState(true);
  const [show, setShow] = useState(false);
  const [identifier, setIdentifier] = useState<string>('');
  const { setDataForContract } = useContract();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setCustomer({});
    fetchPlans();
  }, [fetchPlans, setCustomer]);

  const handleContractPlan = (event: any) => {
    setPlan(plansCustomer.filter(plan => plan.id === event.id)[0]);
    setDataForContract({ customer: { ...customer }, plan: { ...event } });

    setIsVisible(true);
  };

  const handleSearch = async (event: any) => {
    setCustomer({});
    setShow(false);
    const { value } = event.target;

    if (value.length <= 14) {
      setIdentifier(value);
    }

    if (value.length === 14) {
      setDisplay(false);
    }

    if (value.length < 14) {
      return;
    }

    if (event.key === 'Enter') {
      fetchCustomer(stringNumber(value));
    } else {
      fetchCustomer(stringNumber(value));
    }

    setShow(false);
  };

  const handleConfirme = (data: ICustomer) => {
    fetchPlansCustomer(data.identifier);
    setShow(true);
  };

  return (
    <>
      <Container>
        <Search>
          <div className="unidade">
            <SelectAutoComplete />
          </div>
          {!display && (
            <div className="btncontent">
              <div>
                <h4>Buscar Cliente</h4>
              </div>
              <div className="input">
                <Input
                  size="small"
                  name="identifier"
                  label="CPF"
                  mask="999.999.999-99"
                  onChange={handleSearch}
                  onKeyPress={handleSearch}
                  value={identifier}
                />
              </div>
            </div>
          )}
        </Search>
        {!loading && identifier.length < 14 && <CustomerSearch />}

        {loading && <Loading marginTop={50} />}

        {!loading &&
          identifier.length > 13 &&
          Object.keys(customer).length === 0 && <CustomerNotFound />}

        {display && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <div className="input" style={{ width: '300px' }}>
              <Input
                size="small"
                name="identifier"
                label="CPF"
                mask="999.999.999-99"
                onChange={handleSearch}
                onKeyPress={handleSearch}
                value={identifier}
                autoFocus
              />
            </div>
          </div>
        )}

        {Object.keys(customer).length > 0 && (
          <ContentCustomer>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>CPF</td>
                  <td>Primeiro atendimento?</td>
                  <td>Já foi assinante SD Lover?</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{customer.name}</td>
                  <td>{cpfMask(customer.identifier)}</td>
                  {customer.isFirstServiceAtSD ? <td>Sim</td> : <td>Não</td>}
                  {customer.isFirstServiceAtSDLover ? (
                    <td>Sim</td>
                  ) : (
                    <td>Não</td>
                  )}
                  <td className="btn-check" align="right">
                    <Tooltip title="Confirmar" aria-label="Confirmar">
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        onClick={() => handleConfirme(customer)}
                      />
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </ContentCustomer>
        )}

        {loadingPlanCustomer ? (
          <Loading marginTop={50} />
        ) : (
          show && (
            <Content>
              <div className="grid">
                {plansCustomer.map(item => (
                  <div className="card-plan">
                    <div className="hover">
                      <div className="icon-add">
                        <MdAddCircle onClick={() => handleContractPlan(item)} />
                      </div>

                      <span>Contratar</span>
                    </div>
                    <div className="header">
                      <div className="id"> #{item.id}</div>
                    </div>

                    <div className="plan-info">
                      <h2>{item.name}</h2>
                      <div className="detail">
                        <span>
                          {`${item.discount}% ${valueToCurrency(
                            item.original_price,
                          )} por `}
                        </span>

                        <span className="green-price">
                          {valueToCurrency(item.price)}
                        </span>
                      </div>
                    </div>
                    <div className="table">
                      <div className="tab-info">
                        <div className="service">Serviços</div>
                        {item.services.map(service => (
                          <span>
                            {service.quantity}x {service.name}
                            {service.name.includes('1º') && (
                              <span
                                style={{ marginLeft: '5px', color: '#00b300' }}
                              >
                                Cortesia
                              </span>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Content>
          )
        )}

        {isVisible && (
          <Modal
            status={isVisible}
            handleClose={() => setIsVisible(!isVisible)}
          >
            <ModalCreate
              setIsVisible={setIsVisible}
              planId={plan.id}
              customerIdentifier={customer.identifier}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default Plans;
