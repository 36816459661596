import React, { useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useUsers } from 'hooks/users';
import { useReport } from 'hooks/reports';
import { useUnitSelected } from 'hooks/unitSelected';
import { useAuth } from 'hooks/auth';

const SelectAutoComplete: React.FC = () => {
  const { fetchUsersActive, usersActive } = useUsers();
  const { unitSelected, handleSelectedUnit } = useUnitSelected();
  const { loading } = useReport();
  const { user } = useAuth();

  useEffect(() => {
    fetchUsersActive();
  }, [fetchUsersActive]);

  const handleSelect = (event: any) => {
    handleSelectedUnit(event);
  };

  const data: any[] = [
    {
      id: '',
      value: '',
      name: 'Todos',
      email: '',
    },
  ];

  usersActive.forEach(item => {
    data.push(item);
  });

  const findIndex = data.findIndex(item => item.email === unitSelected);

  return (
    <>
      {loading
        ? user.email?.includes('@gsd.com.br') && (
            <Autocomplete
              style={{ width: '300px' }}
              defaultValue={data[findIndex]}
              size="small"
              disabled
              options={data}
              getOptionLabel={option => option.name}
              onChange={(event, option) => handleSelect(option?.email)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Selecione uma unidade"
                />
              )}
            />
          )
        : data.length > 1 && (
            <Autocomplete
              style={{ width: '300px' }}
              defaultValue={data[findIndex]}
              size="small"
              options={data}
              getOptionLabel={option => option.name}
              onChange={(event, option) => handleSelect(option?.email)}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Selecione uma unidade"
                />
              )}
            />
          )}
    </>
  );
};

export default SelectAutoComplete;
