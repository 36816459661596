import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useFormik } from 'formik';
import { queryParams, queryObjectURI } from 'queryparamsuri';
import { useHistory } from 'react-router-dom';

import {FetchUsersParams} from "../../../../hooks/users/types";

import Button from 'components/Button';
import Input from 'components/Input';
import { Container } from './styles';

type FranchisesSearchProps = {
  handleSearch: (params: FetchUsersParams) => void
  setPage: (page: number) => void;
}
const FranchisesSearch: React.FC<FranchisesSearchProps> = ({
  handleSearch,
  setPage
}) => {
  const history = useHistory();

  const { searchURI, page, limit, query } = queryParams({
    params: [
      'email'
    ],
  });

  useEffect(() => {
    handleSearch(query);
  }, [searchURI]); // eslint-disable-line

  const handleFranchise = (values: any) => {
    setPage(1);
    const params = queryObjectURI(values);
    history.push(params);
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: value => handleFranchise(value),
  });

  const handleCleanFiltered = () => {
    const params = queryObjectURI('');
    history.push(params);
    window.location.reload();
  };

  return (
    <Container>
      <div className="content">
        <form onSubmit={formik.handleSubmit}>
          <div className="unit">
            <Input
              size="small"
              label="Unidade"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>

          <div className="buttons">
            <Button type="submit">
              <Tooltip title="Buscar">
                <SearchIcon />
              </Tooltip>
            </Button>

            <Button color="danger" onClick={handleCleanFiltered}>
              <Tooltip title="Limpar">
                <CloseIcon />
              </Tooltip>
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export { FranchisesSearch }
