import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-left: 12px;
  flex-direction: column;

  form {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }

  .unit {
    margin-bottom: 10px;

    p {
      margin-top: 8px;
    }
  }

  .customer-data {
    p {
      margin-top: 5px;
    }
  }

  .card-data {
    p {
      margin-top: 5px;
    }
  }

  .plan-detail {
    display: flex;
    flex-direction: column;

    .detail-content {
      .plan-description {
        p {
          margin-top: 15px;
          margin-bottom: 10px;
          font-weight: bold;
        }

        ul {
          list-style: none;

          li {
            margin-bottom: 5px;
          }
        }
      }

      .values-content {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        .dotted {
          display: flex;
          width: 100%;
          border-bottom: 2px dotted #ccc;
          margin-left: 10px;
          margin-right: 10px;
        }

        .values {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
        }

        .price {
          font-size: 20px;
          font-weight: bold;
          color: #00b300;
        }
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ContainerButton = styled.div`
  margin-top: 12px;
  display: flex;
`;
