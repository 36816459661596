import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 10px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: calc(100vh - 180px);
  margin-left: 10px;
  padding: 20px;
  background-color: #fafafa;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 30px;
  }

  ::-webkit-scrollbar-track {
    background: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    border: 10px solid #fafafa;
  }
`;
