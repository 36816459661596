const dictionary = {
  pages: {
    subscription: {
      lessDetails: 'Mostrar menos detalhes',
      moreDetails: 'Mostrar mais detalhes',
      status: {
        pending_canceled: 'Cancelamento pendente',
        error: 'Erro',
        failed: 'Falha',
        canceled: 'Cancelado',
        delayed: 'Atrasado',
        refunded: 'Estornado',
        active: 'Ativo',
        success: 'Ativo',
        expired: 'Concluído',
        renew: 'Renovado',
        canceled_by_not_renew: 'Cancelado por não renovação',
        canceled_by_delayed: 'Cancelado por atraso',
        canceled_by_not_visit: 'Cancelado por sem visita'
      },
      detailsPayment: 'Detalhe de pagamento',
      dueDate: 'Vencimento',
      start: 'Início em',
      paidOut: 'Pago em',
      value: 'Valor',
      statusTxt: 'Status',
      platform: {
        1: 'Personnalite',
        2: 'Portal SD Lover',
      },
      services: {
        canceled: 'Cancelado',
        failed: 'Falha',
        blocked: 'Bloqueado',
        enabled: 'Disponível',
        used: 'Usado',
        loser: 'Expirado',
      },
      revenue: {
        fraud_review: 'Fraude',
        canceled: 'Cancelado',
        denied: 'Negado',
        scheduled: 'Agendado',
        pending: 'Processando',
        processing: 'Processando',
        paid: 'Pago',
        refund: 'Estornado',
      },
      fees: {
        fraud_review: 'Fraude',
        canceled: 'Cancelado',
        denied: 'Negado',
        scheduled: 'Agendado',
        pending: 'Processando',
        processing: 'Processando',
        paid: 'Pago',
        refund: 'Estornado',
      },
      renews: {
        scheduled: 'Agendado',
        processed: 'Processado',
        pending_renewal: 'Pendente de renovação',
        renewal_error: 'Erro ao renovar',
        canceled: 'Cancelado',
      },
      refund: {
        fraud_review: 'Fraude',
        canceled: 'Cancelado',
        denied: 'Negado',
        scheduled: 'Agendado',
        pending: 'Processando',
        processing: 'Processando',
        paid: 'Pago',
        refund: 'Estornado',
      },
      dateProceeding: 'Data do procedimento',
      proceedingPayment: 'Procedimentos realizados',
      none: 'Nenhum consumo',
    },
    schedules: {
      ongoing: 'Agendado', // em progresso
      scheduled: 'Agendado',
      canceled: 'Cancelado',
      paid: 'Finalizado',
    },
    contract: {
      status: {
        pending: 'Pendente',
        agreed: 'Aceito',
      },
    },
  },
};

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
};
