import React from 'react';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Container } from './styles';

interface IRotation {
  animation?: boolean;
}

const LoadingRotation: React.FC<IRotation> = ({ animation }) => {
  return (
    <Container className="loading" animation={animation}>
      <AutorenewIcon fontSize="large" />
    </Container>
  );
};

export default LoadingRotation;
