import axios from 'axios';
import React, { createContext, useCallback, useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import valid from 'card-validator';
import api from 'services/api';
import { ICardCreate, ICards, IGateway, UpdateOrDelete } from './types';

export interface CardsContextData {
  cards: ICards[];
  loadingCards: boolean;
  setLoadingCards: any;
  showFormAddNewCard: boolean;
  setShowFormAddNewCard: any;
  dataCardForPayment: ICardCreate;
  setDataCardForPayment: any;
  updateDelete(values: UpdateOrDelete): Promise<void>;
  update(values: UpdateOrDelete): Promise<void>;
  fetchCards(identifier: string): Promise<void>;
  handleCreate(value: IGateway): Promise<void>;
}

const CardsContext = createContext<CardsContextData>({} as CardsContextData);

const CardsProvider: React.FC = ({ children }) => {
  const [cards, setCards] = useState<ICards[]>([] as ICards[]);
  const [loadingCards, setLoadingCards] = useState(false);
  const [showFormAddNewCard, setShowFormAddNewCard] = useState(false);
  const [dataCardForPayment, setDataCardForPayment] = useState<ICardCreate>(
    {} as ICardCreate,
  );

  const { addToast } = useToasts();

  const fetchCards = useCallback(async (identifier: string) => {
    try {
      setLoadingCards(true);
      const response = await api.get(`/customers/${identifier}/payments`);

      setCards(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCards(false);
    }
  }, []);

  const handleCreate = useCallback(
    async (value: IGateway) => {
      const { card } = valid.number(value.number);

      setLoadingCards(true);
      const response = await api.get('gateway-user');

      const gateway = response.data.data;
      try {
        const { data: resGateWay } = await axios.post(
          gateway.paymentProfileUri,
          {
            holder_name: value.name,
            card_expiration: `${value.month}/${value.year}`,
            card_number: value.number.replace(/\D/g, ''),
            card_cvv: value.cvc,
            payment_method_code: 'credit_card',
            payment_company_code: card?.type.replace('-', '_'),
          },
          {
            auth: { username: gateway.vindiPublicKey, password: '' },
          },
        );

        const { gateway_token } = resGateWay.payment_profile;

        const response = await api.post(
          `/customers/${value.identifier}/payments`,
          {
            gateway_token,
          },
        );
        setCards(response.data.data);
        setShowFormAddNewCard(false);
      } catch (err: any) {
        console.log(err);
        setShowFormAddNewCard(true);
        const { errors } = err.response.data;

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        } else {
          const { paymentError } = err.response.data;

          if (paymentError) {
            addToast(paymentError[0], {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoadingCards(false);
      }
    },
    [addToast],
  );

  const update = useCallback(async (values: UpdateOrDelete) => {
    const { card, identifier } = values;

    try {
      setLoadingCards(true);
      const response = await api.put(
        `/customers/${identifier}/payments/${card.id}`,
      );

      setCards(response.data.data);

      addToast('Cartão atualizado com sucesso.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err: any) {
      const { errors } = err.response.data;

      if (errors.length > 0) {
        const error = errors[0];

        if (error) {
          addToast(error.message || error, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } finally {
      setLoadingCards(false);
    }
  }, []);

  const updateDelete = useCallback(async (values: UpdateOrDelete) => {
    const { card, identifier } = values;
    try {
      setLoadingCards(true);

      await api.delete(`/customers/${identifier}/payments/${card.id}`);

      addToast('Cartão removido com sucesso.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err: any) {
      const { errors } = err.response.data;

      if (errors.length > 0) {
        const error = errors[0];

        if (error) {
          addToast('Cartão principal não pode ser removido.', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } finally {
      setLoadingCards(false);
    }
  }, []);

  return (
    <CardsContext.Provider
      value={{
        cards,
        showFormAddNewCard,
        setShowFormAddNewCard,
        loadingCards,
        setLoadingCards,
        fetchCards,
        handleCreate,
        updateDelete,
        update,
        dataCardForPayment,
        setDataCardForPayment,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

function useCards(): CardsContextData {
  const context = useContext(CardsContext);

  if (!context) {
    throw new Error(' useCards must be used within an CardsProvider ');
  }
  return context;
}
export { CardsProvider, useCards };
