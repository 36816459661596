import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const collapseIn = keyframes`
  from {
    right: -400px;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  animation: ${fadeIn} 0.2s ease;
`;

export const Container = styled.div`
  width: 350px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  right: 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  animation: ${collapseIn} 0.3s ease;
`;

export const Header = styled.div`
  display: flex;
  height: 65px;
  align-items: center;
  margin-left: 10px;

  .close-icon {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: linear 0.2s;
    font-size: 20px;
    cursor: pointer;

    :hover {
      background-color: #eee;
    }
  }
`;

export const NotificationList = styled.div`
  .not-notifications {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const NotificationItem = styled.div`
  display: flex;
  position: relative;
  width: 360px;
  background-color: #fff;
  transition: all linear 0.2s;
  border-left: 5px solid #fff;

  :hover {
    /* zoom: 102%;
    left: -15px; */
    border-left: 5px solid #39a2db;
    background-color: #eee;
  }
`;

export const NotificationBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  margin-right: 40px;

  .header-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    padding-bottom: 5px;

    .type {
      font-weight: bold;
      padding-right: 10px;
      margin-right: 10px;
    }

    .date {
      color: #999;
    }

    span + span {
      font-size: 14px;
    }
  }

  .menseger {
    font-size: 15px;
  }
`;

export const ButtonsIcons = styled.div`
  width: 335px;
  display: flex;
  position: absolute;
  padding: 20px 0;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  transition: all linear 0.3s;
  opacity: 0;

  span {
    display: flex;
    width: 30px;
    height: auto;
    cursor: pointer;

    :hover {
      color: #39a2db;
    }
  }

  :hover {
    opacity: 1;
  }

  span + span {
    margin-top: 20px;
  }
`;
