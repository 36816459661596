import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom';

import SignIn from "../pages/SignIn";
import CustomerAddNewCard from "../pages/AddNewCard";
import CustomerContract from "../pages/Contract/customerContract";
import CustomerContractFinish from "../pages/Contract/customerContract/finish";
import ContractNotFound from "../pages/NotFound";

const Auth: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/login" exact component={SignIn} />
      <Route
        path="/integration/plan/:id/addnewcard"
        exact
        component={CustomerAddNewCard}
      />
      <Route
        path="/integration/contracts/:hash"
        exact
        component={CustomerContract}
      />
      <Route
        path="/integration/contracts/:hash/finished"
        exact
        component={CustomerContractFinish}
      />
      <Route path="/404" component={ContractNotFound} />
      <Redirect to="/" />
    </Switch>
  )
}

export default Auth
