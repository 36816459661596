import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

import { BrowserRouter as Router } from 'react-router-dom';

import * as ab18n from 'ab18n';
import locales from 'i18n/locale';
import GlobalStyle from './styles/global';
import { Body } from './styles/styles';

import Container from './components/Layout';

import Routes from './routes';
import AppProvider from './hooks';

ab18n.onLocaleChange((data: any) => data.locale);
ab18n.config(locales);
ab18n.set('pt-BR');

const App: React.FC = () => {
  return (
    <>
      <Body>
        <ToastProvider>
          <Router>
            <AppProvider>
              <Container>
                <Routes />
              </Container>
            </AppProvider>
          </Router>
        </ToastProvider>
      </Body>
      <GlobalStyle />
    </>
  );
};

export default App;
