import styled from '@emotion/styled';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .lottie {
    margin-bottom: -5vh;
    width: 30%;
  }

  h4 {
    font-size: 20px;
  }

  @media (min-width: 320px) {
    .lottie {
      margin-bottom: -5vh;
      width: 80%;
    }
    h4 {
      font-size: 18px;
    }
  }

  @media (min-width: 600px) {
    h4 {
      font-size: 28px;
    }
  }

  @media (min-width: 801px) {
    .lottie {
      margin-bottom: -5vh;
      width: 50%;
    }
    h4 {
      font-size: 24px;
    }
  }

  @media (min-width: 1024px) {
    .lottie {
      margin-bottom: -5vh;
      width: 30%;
    }
    h4 {
      font-size: 20px;
    }
  }
`;
