import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ContainerHelp } from './styles';

const HelpCreateNewCard: React.FC = () => {
  return (
    <div>
      <ContainerHelp
        placement="right-start"
        title={
          <div style={{ fontSize: '18px' }}>
            <p>
              Ao cadastrar um cartão, sua assinatura automaticamente passará a
              ser cobrada pelo cartão que estiver habilitado no momento, caso
              não tenha um cartão cadastrado no momento, a cobrança será feita
              no cartão inicial.
            </p>
          </div>
        }
      >
        <div>
          <HelpOutlineIcon />
        </div>
      </ContainerHelp>
    </div>
  );
};

export default HelpCreateNewCard;
