import styled from '@emotion/styled';
import {css} from "@emotion/react";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 830px;
  width: 100%;
`;

export const Search = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-area {
    display: flex;
    align-items: center;

    .input {
      width: 250px;
      margin-left: 10px;
    }

    .btn-search {
      height: 40px;
      width: auto;
    }
  }
`;

export const ContainerTab = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  .form-client-body {
    :hover {
      background-color: #f9f9f9;
    }
  }

  .see-more-icon {
    cursor: pointer;
    :hover {
      color: #39a2db;
    }
  }

  .td-actions {
    display: flex;
    justify-content: center;

    span + span {
      margin-left: 10px;
    }
  }

  .customer-not-found {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #585656;

    span {
      margin-right: 10px;
      color: #585656;
      font-family: 'Roboto', sans-serif;
    }

    .select {
      select {
        border-radius: 3px;
        border: 1px solid #ccc;
        padding: 5px 8px;
        color: #585656;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
`;

type ContentProps = {
  heigth?: number;
}
export const Content = styled.div<ContentProps>`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;

  .btn-action {
    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }

  ${props =>
  props.heigth &&
  css`
      height: ${`${props.heigth}px`};
    `}
`;
