import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .data {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      .card {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .data-card {
        display: flex;
        margin-left: 20px;
      }
    }

    .btn-area {
      display: flex;

      button + button {
        margin-left: 12px;
      }
    }
  }
`;

type IGridProps = {
  grid: number;
};

export const Grid = styled.div<IGridProps>`
  display: grid;
  gap: 12px;

  ${props =>
    props.grid === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.grid === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

    ${props =>
    props.grid === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `}

    & + div {
    margin-top: 12px;
  }
`;
