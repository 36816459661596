import React from 'react';

import { t } from 'ab18n';
import { format, parseISO } from 'date-fns';
import Span from 'components/Span';
import DataNotFound from 'components/Lottie/DataNotFound';
import { useSubscriptions } from 'hooks/subscriptions';
import { Container } from './styles';

const Visit: React.FC = () => {
  const { subscription } = useSubscriptions();

  return (
    <Container>
      {subscription.customerService !== undefined && (
        <>
          {Object.keys(subscription.customerService).length > 0 ? (
            <table className="table-custom table table-striped table-hover">
              <thead>
                <tr>
                  <td>Serviço</td>
                  <td>Id Avant</td>
                  <td>Data</td>
                  <td style={{ width: '20%' }}>Status</td>
                </tr>
              </thead>
              <tbody>
                {subscription.customerService.map(item => (
                  <tr key={item.id}>
                    <td>{item.service}</td>
                    <td>{item.visit}</td>
                    <td>
                      {item.when === null
                        ? ''
                        : format(parseISO(item.when), 'dd-MM-yyyy')}
                    </td>
                    <td>
                      {item.status && (
                        <Span color={item.status}>
                          {t(`pages.subscription.services.${item.status}`)}
                        </Span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <DataNotFound />
          )}
        </>
      )}
    </Container>
  );
};

export default Visit;
