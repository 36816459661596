import React from 'react';

import { HiIdentification } from 'react-icons/hi';
import { MdEmail } from 'react-icons/md';

import { customMask } from 'utils/mask';

import { ContainerInfo } from './styles';

interface ICustomerInfo {
  name: string;
  identifier: string;
  email: string;
}

const CustomerInfo: React.FC<ICustomerInfo> = ({ name, identifier, email }) => {
  return (
    <ContainerInfo>
      <div className="client-name">{name}</div>
      <div className="client-info">
        <div className="client-info client-identifier client-info-info">
          <div className="client-icon">
            <HiIdentification />
          </div>
          CPF: {customMask(identifier, '999.999.999-99')}
        </div>
        <div className="client-info client-email client-info-info">
          <div className="client-icon">
            <MdEmail />
          </div>
          {email}
        </div>
      </div>
    </ContainerInfo>
  );
};

export default CustomerInfo;
