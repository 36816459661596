import styled from '@emotion/styled';

export const Container = styled.div`
  table {
    .icons {
      width: 60px;
      display: flex;

      span + span {
        margin-left: 10px;
      }
    }
  }
`;
