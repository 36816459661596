import styled from '@emotion/styled';

export const Container = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Grid = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  height: 300px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
  }

  strong {
    margin: 10px 0;
  }
`;
