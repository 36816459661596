import React, { useState } from 'react';
import { ICards } from 'hooks/cards/types';
import { useCards } from 'hooks/cards';
import Button from 'components/Button';
import { Container, Title } from './styles';

interface IConfirme {
  identifier: string;
  children?: any;
  setShowConfirme: any;
  card: ICards;
  type: string;
}

const ConfirmeChangeCard: React.FC<IConfirme> = ({
  identifier,
  setShowConfirme,
  card,
  type,
}) => {
  const { fetchCards, update, updateDelete } = useCards();
  const [loadingCard, setLoadingCards] = useState(false);

  const handleConfirm = async () => {
    setShowConfirme(false);
    setLoadingCards(true);
    await update({ identifier, card });
    fetchCards(identifier);
  };

  const handleDelete = async () => {
    setShowConfirme(false);
    setLoadingCards(true);
    await updateDelete({ identifier, card });
    fetchCards(identifier);
  };

  return (
    <Container>
      {type === 'delete' ? (
        <div className="body">
          <Title>Remover cartão</Title>
          <p>
            Deseja remover o cartão com o final{' '}
            <strong> {card.cardNumberLast}</strong>?
          </p>
          <div className="btn">
            <Button onClick={() => setShowConfirme(false)}>Não</Button>
            <Button onClick={handleDelete} color="danger">
              Sim
            </Button>
          </div>
        </div>
      ) : (
        <div className="body">
          <Title>Trocar cartão</Title>
          <p className="change-info">
            O cartão com o final <strong> {card.cardNumberLast}</strong> será o
            principal em todos as assinaturas <strong>ativas</strong> e para as
            <strong> novas</strong> contratações.
          </p>
          <p>Deseja realizar a troca?</p>
          <div className="btn">
            <Button onClick={() => setShowConfirme(false)}>Não</Button>
            <Button onClick={handleConfirm} color="danger">
              Sim
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ConfirmeChangeCard;
