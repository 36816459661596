import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 18px;

  .lottie {
    width: auto;
    height: 250px;
    margin-top: -50px;
  }

  h5 {
    margin-top: -50px;
  }
`;
