import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  min-width: 430px;

  .header-client {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ContainerInfo = styled.div`
  border: solid 1px #eee;
  border-radius: 8px;

  .client-name {
    font-size: 30px;
  }
  .client-info {
    display: flex;
    font-size: 12px;
    margin-top: 2px;

    .client-info-info {
      margin-right: 20px;
    }

    .client-icon {
      margin-right: 5px;

      font-size: 15px;
    }
  }
`;

export const CardClientPlanInfo = styled.div`
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  border-top: solid 1px #eee;
  border-radius: 8px;

  h4 {
    margin-bottom: 20px;
  }

  .icon-actions {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .icon-cancel {
      :hover {
        color: #da0037;
      }
    }

    .icon-see-more {
      margin-left: 10px;
    }
  }
`;

export const ContainerCard = styled.div`
  margin-top: 20px;
`;

export const ContainerBody = styled.div`
  width: 660px;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-cancel {
    display: flex;
    flex-direction: column;
  }

  .title {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee;
  }

  .ditail {
    margin-top: 20px;

    .plan-describe {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .value-mult {
        margin-top: 18px;

        h1 {
          color: #df8350;
        }
      }
    }
    .text-area {
      margin-top: 20px;
      width: 100%;
      display: flex;
    }
  }
  .payment-and-use {
    display: flex;
  }
`;

export const ContainerBtn = styled.div`
  display: flex;

  button + button {
    margin-left: 8px;
  }
`;

interface IExpand {
  expand: boolean;
}

export const Card = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 4px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  h2 {
    margin: 0;
    color: #688053;
    font-weight: bold;
    font-size: 22px;

    @media only screen and (min-width: 880px) {
      font-size: 28px;
    }

    @media only screen and (min-width: 880px) {
      font-size: 28px;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;

    .due-date {
      margin-top: 6px;
    }

    .btn-status {
      display: flex;
      margin-top: 6px;

      .margin-btnstatus {
        margin-right: 15px;
      }
    }

    @media only screen and (min-width: 880px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;

      .due-date {
        margin-top: 0px;
      }

      .btn-status {
        display: flex;
        margin-top: 0px;

        .margin-btnstatus {
          margin-right: 15px;
        }
      }
    }

    @media only screen and (min-width: 880px) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;

      .due-date {
        margin-top: 0px;
      }

      .btn-status {
        display: flex;
        margin-top: 0px;

        .margin-btnstatus {
          margin-right: 15px;
        }
      }
    }

    .btn {
      border-radius: 8px;
      padding: 5px 12px;
    }
    .green-p {
      font-size: 12px;
      color: #58a82d;
      margin-top: 8px;

      @media only screen and (min-width: 880px) {
        font-size: 13px;
        margin-top: 0px;
      }

      @media only screen and (min-width: 880px) {
        font-size: 13px;
        margin-top: 0px;
      }
    }

    .more-details {
      display: flex;
      align-items: center;
      border-radius: 12px;
      border: 0;
      color: #fff;
      padding: 5px 12px;
      font-size: 12px;

      @media only screen and (min-width: 880px) {
        font-size: 14px;
      }

      @media only screen and (min-width: 880px) {
        font-size: 14px;
      }

      &:hover {
        background: ${shade(0.3, '#586651')};
      }
    }

    span {
      p {
        margin: 0;
      }

      & + span {
        margin-left: 16px;
      }
    }
  }
`;

interface IExpand {
  expand: boolean;
}

export const Expand = styled.div<IExpand>`
  ${props =>
    props.expand
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;

  .table {
    overflow-x: auto;

    td {
      width: 10%;
    }
    .empty {
      background: #eee;
      padding: 6px;
      text-align: center;
      font-size: 12px;
    }

    .rh-table {
      width: 100%;
    }

    .rh-payment-detail {
      width: 20%;
    }

    ::-webkit-scrollbar {
      height: 4px;
    }

    p {
      font-size: 12px;
      color: #58a82d;
      font-weight: 300;
      margin-left: 12px;
      margin-top: 0;
      margin-bottom: 0;

      @media only screen and (min-width: 1280px) {
        font-size: 14px;
      }

      @media only screen and (min-width: 1480px) {
        font-size: 14px;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      white-space: nowrap;

      span {
        flex-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }

  hr {
    width: 100%;
    border: 1px dotted #ccc;
  }

  thead {
    color: #58a82d;
    font-weight: 300;
    font-size: 12px;
  }

  tbody {
    font-size: 12px;
  }

  .green {
    color: #58a82d;
  }

  .orange {
    color: #ff9900;
  }

  .red {
    color: red;
  }
`;

export const GridLoad = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  p {
    text-align: center;
    font-size: 13px;
    margin-top: 4px;
  }
`;
