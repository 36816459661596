import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  .header {
    display: flex;
    justify-content: space-between;
  }
`;
