import React, { createContext, useCallback, useState, useContext } from 'react';

import api from 'services/api';
import { IEmployees } from './types';

export interface EmployeeContextData {
  employees: IEmployees[];
  loading: boolean;
  fetchEmployees(): Promise<void>;
}

const EmployeeContext = createContext<EmployeeContextData>(
  {} as EmployeeContextData,
);

const EmployeesProvider: React.FC = ({ children }) => {
  const [employees, setEmployees] = useState<IEmployees[]>([] as IEmployees[]);
  const [loading, setLoading] = useState(false);

  const fetchEmployees = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`employees`);

      setEmployees(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <EmployeeContext.Provider
      value={{
        employees,
        loading,
        fetchEmployees,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

function useEmployees(): EmployeeContextData {
  const context = useContext(EmployeeContext);

  if (!context) {
    throw new Error(' useEmployees must be used within an EmployeesProvider ');
  }
  return context;
}
export { EmployeesProvider, useEmployees };
