import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 15px;

  .content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  span {
    margin-right: 10px;
    font-weight: bold;
  }

  form {
    display: flex;
    width: 100%;
    justify-content: space-between;

    div {
      width: 100%;
      margin-right: 10px;

      :last-child {
        margin-right: 0px;
      }
    }
  }

  .buttons {
    display: flex;
    flex: 1;
    height: 40px;
    margin-right: 0px;

    button + button {
      margin-left: 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
`;
