import React, { useEffect, useState } from 'react';

import Modal from 'components/Modal';
import ModalPrintContract from 'components/modalPrintContract';
import Pagination from 'components/Pagination';
import ItensPerPageComponent from 'components/ItensPerPage';
import SearchComponent from 'components/Search';

import { useContract } from 'hooks/contracts';
import { useEmployees } from 'hooks/employees';
import { useReport } from 'hooks/reports';
import { ISubscriptions, IContract } from 'hooks/subscriptions/types';
import { useSubscriptions } from 'hooks/subscriptions';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

import SignatureTab from './signatureTab';
import { Container, Search, ContainerTab } from './styles';

const Customer: React.FC = () => {
  const {
    subscriptions,
    fetchSubscriptions,
    loading,
    loadingSubscriptioUpdate,
    setSubscriptionId,
    setIdentifierCustomer,
    setPage,
    page,
    setLimit,
    limit,
    total,
  } = useSubscriptions();
  const { fetchEmployees, employees } = useEmployees();
  const { value } = useReport();
  const { fetchContractCustomer } = useContract();
  const [hash, setHash] = useState('');
  const [viewerContract, setViewerContract] = useState(false);

  const { addToast } = useToasts();

  const history = useHistory();

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleShow = async (value: ISubscriptions) => {
    const { id, customer } = value;
    localStorage.setItem('@ClubSd:subscriptionId', id.toString());
    await setSubscriptionId(id);
    setIdentifierCustomer(customer.identifier);

    history.push(`/signature/detail`);
  };

  const handleContractPrint = async (contract: IContract | null) => {
    if (contract) {
      setViewerContract(true);
      setHash(contract.hash);
      await fetchContractCustomer(contract.hash);
    } else {
      addToast(`Não há contrato vinculado`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Container>
      <Search>
        <SearchComponent
          dataRange={value}
          optionEmployees={employees}
          handleSearch={fetchSubscriptions}
          setPage={setPage}
        />
      </Search>

      <ContainerTab>
        <SignatureTab
          subscriptions={subscriptions}
          loading={loading}
          loadingSubscriptioUpdate={loadingSubscriptioUpdate}
          handleContractPrint={handleContractPrint}
          handleShow={handleShow}
        />

        <div className="pagination">
          <Pagination
            total={total}
            page={page}
            setPage={setPage}
            itensPerPage={limit}
          />

          <ItensPerPageComponent
            setItensPerPage={setLimit}
            itensPerPage={limit}
            setPage={setPage}
            total={total}
          />
        </div>
      </ContainerTab>

      {viewerContract && (
        <Modal
          width="1200px"
          status={viewerContract}
          handleClose={() => setViewerContract(!viewerContract)}
        >
          <ModalPrintContract
            openModal={setViewerContract}
            contractHash={hash}
          />
        </Modal>
      )}
    </Container>
  );
};

export default Customer;
