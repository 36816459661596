import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonIcon = styled.button`
  border: none;
`;

export const AddNewCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #eee;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  .add-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .dotted {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dotted #ccc;
      border-radius: 15px;
      margin: 20px;
      padding: 40px 0;

      p + p {
        margin-top: 8px;
        font-size: 18px;
        color: var(--red);
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        svg {
          font-size: 50px;
          opacity: 0.3;
          margin-bottom: 15px;
          cursor: pointer;
          transition: all 0.2s ease;

          :hover {
            font-size: 60px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;
