import React from 'react';

import { GoogleLogin } from 'react-google-login';
import { useAuth } from 'hooks/auth';

import Button from '../../components/Button';
import { Container, Grid } from './styles';

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const SignIn: React.FC = () => {
  const { signIn, loading } = useAuth();

  const handleSignIn = (event: any) => {
    signIn(event);
  };

  const onFailure = (event: any) => {
    console.log('falha', event);
  };

  return (
    <Container>
      <Grid>
        <img src="/static/logo-sd.png" alt="SD" />
        <strong>SD Lover</strong>
        <GoogleLogin
          clientId={String(REACT_APP_GOOGLE_CLIENT_ID)}
          render={renderProps => (
            <Button loading={loading} onClick={renderProps.onClick}>
              Entrar
            </Button>
          )}
          onSuccess={handleSignIn}
          onFailure={onFailure}
        />
      </Grid>
    </Container>
  );
};

export default SignIn;
