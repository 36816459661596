import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import { AiOutlineEye } from 'react-icons/ai';

import { t } from 'ab18n';
import { customMask } from 'utils/mask';
import { dateTimeZone } from 'utils/timeZone';

import Loading from 'components/Loading';
import ButtonStatus from 'components/Button/btnStatus';
import DataNotFound from 'components/Lottie/DataNotFound';

import { ISubscriptions } from 'hooks/subscriptions/types';

import { capitalize } from 'utils/capitalize';
import { Container } from './styles';

interface ISubscriptionTab {
  loading: boolean;
  loadingSubscriptioUpdate: boolean;
  subscriptions: ISubscriptions[];
  handleContractPrint: any;
  handleShow: any;
}

const SignatureTab: React.FC<ISubscriptionTab> = ({
  loading,
  loadingSubscriptioUpdate,
  subscriptions,
  handleContractPrint,
  handleShow,
}) => {
  return (
    <Container>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <td>#</td>
            <td>Cliente</td>
            <td>CPF</td>
            <td>Plano</td>
            <td>Contratado</td>
            <td>Plataforma</td>
            <td style={{ width: '100px' }}>Status</td>
            <td align="center" style={{ width: '100px' }}>
              Ações
            </td>
          </tr>
        </thead>
        {loading || loadingSubscriptioUpdate ? (
          <tbody>
            <tr>
              <td colSpan={100} style={{ background: '#fff' }}>
                <Loading marginTop={50} />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {subscriptions.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{capitalize(item.customer.name)}</td>
                <td>
                  {customMask(item.customer.identifier, '999.999.999-99')}
                </td>
                <td>{item.plan.name}</td>
                <td>{dateTimeZone(item.createdAt)}</td>
                <td>
                  {item.platform !== null &&
                    t(`pages.subscription.platform.${item.platform}`)}
                </td>
                <td>
                  {item.status && (
                    <ButtonStatus
                      color={item.status}
                      style={{ width: '100px' }}
                    >
                      {t(`pages.subscription.status.${item.status}`)}
                    </ButtonStatus>
                  )}
                </td>
                <td align="center" className="td-actions">
                  <span>
                    <Tooltip
                      title="Ver mais"
                      className="see-more-icon"
                      onClick={() => handleShow(item)}
                    >
                      <span>
                        <AiOutlineEye size={20} />
                      </span>
                    </Tooltip>
                  </span>

                  <span>
                    <Tooltip
                      title="Contrato"
                      className="see-more-icon"
                      onClick={() => handleContractPrint(item.contract)}
                    >
                      <DescriptionIcon />
                    </Tooltip>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!loading && subscriptions.length === 0 && (
        <div className="customer-not-found">
          <DataNotFound />
        </div>
      )}
    </Container>
  );
};

export default SignatureTab;
