import VMasker from 'vanilla-masker';

export const valueToCurrency = (value: number) => {
  return value.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
};

export const stringNumber = (value: string) => value.replace(/[^\d]+/g, '');

export const customMask = (value: any, mask: string) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, mask) : '';

export const cpfMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '999.999.999-99')
    : '';

export const cnpjMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '99.999.999/9999-99')
    : '';

export const phoneMask = (value: any) =>
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '+99 (99) 99999-9999')
    : '';
