import React, { useState } from 'react';

import { useCards } from 'hooks/cards';
import { useContract } from 'hooks/contracts';
import { ICards } from 'hooks/cards/types';

import Loading from 'components/Loading';

import Card from './card';
import AddNewCard from './addNewCard';
import FormAddNewCardData from './formAddNewCardData';

import { Conteiner, Header, Divider } from './styles';
import HelpCreateNewCard from './helpText';

interface IComponentCards {
  cards: ICards[];
  identifier: string;
}

const ComponentCards: React.FC<IComponentCards> = ({ cards, identifier }) => {
  const { loadingContractCustomer } = useContract();
  const { loadingCards, setShowFormAddNewCard, showFormAddNewCard } =
    useCards();

  const handleAddNewCard = () => {
    if (cards.length < 2) {
      setShowFormAddNewCard(!showFormAddNewCard);
    }
  };

  const handleBack = () => {
    setShowFormAddNewCard(!showFormAddNewCard);
  };

  return (
    <Conteiner>
      {loadingCards || loadingContractCustomer ? (
        <Loading />
      ) : (
        <div className="cards">
          <Header>
            {cards !== undefined && (
              <>
                {cards.length === 0 ? (
                  <h4>Não há cartão salvo</h4>
                ) : (
                  <div>
                    {cards.length > 1 ? (
                      <h4>Cartões salvos</h4>
                    ) : (
                      <h4>Cartão salvo</h4>
                    )}
                  </div>
                )}
              </>
            )}

            <div className="help">
              <HelpCreateNewCard />
            </div>
          </Header>
          <Divider />
          <Card cards={cards} identifier={identifier} />
        </div>
      )}

      {!showFormAddNewCard && (
        <AddNewCard cards={cards} handleAddNewCard={handleAddNewCard} />
      )}

      {showFormAddNewCard && (
        <FormAddNewCardData identifier={identifier} handleBack={handleBack} />
      )}
    </Conteiner>
  );
};

export default ComponentCards;
