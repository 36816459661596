import React from 'react';
import { format, parseISO } from 'date-fns';

import DataNotFound from 'components/Lottie/DataNotFound';
import { valueToCurrency } from 'utils/mask';
import { useSubscriptions } from 'hooks/subscriptions';

import { Container } from './styles';

const Refunds: React.FC = () => {
  const { subscription } = useSubscriptions();
  const refunds = subscription?.bills?.map((bill) => {
    return bill.charges.map((charge) => ({
      ...charge,
      period: bill.period,
      billId: bill.id,
    }))
  })
    .flat()
    .filter(
      (refund) => refund.status === 'canceled'
        && refund.transactions.some(
          (transaction) => transaction.status === 'refund')
    ) || []

  return (
    <Container>
      {refunds.length ? (
        <table className="table-custom table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>Parcela</td>
              <td>Valor</td>
              <td>Motivo</td>
              <td>Data do pagamento</td>
              <td>ID da fatura (pagamento estornado)</td>
            </tr>
          </thead>
          {refunds.map(refund => (
            <tbody>
              <tr key={refund.id}>
                <td>{refund.id}</td>
                <td>{refund.period?.cycle}ª</td>
                <td>{valueToCurrency(refund.price)}</td>
                <tr>
                  <td colSpan={4}>{refund.message ?? 'Nenhum motivo informado.'}</td>
                </tr>
                <td>
                  {!refund.paidAt
                    ? ''
                    : format(parseISO(refund.paidAt), 'dd-MM-yyyy')}
                </td>
                <td>{refund.billId}</td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Refunds;
