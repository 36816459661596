import React, { useState, useEffect } from 'react';

import Loading from 'components/Loading';
import { useCards } from 'hooks/cards';
import { useSubscriptions } from 'hooks/subscriptions';
import { useParams } from 'react-router-dom';
import { useContract } from 'hooks/contracts';
import { useEmployees } from 'hooks/employees';
import PaymentDetail from './paymentDatail';
import DataCard from './dataCard';

import { Container, ContentCard, Divider, LoadingDiv } from './styles';

const Purchase: React.FC = () => {
  const { loading } = useSubscriptions();
  const { fetchCards, cards } = useCards();
  const { fetchContractCustomer, contractCustomer } = useContract();
  const { employees, fetchEmployees } = useEmployees();
  const [identifier, setIdentifier] = useState('');
  const { hash }: any = useParams();

  useEffect(() => {
    fetchEmployees();
    fetchContractCustomer(hash);
  }, [fetchEmployees, fetchContractCustomer, hash]);

  useEffect(() => {
    if (Object.keys(contractCustomer).length > 0) {
      setIdentifier(contractCustomer.customer.identifier);
    }
    fetchCards(identifier);
  }, [fetchCards, contractCustomer, identifier]);

  return (
    <Container>
      <ContentCard>
        <div className="card-content">
          <h3>Informações de pagamento</h3>
          <Divider />
          <DataCard identifier={identifier} cards={cards} />
        </div>
      </ContentCard>

      <PaymentDetail
        contractCustomer={contractCustomer}
        employees={employees}
        cards={cards}
      />

      {loading && (
        <LoadingDiv>
          <div>
            <Loading />
            <p
              style={{ color: '#585656', marginTop: '80px', fontSize: '20px' }}
            >
              Pagamento sendo realizado, favor aguardar...
            </p>
          </div>
        </LoadingDiv>
      )}
    </Container>
  );
};

export default Purchase;
