import React, {useEffect} from "react";

import {useUsers} from "../../hooks/users";

import ItensPerPageComponent from "../../components/ItensPerPage";
import Pagination from "../../components/Pagination";
import {FranchisesTable} from "./components/FranchisesTable";
import {FranchisesSearch} from "./components/FranchisesSearch";
import {Container, ContainerTab, Search} from "./styles";


const SelectFranchise: React.FC = () => {
  const {
    fetchUsers,
    users: franchises,
    setPage,
    page,
    setLimit,
    limit,
    total,
    loading
  } = useUsers()

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Container>
      <Search>
        <FranchisesSearch handleSearch={fetchUsers} setPage={setPage} />
      </Search>

      <ContainerTab>
        <FranchisesTable loading={loading} franchises={franchises} />

        <div className="pagination">
          <Pagination
            total={total}
            page={page}
            setPage={setPage}
            itensPerPage={limit}
          />

          <ItensPerPageComponent
            setItensPerPage={setLimit}
            itensPerPage={limit}
            setPage={setPage}
            total={total}
          />
        </div>
      </ContainerTab>
    </Container>
  )
}

export default SelectFranchise
