import styled from '@emotion/styled';

interface IProps {
  display?: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 18px;

  .lottie {
    width: auto;
    height: 250px;
    margin-bottom: -10px;
  }
`;
