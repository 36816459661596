import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  .buttons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    position: absolute;
    right: 0;

    button + button {
      margin-left: 8px;
    }

    button {
      padding: 10px 50px;
      white-space: nowrap;
    }
  }

  .help {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid red;
    background-color: rgba(252, 210, 209, 0.2);
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 20px;
`;

export const ContainerModalConfirm = styled.div`
  .buttons-confirm {
    display: flex;

    button + button {
      margin-left: 8px;
    }
  }
`;
