import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import { useAuth } from 'hooks/auth';
import { useNotification } from 'hooks/notification';

// import Notifications from 'components/ModalNotification';
import ModalNotifications from 'components/ModalNotification/modal';

import Nav from '../Navigation';

import { Container, Header, Content, Profile, Grid, Logo } from './styles';

const PageLayout: React.FC = ({ children }) => {
  const { isVisible } = useNotification();
  const { user } = useAuth();

  return (
    <Container>
      <Content>
        <Header>
          <div className="content">
            <div className="container">
              <Logo>
                <img src="/static/logo-sd.png" alt="SD" />
              </Logo>
              <Nav />
            </div>
            {/* <div className="notification">
            <Notifications />
          </div> */}
            <Profile>
              <div className="profile">
                <span>{user.username}</span>
                <small>{user.email}</small>
              </div>
              <Avatar />
            </Profile>
          </div>
        </Header>

        <Grid>{children}</Grid>
        {isVisible && <ModalNotifications />}
      </Content>
    </Container>
  );
};

export default PageLayout;
