import React, { useState } from 'react';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { format, parseISO } from 'date-fns';
import { Switch, FormControlLabel } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import Modal from 'components/Modal';

import { ICards } from 'hooks/cards/types';

import { Container } from './styles';
import ConfirmeChangeCard from './confirmChangeCard';

type IFetch = {
  cards: ICards[];
  identifier: string;
};

const Card: React.FC<IFetch> = ({ cards, identifier }) => {
  const [type, setType] = useState('');
  const [showConfirme, setShowConfirme] = useState(false);
  const [card, setCard] = useState<ICards>({} as ICards);

  const handleActiveCard = async (card: ICards) => {
    setCard(card);
    setType('change');
    setShowConfirme(true);
  };

  const handleDeleteCard = async (card: ICards) => {
    setCard(card);
    setType('delete');
    setShowConfirme(true);
  };

  function hideNumberCard(cardNumberFirst: string, cardNumberLast: string) {
    const asterisk = '*';
    const asteriskRepeat = asterisk.repeat(
      16 - (cardNumberFirst.length + cardNumberLast.length),
    );

    return `${cardNumberFirst}${asteriskRepeat}${cardNumberLast}`;
  }

  return (
    <Container>
      {cards !== undefined && (
        <>
          {cards.length > 0 && (
            <div className="cards">
              <div className="cards-saved-area">
                {cards?.length > 0 &&
                  cards.map(card => (
                    <div className="cards-saved">
                      <div className={card.isActive ? 'active' : ''}>
                        <div
                          className="switch"
                          style={{ marginBottom: '10px' }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={card.isActive}
                                onChange={() => handleActiveCard(card)}
                                name="cardMain"
                                color="primary"
                                disabled={!!card.isActive}
                              />
                            }
                            label={
                              card.isActive
                                ? 'Cartão habilitado'
                                : 'Cartão não habilitado'
                            }
                          />
                          <div className="delete-icon">
                            <Tooltip
                              title="Remover"
                              onClick={() => handleDeleteCard(card)}
                            >
                              <DeleteForeverIcon />
                            </Tooltip>
                          </div>
                        </div>
                        <Cards
                          cvc=""
                          expiry={format(
                            parseISO(card.cardExpiration),
                            'MMyyyy',
                          )}
                          name={card.name}
                          number={hideNumberCard(
                            card.cardNumberFirst,
                            card.cardNumberLast,
                          )}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
      <Modal status={showConfirme} handleClose={setShowConfirme}>
        <ConfirmeChangeCard
          identifier={identifier}
          setShowConfirme={setShowConfirme}
          card={card}
          type={type}
        />
      </Modal>
    </Container>
  );
};

export default Card;
