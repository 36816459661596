export const serviceNameAndQuantity = (arr: any) => {
  const arrayServices = arr.map((ser: any) => ser.service);

  const counts: any = {};
  arrayServices.forEach((x: string) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  const result = Object.keys(counts).map(key => {
    return [<li>{`${counts[key]}x ${key}`}</li>, <br />];
  });

  return result;
};
