import React from 'react';

import { MdRemoveRedEye } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { t } from 'ab18n';
import { format, parseISO } from 'date-fns';
import { cpfMask } from 'utils/mask';
import { ISubscriptions } from 'hooks/subscriptions/types';
import { ContainerModal, Title } from './styles';

interface IModalDashboard {
  setIsVisible: any;
  subscriptionStatus: ISubscriptions[];
  typeStatusSubscription: string;
}

const ModalDashboard: React.FC<IModalDashboard> = ({
  setIsVisible,
  subscriptionStatus,
  typeStatusSubscription,
}) => {
  const history = useHistory();

  const handleShow = (id: number) => {
    history.push(`/customer/${id}`);
  };

  return (
    <ContainerModal>
      <Title>
        Assinaturas <span>{typeStatusSubscription}</span>
      </Title>
      {subscriptionStatus.length === 0 ? (
        <Loading />
      ) : (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>Cliente</td>
              <td>CPF</td>
              <td>Plano</td>
              <td>Data da ação</td>
              <td>Status</td>
              <td>Ação</td>
            </tr>
          </thead>
          <tbody>
            {subscriptionStatus.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.customer.name}</td>
                <td>{cpfMask(item.customer.identifier)}</td>
                <td>{item.plan.name}</td>
                <td>{format(parseISO(item.createdAt), 'dd-MM-yyyy')}</td>
                <td>{t(`pages.signatures.status.${item.status}`)}</td>
                <td align="right">
                  <button
                    type="button"
                    className="icon-btn"
                    onClick={() => handleShow(item.customer.id)}
                  >
                    <MdRemoveRedEye size={22} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Button onClick={() => setIsVisible(false)}>Fechar</Button>
    </ContainerModal>
  );
};

export default ModalDashboard;
