import React from 'react';

import { t } from 'ab18n';
import { format, parseISO } from 'date-fns';
import Span from 'components/Span';
import DataNotFound from 'components/Lottie/DataNotFound';
import { valueToCurrency } from 'utils/mask';

import { useSubscriptions } from 'hooks/subscriptions';

import { Container } from './styles';

const Fees: React.FC = () => {
  const { subscription } = useSubscriptions();
  const outgoingBills = subscription?.bills?.filter(
    (bill) => bill.origin === 'fee'
  ) || [];

  return (
    <Container>
      {outgoingBills.length ? (
        <table className="table-custom table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>Valor</td>
              <td>Data do pagamento</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {outgoingBills.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{valueToCurrency(item.price)}</td>
                <td>
                  {!item.billingAt
                    ? ''
                    : format(parseISO(item.billingAt), 'dd-MM-yyyy')}
                </td>
                <td>
                  {item.status && (
                    <Span color={item.status}>
                      {t(`pages.subscription.fees.${item.status}`)}
                    </Span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <DataNotFound />
      )}
    </Container>
  );
};

export default Fees;
