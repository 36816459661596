import React, { useEffect, useState, useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import ReactHtmlParser from 'react-html-parser';

import Loading from 'components/Loading';
import { useContract } from 'hooks/contracts';
import { useAuth } from 'hooks/auth';
import { useCustomer } from 'hooks/customer';

import { contractTemplate } from 'utils/contractTemplate';
import Button from 'components/Button';

import { Conteiner, Content } from './styles';

interface IContract {
  hash: string;
  openModal: any;
}

const Contract: React.FC<IContract> = ({ hash, openModal }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { user } = useAuth();
  const { customer } = useCustomer();
  const { fetchContractCustomer, contractCustomer, loadingContractCustomer } =
    useContract();

  const [contractRes, setContractRes] = useState('');

  useEffect(() => {
    fetchContractCustomer(hash);
  }, [fetchContractCustomer, hash]);

  useEffect(() => {
    if (Object.keys(contractCustomer).length > 0) {
      const servicesName = contractCustomer.plan.services
        ?.filter((item: any) => item.name)
        .map((item: any) => item.name);

      const insertDataContract = contractTemplate({
        template: contractCustomer.template,
        plan: contractCustomer.plan,
        user,
        servicesName,
        customer,
        customerContract: contractCustomer.customer,
        createdAt: contractCustomer.createdAt,
        unit: contractCustomer.unit,
        acceptAt: contractCustomer.acceptAt,
      });

      setContractRes(insertDataContract);
    }
  }, [contractCustomer, customer, user]);

  return (
    <Conteiner>
      {loadingContractCustomer ? (
        <Loading />
      ) : (
        <>
          <Content ref={componentRef}>
            <div className="contract">{ReactHtmlParser(contractRes)}</div>
            <div className="signature-customer">
              <h4>Assinatura da(o) cliente</h4>
            </div>
          </Content>
          <div className="buttons">
            <Button color="danger" onClick={handlePrint}>
              Imprimir
            </Button>

            <Button onClick={() => openModal(false)}>Fechar</Button>
          </div>
        </>
      )}
    </Conteiner>
  );
};

export default Contract;
