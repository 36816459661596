import styled from '@emotion/styled';

import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = styled(Tooltip)``;

export const Conteiner = styled.div`
  .cards {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 15px;

  .help {
    margin-left: 20px;
    margin-top: 2px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
`;

export const ContainerHelp = styled(Tooltip)`
  .MuiTooltip-tooltip {
    width: 800px;
    background-color: red;
  }
`;
