import { css } from '@emotion/react';
import styled from '@emotion/styled';

type IGridProps = {
  grid: number;
};

export const Container = styled.div`
  h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    margin-bottom: 4px;
  }

  .switch {
    display: flex;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  padding-bottom: 14px;
  padding-top: 14px;
`;

export const Grid = styled.div<IGridProps>`
  display: grid;
  gap: 12px;

  ${props =>
    props.grid === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.grid === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

    ${props =>
    props.grid === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `}

    & + div {
    margin-top: 12px;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 4px;
  display: flex;

  button + button {
    margin-left: 4px;
  }
`;
