import React, { createContext, useCallback, useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import api from 'services/api';

import { useCustomer } from 'hooks/customer';
import { Position } from 'hooks/position'
import { IContract, IDataContract } from './types';

export interface IUpdate {
  contract: IContract;
  agreeMethod?: number;
  notificationMethod?: number;
  sendOptionSelected?: string;
  printObservation?: string;
  position?: Position;
}
export interface IAgreed {
  hash: string;
  agreeMethod: number;
  position: Position;
}

export interface ISubmit {
  contract: IDataContract;
  notificationMethod: number;
  sendOptionSelected: string;
}

export interface ContractContextData {
  contracts: IContract[];
  contract: IContract;
  loading: boolean;
  loadingContractCustomer: boolean;
  setLoadingContractCustomer: any;
  contractCustomer: IContract;
  setContractCustomer: any;
  dataForContract: IDataContract;
  setDataForContract: any;
  contractErros: any;
  submit(data: ISubmit): Promise<void>;
  update(value: IUpdate): Promise<void>;
  agreedContract(value: IAgreed): Promise<void>;
  fetchContract(value: string): Promise<void>;
  fetchValidateContract(
    planId: number,
    customerIdentifier: string,
  ): Promise<void>;
  fetchContractCustomer(value: string): Promise<void>;
}

const ContractContext = createContext<ContractContextData>(
  {} as ContractContextData,
);

const ContractProvider: React.FC = ({ children }) => {
  const [contractErros, setContractErros] = useState();
  const { setCustomer } = useCustomer();
  const [contracts, setContracts] = useState<IContract[]>([] as IContract[]);
  const [contract, setContract] = useState<IContract>({} as IContract);
  const [contractCustomer, setContractCustomer] = useState<IContract>(
    {} as IContract,
  );
  const [dataForContract, setDataForContract] = useState<IDataContract>(
    {} as IDataContract,
  );
  const [loading, setLoading] = useState(false);
  const [loadingContractCustomer, setLoadingContractCustomer] = useState(false);
  const { addToast } = useToasts();

  const fetchContract = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`contracts`);
      setContracts(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchValidateContract = useCallback(
    async (planId, customerIdentifier) => {
      try {
        setLoading(true);
        const response = await api.get(
          `contracts/plans/${planId}/customers/${customerIdentifier}`,
        );
        setContract(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const fetchContractCustomer = useCallback(async (hash: string) => {
    try {
      setLoadingContractCustomer(true);
      const response = await api.get(`integration/contracts/${hash}`);
      setContractCustomer(response.data);

      const { identifier } = response.data.customer;

      const res = await api.get(`customers/${identifier}`);
      setCustomer(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingContractCustomer(false);
    }
  }, []);

  const agreedContract = useCallback(
    async (values: IAgreed) => {
      const { hash, agreeMethod, position } = values;
      try {
        setLoading(true);

        const response = await api.post(`integration/contracts/${hash}`, {
          ...position,
          agreeMethod
        });

        setContracts([...contracts, response.data]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [contracts, addToast],
  );

  const submit = useCallback(
    async (data: ISubmit) => {
      const { contract, notificationMethod, sendOptionSelected } = data;

      try {
        setLoading(true);

        const response = await api.post(
          `contracts/plans/${contract.plan.id}/customers/${contract.customer.identifier}`,
          {
            notificationMethod,
          },
        );

        setContracts([...contracts, response.data]);

        addToast(`Contrato enviado para ${sendOptionSelected}`, {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err: any) {
        const { errors } = err.response.data;
        setContractErros(errors);

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [contracts, addToast],
  );

  const update = useCallback(
    async (values: IUpdate) => {
      const {
        printObservation,
        contract,
        agreeMethod,
        notificationMethod,
        sendOptionSelected,
        position
      } = values;

      try {
        setLoading(true);
        const method = sendOptionSelected ? api.patch : api.put;
        const response = await method(
          `contracts/plans/${contract.plan.id}/customers/${contract.customer.identifier}`,
          {
            ...position,
            agreeMethod,
            notificationMethod,
          },
        );

        setContractCustomer(response.data);

        if (sendOptionSelected) {
          addToast(`Contrato enviado para ${sendOptionSelected}`, {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err: any) {
        const { errors } = err.response.data;
        setContractErros(errors);

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <ContractContext.Provider
      value={{
        contract,
        contracts,
        loading,
        loadingContractCustomer,
        setLoadingContractCustomer,
        fetchContract,
        fetchValidateContract,
        fetchContractCustomer,
        contractCustomer,
        contractErros,
        setContractCustomer,
        dataForContract,
        setDataForContract,
        submit,
        update,
        agreedContract,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

function useContract(): ContractContextData {
  const context = useContext(ContractContext);

  if (!context) {
    throw new Error(' useContract must be used within an ContractProvider ');
  }
  return context;
}
export { ContractProvider, useContract };
