import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyle = () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

      * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
      }

      html,
      body,
      #root {
        min-height: 100%;
      }

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
      }

      :root {
        --primary: #9ea58a;
        --green: #4aa96c;
        --orange: #df8350;
        --red: #ff3333;
        --gray: #585656;
      }

      a {
        text-decoration: none;
        color: #585656;
      }
      p {
        strong {
          font-weight: 800;
        }
      }
      strong {
        font-weight: bold;
      }
      html {
        height: 100%;
      }
      body {
        background: #eee;
        color: #585656;
        -webkit-font-smoothing: antialiased;
        height: 100%;
        position: relative;
        overflow-x: hidden;
      }
      body,
      input,
      button {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
      }
      h1,
      h2,
      h3 h4,
      h5,
      h6 {
        font-weight: bold;
        color: #585656;
      }
      button {
        cursor: pointer;
      }

      .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
      }
      table {
        border-collapse: collapse;
        flex: 1;
      }
      select option {
        font-size: 16px;
      }
      table {
        display: table;
        border-collapse: separate;
        border-spacing: 2px;
        border-color: grey;
        font-size: 12px;
        width: 100%;
        .table-icon {
          display: flex;
          align-items: center;
          svg {
            margin-right: 4px;
          }
        }
      }
      thead {
        font-weight: bold;
        color: #636363;
        font-size: 14px;
      }
      thead tr td {
        padding: 10px;
      }
      tbody tr td {
        padding: 10px;
        border-top: 1px solid #eee;
        color: #515154;
      }
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.02);
      }
      .table-hover tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }

      .icon-btn {
        cursor: pointer;

        svg {
          color: #5f5f5f;

          :hover {
            color: #39a2db;
          }
        }
      }

      .icon-table {
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      .green-price {
        color: #2eb82e;
      }

      .dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border 0.24s ease-in-out;
      }

      .container-dropzone-img-one {
        display: flex;
      }

      .img-dropzone {
        list-style: none;
        width: 120px;
        height: 120px;
        margin-right: 8px;
        background: #eee;
        align-items: center;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          overflow: hidden;
          list-style: none;
          font-size: 14px;
          color: #bdbdbd;

          img {
            width: 120px;
          }
        }
      }
    `}
  />
);

export default GlobalStyle;
