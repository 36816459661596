import styled from '@emotion/styled';

export const Container = styled.div`
  .buttons {
    display: flex;
    margin-top: 20px;

    button + button {
      margin-left: 10px;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
`;
