import React, { createContext, useContext, useCallback, useState } from 'react';

import api from 'services/api';
import { INotifications, INotificationUpdate } from './types';

export interface NotificationData {
  isVisible: boolean;
  loading: boolean;
  setIsVisible: any;
  setCountNotifications: any;
  countNotifications: number;
  notifications: INotifications[];
  update(values: INotificationUpdate): Promise<void>;
  fetchNotifications(): Promise<void>;
}

const Notification = createContext<NotificationData>({} as NotificationData);

const NotificationProvider: React.FC = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [notifications, setNotifications] = useState<INotifications[]>(
    [] as INotifications[],
  );
  const [loading, setLoading] = useState(false);
  const [countNotifications, setCountNotifications] = useState(0);

  const fetchNotifications = useCallback(async () => {
    // try {
    //   setLoading(true);
    //   const response = await api.get('notifications?read=false');
    //   setNotifications(response.data);
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   setLoading(false);
    // }
  }, []);

  const update = useCallback(async ({ id }: INotificationUpdate) => {
    try {
      setLoading(true);
      await api.patch(`notifications/${id}`, {
        id,
        read: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Notification.Provider
      value={{
        loading,
        isVisible,
        setIsVisible,
        notifications,
        fetchNotifications,
        setCountNotifications,
        countNotifications,
        update,
      }}
    >
      {children}
    </Notification.Provider>
  );
};

function useNotification(): NotificationData {
  const context = useContext(Notification);

  if (!context) {
    throw new Error(
      ' useNotification must be used within an NotificationProvider ',
    );
  }
  return context;
}
export { NotificationProvider, useNotification };
