import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import { AiOutlineEye } from 'react-icons/ai';

import { t } from 'ab18n';
import { stringNumber, customMask } from 'utils/mask';

import Loading from 'components/Loading';
import Input from 'components/Input';
import SelectAutoComplete from 'components/SelectAutoComplete';
import BtnStatus from 'components/Button/btnStatus';
import CustomerNotFound from 'components/Lottie/CustomerNotFound';
import CustomerSearch from 'components/Lottie/identifierSearch';

import { useCustomer } from 'hooks/customer';
import { ICustomer } from 'hooks/customer/types';

import { Container, Search, ContainerTab } from './styles';

const Customer: React.FC = () => {
  const [identifier, setIdentifier] = useState('');
  const {
    customers,
    fetchCustomer,
    fetchCustomers,
    loading,
    setCustomer,
    setCustomers,
  } = useCustomer();

  const history = useHistory();

  useEffect(() => {
    setCustomers([]);
  }, []);

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomers([]);
    const { value } = event.target;

    if (value.length <= 14) {
      setIdentifier(value);
    }

    if (value.length < 14) {
      return;
    }
    fetchCustomers(stringNumber(value));
  };

  const handleShow = (event: ICustomer) => {
    fetchCustomer(event.identifier);
    history.push(`/customer/${event.id}`);
    setCustomer(event);
  };

  return (
    <Container>
      <Search>
        <div className="unidade">
          <SelectAutoComplete />
        </div>
        <div className="btncontent">
          <div>
            <h4>Buscar Cliente</h4>
          </div>
          <div className="input">
            <Input
              size="small"
              name="identifier"
              label="CPF"
              onChange={handleSearch}
              mask="999.999.999.99"
              value={identifier}
            />
          </div>
        </div>
      </Search>
      {!loading && identifier.length < 14 && customers.length === 0 && (
        <CustomerSearch />
      )}

      {loading && <Loading marginTop={50} />}

      {!loading && identifier.length > 13 && customers.length === 0 && (
        <CustomerNotFound />
      )}

      {customers.length > 0 && (
        <ContainerTab>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>Cliente</td>
                <td>CPF</td>
                <td>E-mail</td>
                <td>Unidade</td>
                <td>Assinaturas</td>
                <td>Status</td>
                <td>Ação</td>
              </tr>
            </thead>
            <tbody>
              {customers.map((item: ICustomer) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{customMask(item.identifier, '999.999.999-99')}</td>
                  <td>{item.email}</td>
                  <td>{item.unitEmail}</td>
                  <td style={{ fontWeight: 'bold' }}>
                    <span style={{ color: '#58a82d' }}>2</span> /<span>10</span>
                  </td>
                  <td>
                    {item.status === 'paid' && (
                      <BtnStatus color="paid">
                        {t(`pages.signatures.revenue.${item.status}`)}
                      </BtnStatus>
                    )}
                    {item.status === 'scheduled' && (
                      <BtnStatus color="scheduled">
                        {t(`pages.signatures.revenue.${item.status}`)}
                      </BtnStatus>
                    )}
                  </td>
                  <td align="center">
                    <Tooltip
                      title="Ver mais"
                      className="see-more-icon"
                      onClick={() => handleShow(item)}
                    >
                      <AiOutlineEye size={20} />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerTab>
      )}
    </Container>
  );
};

export default Customer;
