import React from 'react';

import { useContract } from 'hooks/contracts';
import ContractNotFound from 'components/Lottie/ContractNotFound';
import LoadingCustomer from 'components/Lottie/LoadingCustomer';

const CustomerContract: React.FC = () => {
  const { loading } = useContract();

  return (
    <>
      {loading ? (
        <LoadingCustomer />
      ) : (
        <ContractNotFound menseger="Não encontrado" />
      )}
    </>
  );
};

export default CustomerContract;
