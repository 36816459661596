import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { queryURI } from 'queryparamsuri';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import {
  Container,
  Pagination,
  PaginationButton,
  PaginationItem,
  PaginationReticence,
  PaginationChange,
} from './styles';

type IPagination = {
  total: number;
  itensPerPage: number;
  setPage: any;
  page: number;
};

export const PaginationComponent: React.FC<IPagination> = ({
  total,
  itensPerPage,
  setPage,
  page,
}) => {
  const [pages, setPages] = useState<number[]>([]);

  const history = useHistory();

  useEffect(() => {
    const totalPages = Math.ceil(total / itensPerPage);
    const arrayPages = [];
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i);
    }
    setPages(arrayPages);

    const pageString = page.toString();

    const params = queryURI({
      name: 'page',
      value: pageString,
    });

    history.push(params);
  }, [total, setPage, page, itensPerPage]); // eslint-disable-line

  return (
    <Container>
      <Pagination>
        <PaginationButton>
          <PaginationChange
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            <KeyboardArrowLeftIcon />
          </PaginationChange>

          {page > 2 && (
            <>
              <PaginationItem onClick={() => setPage(1)}>{1}</PaginationItem>
              {page > 2 && (
                <>
                  <PaginationReticence>...</PaginationReticence>
                </>
              )}
            </>
          )}

          {page > 1 && (
            <PaginationItem onClick={() => setPage(page - 1)}>
              {page - 1}
            </PaginationItem>
          )}

          <PaginationItem isSelect>{page}</PaginationItem>

          {page < pages.length && (
            <>
              <PaginationItem onClick={() => setPage(page + 1)}>
                {page + 1}
              </PaginationItem>
              {page < pages.length - 2 && (
                <>
                  <PaginationReticence>...</PaginationReticence>
                  <PaginationItem onClick={() => setPage(pages.length)}>
                    {pages.length}
                  </PaginationItem>
                </>
              )}
            </>
          )}

          <PaginationChange
            disabled={page === pages.length}
            onClick={() => setPage(page + 1)}
          >
            <KeyboardArrowRightIcon />
          </PaginationChange>
        </PaginationButton>
      </Pagination>
    </Container>
  );
};

export default PaginationComponent;
