import React from 'react';
import Lottie from 'lottie-react-web';
import animation from './json.json';

import { Container } from './styles';

interface IAlert {
  children?: any;
  menseger?: string;
}

const AlertSuccess: React.FC<IAlert> = ({ menseger }) => {
  return (
    <Container>
      <div className="lottie">
        <Lottie options={{ animationData: animation }} />
      </div>
      <h4>{menseger}</h4>
    </Container>
  );
};

export default AlertSuccess;
