import React, { useState, useEffect } from 'react';

import { MdEdit } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';

import { useAuth } from 'hooks/auth';
import { usePlans } from 'hooks/Plans';
import { IPlans } from 'hooks/Plans/types';

import Button from 'components/Button';
import ButtonStatus from 'components/Button/btnStatus';
import Modal from 'components/Modal';
import Loading from 'components/Loading';

import { valueToCurrency } from 'utils/mask';
import CreateOrEdit from './form/createOrEdit';

import { Container, Content, Search } from './styles';

type IHandleModal = {
  plan?: IPlans;
  type?: 'create' | 'edit';
};

const PlansInfo: React.FC = () => {
  const { user } = useAuth();
  const { plans, fetchPlans, submit, update, loading } = usePlans();

  const [modalStatus, setModalStatus] = useState(false);
  const [editOrCreate, setEditOrCreate] = useState<any>(null);
  const [plan, setPlan] = useState<IPlans>({} as IPlans);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const handleSubmitOrEdit = async ({ values, type }: any) => {
    if (type === 'edit') {
      await update({ data: values, id: plan.id });
      setModalStatus(false);
      setPlan({} as IPlans);
      return;
    }

    await submit(values);
    setModalStatus(false);
    setPlan({} as IPlans);
  };

  const handleModal = async ({ type, plan }: IHandleModal) => {
    setEditOrCreate(type);
    setModalStatus(!modalStatus);

    if (plan) {
      setPlan(plan);
    }
  };

  const handleClose = () => {
    setModalStatus(!modalStatus);
    setPlan({} as IPlans);
  };

  return (
    <>
      <Container>
        <Search>
          <div className="unidade">
            <strong>Planos:</strong> Todos
          </div>
          {user.roles?.includes('ROLE_ADMIN') && (
            <div className="btncontent">
              <div style={{ marginLeft: 10 }}>
                <Button onClick={() => handleModal({ type: 'create' })}>
                  Criar novo plano
                </Button>
              </div>
            </div>
          )}
        </Search>
        {loading ? (
          <Loading marginTop={50} />
        ) : (
          <Content>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Plano</td>
                  <td>1º Atendimento </td>
                  <td>Desconto</td>
                  <td>Valor</td>
                </tr>
              </thead>
              <tbody>
                {plans.map(item => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <strong>{item.name}</strong>
                    </td>
                    {item.isFirstAttendance ? (
                      <td className="green-price">1º Utilização cortesia</td>
                    ) : (
                      <td> </td>
                    )}
                    <td>25%</td>
                    <td>
                      de {valueToCurrency(item.original_price)} por{' '}
                      <span className="green-price">
                        {valueToCurrency(item.price)}
                      </span>
                    </td>
                    {user.roles.includes('ROLE_ADMIN') && (
                      <>
                        <td>
                          {item.status ? (
                            <ButtonStatus color="active">Ativo</ButtonStatus>
                          ) : (
                            <ButtonStatus color="danger">
                              Desativado
                            </ButtonStatus>
                          )}
                        </td>
                        <td align="right">
                          <div className="btn-action">
                            <Tooltip
                              title="Editar Plano"
                              aria-label="Editar Plano"
                            >
                              <button
                                type="button"
                                className="icon-btn"
                                onClick={() =>
                                  handleModal({ plan: item, type: 'edit' })
                                }
                              >
                                <MdEdit size={22} />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Content>
        )}
      </Container>

      {modalStatus && (
        <Modal status={modalStatus}>
          <CreateOrEdit
            handleClose={handleClose}
            handleSubmitOrEdit={handleSubmitOrEdit}
            plan={plan}
            type={editOrCreate}
          />
        </Modal>
      )}
    </>
  );
};

export default PlansInfo;
