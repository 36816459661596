import React, { createContext, useCallback, useState, useContext } from 'react';

import api from 'services/api';
import { useUnitSelected } from 'hooks/unitSelected';
import { DateRange } from '@material-ui/lab/DateRangePicker';
import { startOfMonth, endOfToday, format } from 'date-fns';
import { IReport } from './types';

export interface ReportState {
  token: string;
  logged: boolean;
}

export interface ReportContextData {
  loading: boolean;
  reports: IReport;
  value: any;
  setValue: any;
  setReports: any;
  fetchReports(): Promise<void>;
}

const ReportContext = createContext<ReportContextData>({} as ReportContextData);

const ReportProvider: React.FC = ({ children }) => {
  const startMonth = startOfMonth(new Date());
  const endToday = endOfToday();

  const { unitSelected } = useUnitSelected();
  const [value, setValue] = React.useState<any>([startMonth, endToday]);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<IReport>({
    subscriptionActive: 0,
    subscriptionDelayed: 0,
    subscriptionCanceled: 0,
    totalSubscriptions: 0,
  } as IReport);

  const fetchReports = useCallback(async () => {
    const initial_date =
      value[0] === null
        ? format(startMonth, 'yyyy-MM-dd')
        : format(value[0], 'yyyy-MM-dd');
    const final_date =
      value[1] === null
        ? format(endToday, 'yyyy-MM-dd')
        : format(value[1], 'yyyy-MM-dd');

    try {
      setLoading(true);
      const { data } = await api.get(`dashboards/subscriptions`, {
        params: {
          unitSelected,
          initial_date,
          final_date,
        },
      });

      const report: IReport = data.data;

      setReports(report);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [unitSelected, value]);

  return (
    <ReportContext.Provider
      value={{
        loading,
        reports,
        fetchReports,
        setReports,
        value,
        setValue,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

function useReport(): ReportContextData {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error(' useReport must be used within an ReportProvider ');
  }
  return context;
}
export { ReportProvider, useReport };
