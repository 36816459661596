import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .buttons-area {
    display: flex;
    button + button {
      margin-left: 8px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .divider {
    margin: 15px 0;
  }

  .modal-print-contract {
    position: absolute;
    width: 1200px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .check-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-print {
      width: 30%;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .btncontent {
    display: flex;
    align-items: center;
  }

  .input {
    width: 250px;
    margin-left: 10px;
  }
`;

export const ContainerTab = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  .td-actions {
    width: 130px;
    span + span {
      margin-left: 15px;
    }
  }

  .see-more-icon {
    cursor: pointer;
    :hover {
      color: #39a2db;
    }
  }

  .customer-not-found {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #585656;

    span {
      margin-right: 10px;
      color: #585656;
      font-family: 'Roboto', sans-serif;
    }

    .select {
      select {
        border-radius: 3px;
        border: 1px solid #ccc;
        padding: 5px 8px;
        color: #585656;
        font-family: 'Roboto', sans-serif;
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 15px;
`;

export const FormTermoData = styled.div`
  margin: 15px 0;
`;

type IGridProps = {
  grid: number;
};

export const Grid = styled.div<IGridProps>`
  display: grid;
  gap: 12px;

  ${props =>
    props.grid === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.grid === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

    ${props =>
    props.grid === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `}

    & + div {
    margin-top: 12px;
  }
`;

export const ResendTerm = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;

  .form-email,
  .form-sms {
    display: flex;
    flex-direction: column;
  }

  .form-email {
    margin-right: 30px;
  }
`;

export const AceptPhysical = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 15px;

  form {
    .print-observation {
      margin-bottom: 20px;
    }
  }

  .div-menseger {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .div-validation {
    display: flex;
    flex-direction: column;

    .validation-area {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .btn-acept {
        max-height: 40px;
      }
    }

    .input-validation {
      margin-right: 10px;
      width: 100%;

      input {
        height: 8px;
      }
    }
  }

  .input-menseger {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const ContainerPrint = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .btn {
      button {
        width: 150px;
        height: 40px;
      }
    }

    div + div {
      margin-left: 10px;
    }
  }

  .signature-customer {
    display: flex;
    width: 500px;
    margin: 60px auto;
    justify-content: center;
    border-top: 1px solid #999;
    padding-top: 10px;
  }
`;

export const PageToPrint = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);

  .contract {
    text-align: justify;
  }
`;
