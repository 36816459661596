import React from 'react';

import { Span } from './styles';

type IPropsStatus = {
  color?:
    | 'fraud_review'
    | 'canceled'
    | 'denied'
    | 'scheduled'
    | 'pending'
    | 'processing'
    | 'paid'
    | 'refund'
    | string;
};

const SpanStatus: React.FC<IPropsStatus> = ({
  children,
  color = 'scheduled',
  ...rest
}) => {
  return (
    <Span {...rest} color={color}>
      {children}
    </Span>
  );
};

export default SpanStatus;
