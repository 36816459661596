import React from 'react';
import Lottie from 'lottie-react-web';
import animation from './json.json';

import { Container } from './styles';

const DataNotFound: React.FC = () => {
  return (
    <Container>
      <div className="lottie">
        <Lottie options={{ animationData: animation }} />
      </div>
      <h5>Dados não encontrado</h5>
    </Container>
  );
};

export default DataNotFound;
