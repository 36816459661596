import React, { useEffect } from 'react';

import { useHistory } from 'react-router';

import Loading from 'components/Loading';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { format, parseISO } from 'date-fns';
import { Divider } from '@material-ui/core';
import { useNotification } from 'hooks/notification';
import { INotifications } from 'hooks/notification/types';

import { useState } from 'react';
import {
  Container,
  Overlay,
  Header,
  NotificationList,
  NotificationItem,
  NotificationBody,
  ButtonsIcons,
} from './styles';

const Notifications: React.FC = () => {
  const { setIsVisible, fetchNotifications, notifications, update, loading } =
    useNotification();
  const [load, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, update]);

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const handleSubmitViwer = async (values: INotifications) => {
    setIsVisible(false);
    setLoading(true);
    history.push(`customer/${values.customerId}`);
    await update({ data: values, id: values.id });
    fetchNotifications();
    setLoading(false);
  };

  const handleSubmitCheck = async (values: INotifications) => {
    await update({ data: values, id: values.id });
    setLoading(true);
    fetchNotifications();
    setLoading(false);
  };

  return (
    <Overlay onClick={handleCloseModal}>
      <Container onClick={(e: any) => e.stopPropagation()}>
        <Header>
          <ChevronRightIcon className="close-icon" onClick={handleCloseModal} />
        </Header>
        <Divider />
        {loading ? (
          <Loading marginTop={30} />
        ) : (
          <NotificationList>
            {notifications.length > 0 ? (
              notifications.map(item => (
                <>
                  <NotificationItem key={item.id}>
                    <NotificationBody>
                      <div className="header-info">
                        <span className="type">{item.type} </span>
                        <span className="date">
                          {format(
                            parseISO(item.createAt),
                            'yyyy-MM-dd HH:mm:ss',
                          )}
                        </span>
                      </div>
                      <div className="menseger">
                        <p> {item.menseger} </p>
                      </div>
                    </NotificationBody>
                    <ButtonsIcons className="buttons">
                      <span>
                        <Tooltip
                          title=""
                          onClick={() => handleSubmitCheck(item)}
                        >
                          <CloseIcon />
                        </Tooltip>
                      </span>

                      <span>
                        <Tooltip
                          title=""
                          onClick={() => handleSubmitViwer(item)}
                        >
                          <VisibilityIcon />
                        </Tooltip>
                      </span>
                    </ButtonsIcons>
                  </NotificationItem>

                  <Divider />
                </>
              ))
            ) : (
              <div className="not-notifications">Sem novas notificações</div>
            )}
          </NotificationList>
        )}
      </Container>
    </Overlay>
  );
};

export default Notifications;
