import React from 'react';
import Lottie from 'lottie-react-web';
import animation from './identifierSearch.json';

import { Container } from './styles';

const Process: React.FC = () => {
  return (
    <Container>
      <div className="lottie">
        <Lottie options={{ animationData: animation }} />
      </div>
      <h4>Favor informar o CPF da(o) cliente</h4>
    </Container>
  );
};

export default Process;
