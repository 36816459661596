import { addYears, getYear } from 'date-fns';

export const months: any = [
  { month: '01', name: 'Janeiro' },
  { month: '02', name: 'Fevereiro' },
  { month: '03', name: 'Março' },
  { month: '04', name: 'Abril' },
  { month: '05', name: 'Maio' },
  { month: '06', name: 'Junho' },
  { month: '07', name: 'Julho' },
  { month: '08', name: 'Agosto' },
  { month: '09', name: 'Setembro' },
  { month: '10', name: 'Outubro' },
  { month: '11', name: 'Novembro' },
  { month: '12', name: 'Dezembro' },
];

export const years: any = (year: number) => {
  const addYear = getYear(addYears(new Date(), year));
  const years = [];

  let i = Number(getYear(new Date()));

  for (; i < addYear; i++) {
    years.push(i);
  }

  return years;
};
