import React from 'react';

import { useAuth } from '../../hooks/auth';

import BaseLayout from './baseLayout';
import PageLayout from './pageLayout';

const Layout: React.FC = ({ children }) => {
  const { logged } = useAuth();

  if (logged) {
    return <PageLayout>{children}</PageLayout>;
  }
  return <BaseLayout>{children}</BaseLayout>;
};

export default Layout;
