import React, { useState, useEffect } from 'react';

import { AiOutlineEye } from 'react-icons/ai';
import DescriptionIcon from '@material-ui/icons/Description';
import Replay from '@material-ui/icons/Replay';
import { Tooltip } from '@material-ui/core';

import { t } from 'ab18n';
import { useContract } from 'hooks/contracts';
import { IContract } from 'hooks/contracts/types';
import { cpfMask } from 'utils/mask';
import { capitalize } from 'utils/capitalize';
import { format, parseISO } from 'date-fns';

import DataNotFound from 'components/Lottie/DataNotFound';
import Input from 'components/Input';
import BtnStatus from 'components/Button/btnStatus';
import SelectAutoComplete from 'components/SelectAutoComplete';
import Loading from 'components/Loading';
import Modal from 'components/Modal';

import ModalPrintContract from 'components/modalPrintContract';
import Pagination from 'components/Pagination';
import ModalContract from './modalContract';

import { Container, ContainerTab, Search } from './styles';

const Contract: React.FC = () => {
  const { fetchContract, contracts, loading } = useContract();
  const [isVisible, setIsVisible] = useState(false);
  const [contract, setContract] = useState<IContract>({} as IContract);
  const [viewerContract, setViewerContract] = useState(false);
  const [hash, setHash] = useState('');
  const [filtered, setFiltered] = useState('');
  const [itensPerPage, setItensPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const page = Math.ceil(contracts.length / itensPerPage);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;

  useEffect(() => {
    fetchContract('');
  }, [fetchContract]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filtered]);

  function searchInput(contracts: IContract[]) {
    return contracts.filter(
      contract =>
        contract.customer.identifier
          .toLowerCase()
          .indexOf(filtered.toLowerCase()) > -1 ||
        contract.customer.name.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1,
    );
  }

  const handleShow = (contract: IContract) => {
    setIsVisible(true);
    if (contract) {
      setContract(contract);
    }
  };

  const handleContractPrint = async (hash: string) => {
    setHash(hash);
    setViewerContract(true);
  };

  return (
    <>
      <Container>
        <Search>
          <div className="unidade">
            <SelectAutoComplete />
          </div>
          <div className="btncontent">
            <div>
              <h4>Buscar Cliente</h4>
            </div>
            <div className="input">
              <Input
                size="small"
                name="filtered"
                label="Nome ou CPF"
                type="text"
                value={filtered}
                onChange={(e: any) => setFiltered(e.target.value)}
              />
            </div>
          </div>
        </Search>

        {loading ? (
          <Loading marginTop={50} />
        ) : (
          <ContainerTab>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Cliente</td>
                  <td>CPF</td>
                  <td>Termo</td>
                  <td>Status</td>
                  <td>Data do envio</td>
                  <td>Data do aceite</td>
                  <td className="actions">Ações</td>
                </tr>
              </thead>
              <tbody>
                {contracts.length > 0 &&
                  searchInput(contracts)
                    .slice(startIndex, endIndex)
                    .map(item => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{capitalize(item.customer.name)}</td>
                        <td>{cpfMask(item.customer.identifier)}</td>

                        {item.agreeMethod === null ? (
                          <td>Envio digital</td>
                        ) : (
                          <td>
                            {item.agreeMethod.id === 0 ? (
                              <span>Impresso na loja</span>
                            ) : (
                              <span>Envio digital</span>
                            )}
                          </td>
                        )}

                        <td style={{ width: '140px' }}>
                          {item.status.id === 0 && (
                            <BtnStatus style={{ width: '100px' }}>
                              {t(
                                `pages.contract.status.${item.status.description}`,
                              )}
                            </BtnStatus>
                          )}
                          {item.status.id === 1 && ( // para 1 - aguardando o back subir
                            <BtnStatus
                              color="success"
                              style={{ width: '100px' }}
                            >
                              {t(
                                `pages.contract.status.${item.status.description}`,
                              )}
                            </BtnStatus>
                          )}
                        </td>
                        <td>
                          {item.createdAt
                            ? format(
                                parseISO(item.createdAt),
                                'dd-MM-yyyy HH:mm:ss',
                              )
                            : ''}
                        </td>
                        <td>
                          {item.acceptAt
                            ? format(
                                parseISO(item.acceptAt),
                                'dd-MM-yyyy HH:mm:ss',
                              )
                            : ''}
                        </td>
                        <td className="td-actions">
                          <span className="see-more-icon">
                            <Tooltip
                              title="Atualizar"
                              onClick={() => window.location.reload()}
                            >
                              <Replay />
                            </Tooltip>
                          </span>
                          <span className="see-more-icon">
                            <Tooltip
                              title="Ver mais"
                              onClick={() => handleShow(item)}
                            >
                              <span>
                                <AiOutlineEye size={20} />
                              </span>
                            </Tooltip>
                          </span>
                          {item.acceptAt !== null && (
                            <span>
                              <Tooltip
                                title="contrato"
                                className="see-more-icon"
                                onClick={() => handleContractPrint(item.hash)}
                              >
                                <DescriptionIcon />
                              </Tooltip>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {searchInput(contracts).length === 0 && (
              <div className="customer-not-found">
                <DataNotFound />
              </div>
            )}
            <div className="pagination">
              <Pagination
                total={contracts.length}
                page={currentPage}
                setPage={setCurrentPage}
                itensPerPage={itensPerPage}
              />
            </div>
          </ContainerTab>
        )}
        {isVisible && (
          <Modal
            status={isVisible}
            handleClose={() => setIsVisible(!isVisible)}
          >
            <ModalContract setIsVisible={setIsVisible} contract={contract} />
          </Modal>
        )}

        {viewerContract && (
          <Modal
            width="1200px"
            status={viewerContract}
            handleClose={() => setViewerContract(!viewerContract)}
          >
            <ModalPrintContract
              openModal={setViewerContract}
              contractHash={hash}
            />
          </Modal>
        )}
      </Container>
    </>
  );
};

export default Contract;
