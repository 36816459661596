import Button from 'components/Button';
import React from 'react';

import { ContainerModalConfirm, Title } from './styles';

export interface ModalConfirm {
  handleActiveRenew: any;
  handleAnticipateRenew: any;
  setIsVisible: any;
  type: string;
  renewId: number;
  renewStatus: boolean;
}

const ModalConfirm: React.FC<ModalConfirm> = ({
  handleActiveRenew,
  handleAnticipateRenew,
  setIsVisible,
  type,
  renewId,
  renewStatus,
}) => {
  return (
    <ContainerModalConfirm>
      <div className="modal-confirm-body">
        {type === 'renew' ? (
          <Title>{`Deseja mesmo ${
            renewStatus ? 'cancelar' : 'ativar'
          } a renovação automática?`}</Title>
        ) : (
          <Title>Deseja mesmo antecipar a renovação?</Title>
        )}

        <div className="buttons-confirm">
          {type === 'renew' ? (
            <Button color="danger" onClick={() => handleActiveRenew(renewId)}>
              Sim
            </Button>
          ) : (
            <Button
              color="danger"
              onClick={() => handleAnticipateRenew(renewId)}
            >
              Sim
            </Button>
          )}

          <Button color="default" onClick={() => setIsVisible(false)}>
            Não
          </Button>
        </div>
      </div>
    </ContainerModalConfirm>
  );
};

export default ModalConfirm;
