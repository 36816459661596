import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Cards from '../pages/cards';
import Visit from '../pages/visit';
import Fees from '../pages/fees';
import Refund from '../pages/refund';
import Renew from '../pages/renew';
import Detail from '../pages/detail';
import Bill from '../pages/Bills';

const RoutesSignatures: React.FC = () => (
  <Switch>
    <Route path="/signature/detail" exact component={Detail} />
    <Route path="/signature/revenue" exact component={Bill} />
    <Route path="/signature/visits" exact component={Visit} />
    <Route path="/signature/fees" exact component={Fees} />
    <Route path="/signature/refunds" exact component={Refund} />
    <Route path="/signature/cards" exact component={Cards} />
    <Route path="/signature/renews" exact component={Renew} />
  </Switch>
);

export default RoutesSignatures;
