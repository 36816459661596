import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useCustomer } from 'hooks/customer';

import Loading from 'components/Loading';
import SignatureCard from './signatureCard';
import CustomerInfo from './customerInfo';

import { Container, ContainerCard, GridLoad } from './styles';

const Show: React.FC = () => {
  const { id }: any = useParams();
  const { customerSubscription, showCustomer } = useCustomer();
  const [expand, setExpand] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadCustomer() {
      await showCustomer(id);
      setLoading(false);
    }
    loadCustomer();
  }, [id, showCustomer]);

  const mySignatures = customerSubscription
    .filter(item => item.status !== 'finished')
    .filter(item => item.status !== 'canceled')
    .filter(item => item.status !== 'expired')
    .filter(item => item.status !== 'refunded');

  const myHistorical = customerSubscription
    .filter(item => item.status !== 'delayed')
    .filter(item => item.status !== 'success');

  const customer = customerSubscription[0];

  const handleExpand = (event: number) => {
    if (event === expand) {
      setExpand(0);
      return;
    }
    setExpand(event);
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            <CustomerInfo
              key={customer.customer.id}
              name={customer.customer.name}
              identifier={customer.customer.identifier}
              email={customer.customer.email}
            />
          </div>
          <ContainerCard>
            {customerSubscription.length === 0 && loading ? (
              <GridLoad>
                <Loading color="#999" />
              </GridLoad>
            ) : (
              <>
                {customerSubscription.length > 0 && (
                  <>
                    <h3>Minha Assinatura</h3>
                    {mySignatures.map(signature => (
                      <SignatureCard
                        signature={signature}
                        key={signature.id}
                        expand={signature.id === expand}
                        handleExpand={handleExpand}
                      />
                    ))}

                    <h3 style={{ marginTop: 12 }}>Meu histórico</h3>
                    {myHistorical.map(signature => (
                      <SignatureCard
                        signature={signature}
                        key={signature.id}
                        expand={signature.id === expand}
                        handleExpand={handleExpand}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </ContainerCard>
        </>
      )}
    </Container>
  );
};

export default Show;
