import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-control-label {
      margin-top: 10px;
    }
  }

  @media (max-width: 600px) {
    padding: 30px 10px;
  }

  @media (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e2e2e;
  margin-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-height: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);

  .contract {
    text-align: justify;
  }
`;
