import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #ddd;
  justify-content: center;
  align-items: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 40% 40% 20%;
  width: 100%;
  margin-top: -10px;

  .content--tab {
    display: flex;
    flex-direction: column;

    span + span {
      margin-top: 8px;
    }

    span {
      display: flex;
      font-weight: bold;

      p {
        margin-left: 5px;
        font-weight: normal;
      }
    }
  }

  .due-payment {
    display: flex;
    align-items: center;
  }
`;

export const ContentStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .id {
    display: flex;
    margin-bottom: 8px;
  }
`;
