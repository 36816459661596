import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IProps {
  heigth?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 830px;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  .grid {
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .description {
      display: flex;
      flex-direction: column;

      span {
        font-size: 11px;
      }

      .total {
        text-align: right;
        font-size: 36px;
        font-weight: bold;
      }

      .green {
        color: #00b300;
      }

      .red {
        color: #ff4d4d;
      }

      .blue {
        color: #4d94ff;
      }
    }
  }
`;

export const Content = styled.div<IProps>`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;

  .btn-action {
    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }

  ${props =>
    props.heigth &&
    css`
      height: ${`${props.heigth}px`};
    `}
`;

export const ContentCustomer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .btncontent {
    display: flex;
    align-items: center;
  }

  .input {
    width: 250px;
    margin-left: 10px;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
`;
