import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IProps {
  heigth?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 830px;
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;

  .grid {
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .description {
      display: flex;
      flex-direction: column;

      span {
        font-size: 11px;
      }

      .total {
        text-align: right;
        font-size: 36px;
        font-weight: bold;
      }

      .green {
        color: #00b300;
      }

      .warning {
        color: #e2b213;
      }

      .red {
        color: #ff4d4d;
      }

      .blue {
        color: #4d94ff;
      }
    }
  }
`;

export const GridButton = styled.div`
  cursor: pointer;
  transition: all ease 0.2s;

  :hover {
    background-color: #f1f1f1;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const ContentArea = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Content = styled.div<IProps>`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;

  ${props =>
    props.heigth &&
    css`
      height: ${`${props.heigth}px`};
    `}

  .employers {
    tbody {
      td {
        height: 48px;
      }
    }
  }
`;

export const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;

  .select {
    display: flex;
    width: auto;

    .date-range {
      width: 100%;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;

      span {
        padding: 0px 10px;
      }
    }
  }
`;

export const ContainerModal = styled.div``;
