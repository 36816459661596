import React, { useState } from 'react';

import Button from 'components/Button';
import Span from 'components/Span';
import Modal from 'components/Modal';
import DataNotFound from 'components/Lottie/DataNotFound';

import { format, parseISO } from 'date-fns';
import { t } from 'ab18n';

import { useSubscriptions } from 'hooks/subscriptions';
import Loading from 'components/Loading';
import ModalConfirm from './modalConfirm';
import { Container } from './styles';

const Renew: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState('');

  const handleOpenModalConfirm = (value: string) => {
    setType(value);
    setIsVisible(true);
  };
  const {
    subscription,
    updateAnticipate,
    updateRenews,
    loadingModalDetail,
    errorUpdate,
    fetchSubscriptionDetail,
  } = useSubscriptions();

  const handleActiveRenew = async (id: number) => {
    await updateRenews(id);

    fetchSubscriptionDetail(subscription.id);
    setIsVisible(false);
  };

  const handleAnticipateRenew = async (id: number) => {
    await updateAnticipate(id);

    if (errorUpdate === undefined) {
      fetchSubscriptionDetail(subscription.id);
    }
    setIsVisible(false);
  };

  return (
    <Container>
      {loadingModalDetail ? (
        <Loading marginTop={20} />
      ) : (
        subscription.renews !== undefined && (
          <>
            {Object.keys(subscription.renews).length > 0 ? (
              subscription.renews.map(renew => (
                <div className="renew">
                  <table className="table-custom table table-striped table-hover">
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Id Última assinatura</td>
                        <td>Próxima renovação</td>
                        <td>Renovação</td>
                        <td>Status</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={renew.id}>
                        <td>{renew.id}</td>
                        <td>{renew.last_subscription}</td>
                        <td>
                          {renew.scheduled_date === null
                            ? ''
                            : format(
                                parseISO(renew.scheduled_date),
                                'dd-MM-yyyy',
                              )}
                        </td>
                        <td>
                          {renew.is_active ? (
                            <Span color="active">Ativo</Span>
                          ) : (
                            <Span color="disabled">Desativada</Span>
                          )}
                        </td>
                        <td>
                          {renew.status && (
                            <Span color={renew.status}>
                              {t(`pages.subscription.renews.${renew.status}`)}
                            </Span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="help">
                    <p>
                      <strong>**</strong>Para cancelar uma renovação é
                      necessário que a cliente tenha realizado a <strong>terceira parcela</strong>, sendo possível reativar até o dia da
                      renovação, caso chegue o dia e esteja desativado, a
                      renovação é cancelada e não sendo mais possível renovar,
                      nesse caso, teria que criar uma nova.
                    </p>
                    <br />
                    <p>
                      <strong>**</strong>
                      Ao antecipar uma renovação será criada uma nova assinatura com 3 serviços auto-renováveis.
                    </p>
                  </div>

                  <div className="buttons">
                    {subscription.status === 'expired' && (
                      <Button
                        value="anticipate"
                        onClick={() => handleOpenModalConfirm('anticipate')}
                        color="green"
                      >
                        Antecipar renovação
                      </Button>
                    )}
                    {subscription.revenue.length === 3 && (
                      <Button
                        color={renew.is_active ? 'danger' : 'default'}
                        onClick={() => handleOpenModalConfirm('renew')}
                      >
                        {renew.is_active
                          ? 'Desativar renovação'
                          : 'Ativar renovação'}
                      </Button>
                    )}
                  </div>

                  {isVisible && (
                    <Modal
                      width="400px"
                      status={isVisible}
                      handleClose={() => setIsVisible(!isVisible)}
                    >
                      <ModalConfirm
                        handleActiveRenew={handleActiveRenew}
                        handleAnticipateRenew={handleAnticipateRenew}
                        setIsVisible={setIsVisible}
                        type={type}
                        renewId={renew.id}
                        renewStatus={renew.is_active}
                      />
                    </Modal>
                  )}
                </div>
              ))
            ) : (
              <DataNotFound />
            )}
          </>
        )
      )}
    </Container>
  );
};

export default Renew;
