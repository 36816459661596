import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { MdPersonAdd, MdRemoveRedEye, MdEventNote } from 'react-icons/md';

import { format, parseISO } from 'date-fns';

import { valueToCurrency } from 'utils/mask';

import { useSubscriptions } from 'hooks/subscriptions';
import { ISubscriptions } from 'hooks/subscriptions/types';
import { useCustomer } from 'hooks/customer';
import { useReport } from 'hooks/reports';
import { useUnitSelected } from 'hooks/unitSelected';

import LoadingRotation from 'components/LoadingRotation';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import ChartLine from 'components/Charts/line';
import DateRange from 'components/Date/range';
import SelectAutoComplete from 'components/SelectAutoComplete';

import ModalDashboard from './modalDashboar';
import {
  Container,
  Grid,
  Content,
  ContentArea,
  Search,
  Title,
  GridButton,
} from './styles';

const Home: React.FC = () => {
  const {
    fetchSubscriptions,
    subscriptions,
    loading: load,
  } = useSubscriptions();
  const { fetchCustomer } = useCustomer();
  const { fetchReports, reports, loading } = useReport();
  const { unitSelected } = useUnitSelected();
  const [modalStatus, setIsVisible] = useState(false);
  const [typeStatusSubscription, setTypeStatusSubscription] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    ISubscriptions[]
  >([] as ISubscriptions[]);

  const history = useHistory();

  useEffect(() => {
    fetchReports();
    fetchSubscriptions({ data: '' });
  }, [fetchReports, fetchSubscriptions, unitSelected]);

  const handleShow = (event: any) => {
    fetchCustomer(event);
    history.push(`/customer/${event.id}`);
  };

  const subscriptionsActive = subscriptions.filter(
    item => item.status === 'success',
  );

  const handleOpenModalDetails = (item: string) => {
    if (item === 'subscriptionActive' && reports.subscriptionActive !== 0) {
      setSubscriptionStatus(
        subscriptions.filter(item => item.status === 'success'),
      );
      setTypeStatusSubscription('Ativas');
      setIsVisible(true);
    }

    if (item === 'subscriptionDelayed' && reports.subscriptionDelayed !== 0) {
      setSubscriptionStatus(
        subscriptions.filter(item => item.status === 'delayed'),
      );
      setTypeStatusSubscription('Atrasadas');
      setIsVisible(true);
    }

    if (item === 'subscriptionCanceled' && reports.subscriptionCanceled !== 0) {
      setSubscriptionStatus(
        subscriptions.filter(item => item.status === 'canceled'),
      );
      setTypeStatusSubscription('Canceladas');
      setIsVisible(true);
    }

    if (item === 'totalSubscriptions' && reports.totalSubscriptions !== 0) {
      setSubscriptionStatus(subscriptions);
      setTypeStatusSubscription('Todas');
      setIsVisible(true);
    }
  };

  return (
    <Container>
      <Search>
        <div className="unit">
          <div>
            <SelectAutoComplete />
          </div>
        </div>
        <div className="select">
          <div className="date-range">
            <span>Selecione um período:</span>
            <DateRange />
          </div>
        </div>
      </Search>
      <>
        <Grid>
          <GridButton
            className="grid"
            onClick={() => handleOpenModalDetails('subscriptionActive')}
          >
            <span>
              {loading ? <LoadingRotation animation /> : <LoadingRotation />}
            </span>
            <div className="description">
              <span>Assinaturas Ativas</span>
              <div className="total green">{reports.subscriptionActive}</div>
            </div>
          </GridButton>

          <GridButton
            className="grid"
            onClick={() => handleOpenModalDetails('subscriptionDelayed')}
          >
            <span>
              <span>
                {loading ? <LoadingRotation animation /> : <LoadingRotation />}
              </span>
            </span>
            <div className="description">
              <span>Assinaturas Atrasadas</span>
              <div className="total warning">{reports.subscriptionDelayed}</div>
            </div>
          </GridButton>
          <GridButton
            className="grid"
            onClick={() => handleOpenModalDetails('subscriptionCanceled')}
          >
            <span>
              <span>
                {loading ? <LoadingRotation animation /> : <LoadingRotation />}
              </span>
            </span>
            <div className="description">
              <span>Assinaturas Canceladas</span>
              <div className="total red">{reports.subscriptionCanceled}</div>
            </div>
          </GridButton>
          <GridButton
            className="grid"
            onClick={() => handleOpenModalDetails('totalSubscriptions')}
          >
            <span>
              <MdPersonAdd size={38} color="#2e2e2e" />
            </span>
            <div className="description">
              <span>Cadastros</span>
              <div className="total blue">{reports.totalSubscriptions}</div>
            </div>
          </GridButton>
        </Grid>
        <Content heigth={320}>
          <ChartLine data={reports.periodValues} />
        </Content>
      </>

      <ContentArea>
        <Content>
          <Title>Assinaturas por funcionária</Title>
          {load ? (
            <Loading />
          ) : (
            <table className="employers table table-striped table-hover">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Funcionária</td>
                  <td>Quantidade</td>
                  <td>Valor</td>
                </tr>
              </thead>

              <tbody>
                {reports.periodValues?.employers.length > 0 &&
                  reports.periodValues.employers.map(item => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.quantity ? item.quantity : 0}</td>
                      <td>
                        {item.total ? valueToCurrency(Number(item.total)) : 0}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </Content>

        <Content style={{ flex: '1', marginLeft: '12px' }}>
          <Title>Assinaturas Recentes</Title>
          {load ? (
            <Loading />
          ) : (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Cliente</td>
                  <td>Plano</td>
                  <td>Valor</td>
                  <td>Data Vencimento</td>
                  <td>Criado</td>
                  <td>Ação</td>
                </tr>
              </thead>
              {subscriptionsActive.map(item => (
                <tbody>
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.customer.name}</td>
                    <td>{item.plan.name}</td>
                    <td>
                      {item.revenue.map(rev => rev.amount)[0] > 0
                        ? valueToCurrency(
                            item.revenue.map(rev => rev.amount)[0],
                          )
                        : ''}
                    </td>
                    <td>
                      <span className="icon-table">
                        <MdEventNote /> {item.dueDate}
                      </span>
                    </td>
                    <td>
                      <span className="icon-table">
                        <MdEventNote />{' '}
                        {format(parseISO(item.createdAt), 'dd-MM-yyyy')}
                      </span>
                    </td>
                    <td align="center">
                      <button
                        type="button"
                        className="icon-btn"
                        onClick={() => handleShow(item.customer)}
                      >
                        <MdRemoveRedEye size={22} />
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}
        </Content>
      </ContentArea>
      {modalStatus && (
        <Modal status={modalStatus}>
          <ModalDashboard
            setIsVisible={setIsVisible}
            subscriptionStatus={subscriptionStatus}
            typeStatusSubscription={typeStatusSubscription}
          />
        </Modal>
      )}
    </Container>
  );
};

export default Home;
