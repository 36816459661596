import React from 'react';

import { Formik } from 'formik';

import { useDropzone } from 'react-dropzone';

import Input from 'components/Input';
import MultipleSelect from 'components/Select/multiple';
import Button from 'components/Button';
import Switch from 'components/Switch';
import Loading from 'components/Loading';

import { usePlans } from 'hooks/Plans';
import { IPlans } from 'hooks/Plans/types';

import { createOrEditSchema } from 'validations';

import { Container, Content, Grid, ContainerButton } from './styles';

interface ICreateOrEdit {
  handleClose: any;
  type: 'create' | 'edit';
  plan: IPlans;
  handleSubmitOrEdit: any;
}

const CreateOrEdit: React.FC<ICreateOrEdit> = ({
  handleClose,
  type,
  plan,
  handleSubmitOrEdit,
}) => {
  const { loadingSubmitOrUpdate } = usePlans();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
  });

  const file = acceptedFiles[0]
    ? Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    : null;

  const services = plan.services?.map((item: any) => item.name);
  return (
    <>
      {loadingSubmitOrUpdate ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            name: plan.name,
            timeLimit: String(plan.timeLimit),
            description: plan.description,
            services: services || [],
            discount: String(plan.discount),
            isRecommended: plan.isRecommended,
            status: plan.status,
            isFirstAttendance: plan.isFirstAttendance,
          }}
          validationSchema={createOrEditSchema}
          onSubmit={values => handleSubmitOrEdit({ values, type })}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <Container>
                {type === 'create' ? (
                  <h3>Criar novo plano</h3>
                ) : (
                  <h3>Editar Plano: {plan.name}</h3>
                )}
                <Content>
                  <div className="grid">
                    <Grid grid={1}>
                      <Input
                        name="name"
                        label="Nome"
                        onChange={props.handleChange}
                        value={props.values.name}
                        error={props.errors.name}
                        size="small"
                      />
                    </Grid>
                    <Grid grid={2}>
                      <Input
                        type="number"
                        name="timeLimit"
                        label="Quantidade"
                        onChange={props.handleChange}
                        value={props.values.timeLimit}
                        error={props.errors.timeLimit}
                        disabled={type === 'edit'}
                        size="small"
                      />
                      <Input
                        type="number"
                        name="discount"
                        label="Desconto"
                        onChange={props.handleChange}
                        value={props.values.discount}
                        error={props.errors.discount}
                        disabled={type === 'edit'}
                        size="small"
                      />
                    </Grid>
                    <Grid grid={1}>
                      <Input
                        name="description"
                        label="Descrição"
                        placeholder="Ex.: Plano de 6 meses 20%"
                        onChange={props.handleChange}
                        value={props.values.description}
                        error={props.errors.description}
                        size="small"
                      />
                    </Grid>
                    <Grid grid={1}>
                      <MultipleSelect
                        name="services[]"
                        title="Serviços"
                        values={props.values.services}
                        error={props.errors.services}
                        disabled={type === 'edit'}
                        data={['Sobrancelhas Design', 'Epilação']}
                        handleChange={(event: any) =>
                          props.setFieldValue('services', event.target.value)
                        }
                      />
                    </Grid>
                    <Grid grid={1}>
                      <section className="container-dropzone-img-one">
                        <ul className="img-dropzone">
                          {file ? (
                            <li>
                              <img src={file.preview} alt={file.name} />
                            </li>
                          ) : (
                            <li>Preview</li>
                          )}
                        </ul>
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>Selecione a imagem do plano.</p>
                        </div>
                      </section>
                    </Grid>
                    <div className="switch">
                      <Switch
                        label="Destaque?"
                        name="isRecommended"
                        value={props.values.isRecommended}
                        handleChange={(event: any) =>
                          props.setFieldValue(
                            'isRecommended',
                            event.target.checked,
                          )
                        }
                      />
                      {type === 'edit' && (
                        <Switch
                          label="Primeiro atendimento"
                          name="isFirstAttendance"
                          value={props.values.isFirstAttendance}
                          handleChange={(event: any) =>
                            props.setFieldValue(
                              'isFirstAttendance',
                              event.target.checked,
                            )
                          }
                        />
                      )}
                      {type === 'edit' && (
                        <Switch
                          label="Ativar ou Desativar Plano"
                          name="status"
                          value={props.values.status}
                          handleChange={(event: any) =>
                            props.setFieldValue('status', event.target.checked)
                          }
                        />
                      )}
                    </div>
                  </div>
                </Content>
              </Container>

              <ContainerButton>
                <Button type="button" color="danger" onClick={handleClose}>
                  Cancelar
                </Button>
                {type === 'create' ? (
                  <Button type="submit" onClick={() => props.validateForm()}>
                    Adicionar
                  </Button>
                ) : (
                  <Button type="submit" onClick={() => props.validateForm()}>
                    Atualizar
                  </Button>
                )}
              </ContainerButton>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default CreateOrEdit;
