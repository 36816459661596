import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;

  .logo {
    width: 80px;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex: 1;

  ul {
    display: flex;
    flex: 1;
    list-style: none;

    li {
      padding-left: 4px;
      padding-right: 4px;

      & + li {
        border-left: 1px solid #eee;
      }

      a {
        display: flex;
        align-items: center;
        padding: 14px 6px;
        transition: color 0.2s;

        svg {
          margin-right: 4px;
        }

        &:hover {
          color: #9ea58a;
        }
      }

      button {
        display: flex;
        align-items: center;
        padding: 14px 6px;
        transition: color 0.2s;
        border: 0px;
        background: none;
        color: #ff4d4d;

        svg {
          margin-right: 4px;
        }

        &:hover {
          color: #9ea58a;
        }
      }

      .active {
        color: #9ea58a;
      }
    }
  }
`;
