import React, { createContext, useCallback, useState, useContext } from 'react';

import jwtDecode from 'jwt-decode';
import api from '../services/api';

export type IUser = {
  id: number;
  email: string;
  username: string;
  isAdmin: string;
  roles: any[];
};

export interface AuthState {
  token: string;
  logged: boolean;
}

export interface AuthContextData {
  user: IUser;
  loading: boolean;
  logged: boolean;
  signIn(value: string): Promise<void>;
  signInAdmin(email: string): void
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ClubSdAdmin:token');

    if (token) {
      return { token, logged: true };
    }
    return { token: '', logged: false } as AuthState;
  });
  const [user, setUser] = useState<IUser>(() => {
    const token: any = localStorage.getItem('@ClubSdAdmin:token');

    if (token) {
      return jwtDecode(token);
    }

    return {} as IUser;
  });

  const signIn = useCallback(async event => {
    const { tokenId } = event;

    try {
      setLoading(true);
      const { data } = await api.post('login/google', {
        idToken: tokenId,
      });

      const token = data.jwt;

      localStorage.setItem('@ClubSdAdmin:token', token);

      setData({ token, logged: true } as AuthState);
      setUser(jwtDecode(token));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ClubSdAdmin:token');
    localStorage.removeItem('@ClubSdAdmin:unitSelected');

    setData({ token: '', logged: false } as AuthState);
  }, []);

  const signInAdmin = useCallback(async (email: string) => {
    try {
      setLoading(true);
      const { data } = await api.post('login/redirect', {
        unit: email,
      });

      const token = data.jwt;

      localStorage.setItem('@ClubSdAdmin:token', token);

      setData({ token, logged: true } as AuthState);
      setUser(jwtDecode(token));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loading,
        logged: data.logged,
        user,
        signIn,
        signOut,
        signInAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(' useAuth must be used within an authProvider ');
  }
  return context;
}
export { AuthProvider, useAuth };
