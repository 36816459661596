import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/auth';

import { Container, MenuContent } from './styles';

const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const { signOut, user } = useAuth();
  const isSdUnit = !user?.email?.endsWith('@gsd.com.br')

  return (
    <Container>
      <MenuContent>
        <ul>
          {/* <li>
            <Link
              to="/dashboard"
              className={pathname === '/dashboard' ? 'active' : ''}
            >
              Dashboard
            </Link>
          </li> */}
          {isSdUnit && (
            <>
              <li>
                <Link
                  to="/signatures/create"
                  className={pathname === '/signatures/create' ? 'active' : ''}
                >
                  Nova Assinatura
                </Link>
              </li>
              <li>
                <Link
                  to="/signatures"
                  className={pathname === '/signatures' ? 'active' : ''}
                >
                  Assinaturas
                </Link>
              </li>

              <li>
                <Link
                  to="/contracts"
                  className={pathname === '/contracts' ? 'active' : ''}
                >
                  Contratos
                </Link>
              </li>
              {/* <li>
            <Link
              to="/revenues"
              className={pathname === '/revenues' ? 'active' : ''}
            >
              Pagamentos
            </Link>
          </li> */}
              {/* {user.roles?.includes('ROLE_ADMIN') && (
            <li>
              <Link
                to="/users"
                className={pathname === '/users' ? 'active' : ''}
              >
                Usuários
              </Link>
            </li>
          )} */}
              <li>
                <Link to="/plans" className={pathname === '/plans' ? 'active' : ''}>
                  Planos
                </Link>
              </li>
            </>
          )}
          <li>
            <button type="button" onClick={() => signOut()}>
              Sair
            </button>
          </li>
        </ul>
      </MenuContent>
    </Container>
  );
};

export default Navigation;
