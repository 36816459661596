import React from 'react';

import {useAuth} from "../hooks/auth";
import Auth from "./Auth";
import App from "./App";
import Franchise from "./Franchise";

const Routes: React.FC = () => {
  const { logged, user } = useAuth();
  if (!logged) return <Auth />;
  if (user.isAdmin && user.email.endsWith('@gsd.com.br'))
    return <Franchise />;
  return <App />;
}

export default Routes;
