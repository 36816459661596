import React from 'react';

import { useTheme, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { Container, Error } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface IMultipleSelect {
  name: string;
  title: string;
  handleChange: any;
  values: string[];
  data: string[];
  error?: any;
  disabled?: boolean;
}

const MultipleSelect: React.FC<IMultipleSelect> = ({
  name,
  title,
  handleChange,
  values,
  data,
  error,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <FormControl>
      <InputLabel>{title}</InputLabel>
      <Select
        name={name}
        multiple
        value={values}
        error={!!error}
        onChange={handleChange}
        disabled={disabled}
        input={<Input name={name} />}
        renderValue={selected => (
          <Container>
            {(selected as string[]).map(value => (
              <Chip key={value} label={value} />
            ))}
          </Container>
        )}
        MenuProps={MenuProps}
      >
        {data.map(name => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, values, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {error && <Error>{error}</Error>}
    </FormControl>
  );
};

export default MultipleSelect;
