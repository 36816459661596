import React from 'react';

import { Line } from 'react-chartjs-2';
import { IPeriodValues } from 'hooks/reports/types';

import pt from 'date-fns/locale/pt';
import { setMonth, format } from 'date-fns';

interface ILineContainer {
  data: IPeriodValues;
}

const LineContainer: React.FC<ILineContainer> = ({ data }) => {
  let monthFormatted: any[] = [];

  if (data) {
    monthFormatted = data.data.map(d => {
      const month = format(setMonth(new Date(), d.month), 'MMMM', {
        locale: pt,
      });
      const object = `${month} - ${d.year}`;
      return object;
    });
  }

  const state = {
    labels: monthFormatted,
    datasets: [
      {
        label: 'Assinaturas Ativos R$',
        data: data ? data.subscriptionActive : [],
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#15c48f',
        borderColor: '#15c48f',
        borderWidth: 3,
      },
      {
        label: 'Assinaturas Atrasadas R$',
        data: data ? data.subscriptionDelayed : [],
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#e2b213',
        borderColor: '#e2b213',
        borderWidth: 3,
      },
      {
        label: 'Assinaturas Canceladas R$',
        data: data ? data.subscriptionCanceled : [],
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#f33b72',
        borderColor: '#f33b72',
        borderWidth: 3,
      },
    ],
  };

  return (
    <Line
      data={state}
      options={{
        responsive: true,
        animation: {
          duration: 800, // general animation time
          easing: 'easeInQuad',
        },
        maintainAspectRatio: false,
        scales: {},
      }}
    />
  );
};

export default LineContainer;
