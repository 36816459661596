import React from 'react';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { ICards } from 'hooks/cards/types';
import { Container, ButtonIcon, AddNewCard } from './styles';

type IFetch = {
  cards: ICards[];
  handleAddNewCard: () => void;
};

const Purchase: React.FC<IFetch> = ({ cards, handleAddNewCard }) => {
  return (
    <Container>
      <AddNewCard>
        <div className="add-card">
          <div className="dotted">
            <ButtonIcon className="icon" onClick={handleAddNewCard}>
              <AddCircleOutlineOutlinedIcon />
            </ButtonIcon>
            <p>Adicionar um novo cartão</p>
            {cards?.length === 2 && (
              <p>
                Existem 2 cartões cadastrados, favor remover um antes de
                cadastrar um novo
              </p>
            )}
          </div>
        </div>
      </AddNewCard>
    </Container>
  );
};

export default Purchase;
