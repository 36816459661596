import React, { createContext, useState, useContext } from 'react';
import { useCallback } from 'react';

import api from 'services/api';
import { ISubscriptions } from 'hooks/subscriptions/types';
import { useToasts } from 'react-toast-notifications';
import { ICustomer } from './types';

export interface CustomerContextData {
  loading: boolean;
  customers: ICustomer[];
  customer: ICustomer;
  customerSubscription: ISubscriptions[];
  setCustomer: any;
  setCustomers: any;
  showCustomer(id: number): Promise<void>;
  fetchCustomer(identifier: string): Promise<void>;
  fetchCustomers(identifier: string): Promise<void>;
}

const CustomerContext = createContext<CustomerContextData>(
  {} as CustomerContextData,
);

const CustomerProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>([] as ICustomer[]);
  const [customer, setCustomer] = useState<ICustomer>({} as ICustomer);
  const [customerSubscription, setCustomerSubscription] = useState<
    ISubscriptions[]
  >([] as ISubscriptions[]);

  const { addToast } = useToasts();

  const fetchCustomer = useCallback(
    async value => {
      const identifier = value;
      try {
        setLoading(true);
        const response = await api.get(`customers/${identifier}`);
        setCustomer(response.data);
      } catch (err: any) {
        const { data } = err.response;

        if (data.title === 'Unprocessable Entity') {
          addToast(`${data.errors.city}`, {
            appearance: 'error',
            autoDismiss: true,
          });
          addToast(`${data.errors.state}`, {
            appearance: 'error',
            autoDismiss: true,
          });
          addToast(`${data.errors.country}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('customers/');
      setCustomers(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const showCustomer = useCallback(async id => {
    try {
      setLoading(true);
      const res = await api.get(`customers/${id}/subscriptions`);
      setCustomerSubscription(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        fetchCustomer,
        fetchCustomers,
        loading,
        customers,
        customer,
        customerSubscription,
        setCustomer,
        setCustomers,
        showCustomer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

function useCustomer(): CustomerContextData {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error(' useCustomer must be used within an customerProvider ');
  }
  return context;
}
export { CustomerProvider, useCustomer };
