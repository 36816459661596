import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IModalProps {
  status: boolean;
  width: string;
}

export const Container = styled.div<IModalProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  justify-content: center;
  align-items: center;

  .zoom {
    width: ${props => `${props.width}`};
    height: auto;
    max-width: ${props => `${props.width}`};
    max-height: 650px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    overflow: auto;
  }

  ${props =>
    props.status
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;

export const Content = styled.div<IModalProps>`
  display: flex;
  width: 100%;
  height: auto;
  max-width: ${props => `${props.width}`};
  max-height: 650px;
  justify-content: center;
  align-items: center;
`;
