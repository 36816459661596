import React from 'react';
import { format, parseISO } from 'date-fns';
import { useSubscriptions } from 'hooks/subscriptions';
import { valueToCurrency } from 'utils/mask';
import { serviceNameAndQuantity } from 'utils/nameAndQuantity';
import Loading from 'components/Loading';
import ButtonStatus from 'components/Button/btnStatus';
import { t } from 'ab18n';
import {
  Container,
  Content,
  Box,
  ContentPlan,
  ContentRevenues,
  Dotted,
  SpanFlexRow,
} from './styles';

const Detail: React.FC = () => {
  const { subscription } = useSubscriptions();
  const incomingBills = subscription?.bills?.filter(
    (bill) => bill.origin === 'subscription' && bill.status ==='paid'
  ) || [];
  const outgoingBills = subscription?.bills?.filter(
    (bill) => bill.origin === 'fee'
  ) || [];

  const id = Number(localStorage.getItem('@ClubSd:subscriptionId'));

  return (
    <Container>
      {subscription.id !== id ? (
        <Loading marginTop={50} />
      ) : (
        Object.keys(subscription).length > 0 && (
          <Content>
            <Box>
              <ContentPlan>
                <div className="flex--column content--detail">
                  <div className="flex--column plan">
                    <h1>Plano</h1>
                    <SpanFlexRow>
                      <strong>Nome:</strong>
                      <Dotted />
                      <span>{subscription.plan.name}</span>
                    </SpanFlexRow>
                    <SpanFlexRow>
                      <strong>Valor original:</strong>
                      <Dotted />
                      <span>
                        {' '}
                        {valueToCurrency(subscription.plan.original_price)}
                      </span>
                    </SpanFlexRow>

                    <SpanFlexRow>
                      <strong>Valor com desconto:</strong>
                      <Dotted />
                      <span>
                        {' '}
                        {valueToCurrency(subscription.plan.discount_price)}
                      </span>
                    </SpanFlexRow>

                    <div className="services">
                      {subscription.customerService.length > 0 &&
                        (subscription.customerService.length === 1 ? (
                          <strong>Serviço:</strong>
                        ) : (
                          <strong>Serviços:</strong>
                        ))}
                      <span>
                        {serviceNameAndQuantity(subscription.customerService)}
                      </span>
                    </div>

                    {!subscription.isInfinite && (
                      <SpanFlexRow>
                        <strong>Renovação automática:</strong>
                        <Dotted />
                        {subscription.renews.map(i =>
                          i.is_active ? (
                            <ButtonStatus color="paid">Ativa</ButtonStatus>
                          ) : (
                            <ButtonStatus color="canceled">
                              Desativada
                            </ButtonStatus>
                          ),
                        )}
                      </SpanFlexRow>
                    )}

                    {!subscription.isInfinite && subscription.renews.map(
                      item =>
                        item.cycle !== null && (
                          <SpanFlexRow>
                            <strong>Ciclo de renovação:</strong>
                            <Dotted />
                            {subscription.renews.map(cycle => (
                              <span>{cycle.cycle}ª</span>
                            ))}
                          </SpanFlexRow>
                        ),
                    )}

                    {subscription.cancelAt && (
                      <div className="divider">
                        <h3>Cancelado</h3>
                        <SpanFlexRow>
                          <strong>Data do cancelamento:</strong>
                          <Dotted />
                          <span>
                            {format(
                              parseISO(subscription.cancelAt),
                              'dd-MM-yyyy',
                            )}
                          </span>
                        </SpanFlexRow>
                        <div className="cancellation">
                          <strong>Motivo:</strong>
                          <div className="reason--area">
                            {subscription.cancellationReason}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ContentPlan>
            </Box>

            <Box>
              <ContentRevenues>
                <h1>Financeiro</h1>

                <SpanFlexRow>
                  <strong>Contratado:</strong>
                  <Dotted />
                  <span>
                    {format(parseISO(subscription.createdAt), 'dd-MM-yyyy')}
                  </span>
                </SpanFlexRow>

                <SpanFlexRow>
                  {!subscription.isInfinite && (
                    <>
                      <strong>Vencimento:</strong><Dotted /><span>
                        {format(parseISO(subscription.dueDate), 'dd-MM-yyyy')}
                      </span>
                    </>
                  )}
                </SpanFlexRow>

                <SpanFlexRow>
                  <strong>Pagamentos realizado:</strong>
                  <Dotted />
                  <span>{incomingBills.length.toString().padStart(2, '0')}</span>
                </SpanFlexRow>

                <SpanFlexRow>
                  <strong>Multa cobrada:</strong>
                  <Dotted />
                  <span>{outgoingBills.length ? valueToCurrency(outgoingBills[0].price) : 'Não houve'}</span>
                </SpanFlexRow>

                <SpanFlexRow>
                  <strong>Visitas realizadas:</strong>
                  <Dotted />
                  <span>
                    {
                      subscription.customerService
                      .filter((customerService) => customerService.status === 'used')
                      .length
                      .toString()
                      .padStart(2, '0')
                    }
                  </span>
                </SpanFlexRow>

                {subscription.employee && (
                  <div className="divider">
                    <h3>Funcionária(o) que vendeu:</h3>
                    <span>{subscription.employee.name}</span>
                  </div>
                )}

                {subscription.platform && (
                  <div className="divider">
                    <h3>Contratado na plataforma:</h3>
                    <span>
                      {t(
                        `pages.subscription.platform.${subscription.platform}`,
                      )}
                    </span>
                  </div>
                )}
              </ContentRevenues>
            </Box>
          </Content>
        )
      )}
    </Container>
  );
};

export default Detail;
