import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 12px;
  background-color: transparent;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  background: transparent;

  .card-content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    padding: 20px 30px;
  }

  > h3 {
    padding-bottom: 30px;
  }

  .card-saved {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    .active {
      padding: 0;
    }

    h3 {
      padding-top: 20px;
      padding-left: 20px;
    }
  }
`;

export const LoadingDiv = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  color: #fff;
`;

export const ButtonIcon = styled.button`
  border: none;
`;

export const AddNewCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #eee;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
  margin-bottom: 10px;

  .add-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .dotted {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dotted #ccc;
      border-radius: 15px;
      margin: 20px;
      padding: 40px 0;

      p + p {
        margin-top: 8px;
        font-size: 18px;
        color: var(--red);
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        svg {
          font-size: 50px;
          opacity: 0.3;
          margin-bottom: 15px;
          cursor: pointer;
          transition: all 0.2s ease;

          :hover {
            font-size: 60px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 20px;
`;
