import React, { createContext, useState, useContext, useCallback } from 'react';

import api from 'services/api';

import { useToasts } from 'react-toast-notifications';
import { useAuth } from 'hooks/auth';
import {FetchUsersParams, IUsers, IUserUpdate} from 'hooks/users/types';

interface UsersContextData {
  loading: boolean;
  setPage: any;
  page: number;
  setLimit: any;
  limit: number;
  total: number;
  users: IUsers[];
  usersActive: IUsers[];
  loadingSubmitOrUpdate: boolean;
  fetchUsers(params?: FetchUsersParams): void;
  fetchUsersActive(): Promise<void>;
  submit(values: IUsers): Promise<void>;
  update(values: IUserUpdate): Promise<void>;
}

const UsersContext = createContext<UsersContextData>({} as UsersContextData);

const UsersProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [users, setUsers] = useState<IUsers[]>([] as IUsers[]);
  const [usersActive, setUsersActive] = useState<IUsers[]>([] as IUsers[]);
  const [loadingSubmitOrUpdate, setLoadingSubmitOrUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const { addToast } = useToasts();

  const fetchUsers = useCallback(async (params?: FetchUsersParams) => {
    try {
      setLoading(true);
      const isFranchise = user.email.includes('@sd.com.br')
      const { data } = await api.get('franchises', {
        params: {
          page,
          limit,
          email: isFranchise ? user.email : params?.email
        }
      });

      setUsers(data.items);
      setPage(data.page);
      setLimit(data.limit);
      setTotal(data.total);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [limit, page, user.email]);

  const fetchUsersActive = useCallback(async () => {
    try {
      // setLoading(true);
      // const response = await api.get(`users`);
      // if (user.email.includes('@sd.com.br')) {
      //   setUsers(
      //     response.data.data.filter((item: any) => item.email === user.email),
      //   );
      // } else {
      //   setUsers(response.data.data);
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const submit = useCallback(
    async (values: IUsers) => {
      try {
        setLoadingSubmitOrUpdate(true);
        const data = {
          ...values,
        };
        const response = await api.post('users', data);
        addToast('Usuário criado com sucesso.', {
          appearance: 'success',
          autoDismiss: true,
        });

        setUsers([...users, response.data]);
      } catch (err) {
        addToast('Algo deu errado.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
        setLoadingSubmitOrUpdate(false);
      }
    },
    [addToast, users],
  );

  const update = useCallback(
    async ({ data, id }: IUserUpdate) => {
      try {
        setLoadingSubmitOrUpdate(true);
        const response = await api.patch(`users/${id}`, {
          ...data,
        });

        const indexUsers = users.findIndex(item => item.id === id);
        users[indexUsers] = response.data;

        addToast('Atualizado com sucesso.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingSubmitOrUpdate(false);
      }
    },
    [users, addToast],
  );

  return (
    <UsersContext.Provider
      value={{
        loading,
        users,
        usersActive,
        setPage,
        page,
        setLimit,
        limit,
        total,
        fetchUsers,
        fetchUsersActive,
        update,
        submit,
        loadingSubmitOrUpdate,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

function useUsers(): UsersContextData {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error(' useUsers must be used within an UsersProvider ');
  }
  return context;
}
export { UsersProvider, useUsers };
