import React from 'react';
import { AiOutlineEye } from "react-icons/ai";
import Tooltip from '@material-ui/core/Tooltip';

import { IUsers } from "../../../../hooks/users/types";

import Loading from 'components/Loading';
import DataNotFound from 'components/Lottie/DataNotFound';

import { Container } from './styles';
import {useAuth} from "../../../../hooks/auth";

type FranchisesTableProps = {
  loading: boolean;
  franchises: IUsers[];
}
const FranchisesTable: React.FC<FranchisesTableProps> = ({
  loading,
  franchises
}) => {
  const { signInAdmin } = useAuth()
  return (
    <Container>
      <table className="table table-striped table-hover">
        <thead>
        <tr>
          <td>#</td>
          <td>Nome</td>
          <td>Razão social</td>
          <td>Email</td>
          <td>CNPJ</td>
          <td align="center" style={{ width: '100px' }}>
            Ações
          </td>
        </tr>
        </thead>
        {loading ? (
          <tbody>
          <tr>
            <td colSpan={100} style={{ background: '#fff' }}>
              <Loading marginTop={50} />
            </td>
          </tr>
          </tbody>
        ) : (
          <tbody>
          {franchises.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>
                <strong>{item.name}</strong>
              </td>
              <td>{item.companyName}</td>
              <td>{item.email}</td>
              <td>{item.cnpj}</td>
              <td align="center" className="td-actions">
                <span>
                  <Tooltip
                    title="Selecionar"
                    className="see-more-icon"
                    onClick={() => signInAdmin(item.email)}
                  >
                    <span>
                      <AiOutlineEye size={20} />
                    </span>
                  </Tooltip>
                </span>
              </td>
            </tr>
          ))}
          </tbody>
        )}
      </table>
      {!loading && franchises.length === 0 && (
        <div className="customer-not-found">
          <DataNotFound />
        </div>
      )}
    </Container>
  );
};

export { FranchisesTable }
