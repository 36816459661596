import React, { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { CustomerProvider } from './customer';
import { PlansProvider } from './Plans';
import { ReportProvider } from './reports';
import { SubscriptionsProvider } from './subscriptions';
import { UsersProvider } from './users';
import { UnitSelectedProvider } from './unitSelected';
import { NotificationProvider } from './notification';
import { EmployeesProvider } from './employees';
import { ContractProvider } from './contracts';
import { CardsProvider } from './cards';
import { RevenuesProvider } from './revenues';

type Props = {
  components: React.JSXElementConstructor<React.PropsWithChildren<any>>[]
  children: ReactNode
}

const Compose = ({ components, children }: Props) => (
  <>
    {components.reduceRight((acc, Component) => <Component>{acc}</Component>, children)}
  </>
)

const AppProvider: React.FC = ({ children }) => (
  <Compose
    components={[
      AuthProvider,
      RevenuesProvider,
      CardsProvider,
      EmployeesProvider,
      NotificationProvider,
      UnitSelectedProvider,
      CustomerProvider,
      PlansProvider,
      ReportProvider,
      SubscriptionsProvider,
      UsersProvider,
      ContractProvider
    ]}>
    {children}
  </Compose>
)

export default AppProvider;
