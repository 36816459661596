import React, { createContext, useState, useContext, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import api from 'services/api';
import { useToasts } from 'react-toast-notifications';
import { fingerPrint } from 'utils/fingerPrint';
import { ISubscriptions, IGateway, Fetch } from './types';

interface SubscriptionsContextData {
  setPage: any;
  page: number;
  setLimit: any;
  limit: number;
  total: number;
  loading: boolean;
  subscriptionError: any;
  loadingModalDetail: boolean;
  loadingUpdate: boolean;
  setLoadingModalDetail: any;
  loadingSubscriptioUpdate: boolean;
  subscriptions: ISubscriptions[];
  subscription: ISubscriptions;
  subscriptionId: any;
  errorPayment: any;
  errorUpdate: any;
  setSubscriptionId: any;
  identifierCustomer: string;
  setIdentifierCustomer: any;
  duePayment(id: number): Promise<void>;
  revenueRetry(id: number): Promise<void>;
  update(values: any): Promise<void>;
  updateRenews(id: number): Promise<void>;
  updateAnticipate(id: number): Promise<void>;
  fetchSubscriptions(data: Fetch): Promise<void>;
  fetchSubscriptionDetail(id: number): Promise<void>;
  handlePayment(value: IGateway): Promise<void>;
}

const SubscriptionsContext = createContext<SubscriptionsContextData>(
  {} as SubscriptionsContextData,
);

const SubscriptionsProvider: React.FC = ({ children }) => {
  const [subscriptionError, setSubscriptionError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingModalDetail, setLoadingModalDetail] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [errorPayment, setErrorPayment] = useState();
  const [errorUpdate, setErrorUpdate] = useState();
  const [subscriptions, setSubscriptions] = useState<ISubscriptions[]>(
    [] as ISubscriptions[],
  );
  const [subscription, setSubscription] = useState<ISubscriptions>(
    {} as ISubscriptions,
  );
  const [loadingSubscriptioUpdate, setLoadingSubscriptioUpdate] =
    useState(false);
  const [identifierCustomer, setIdentifierCustomer] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const { addToast } = useToasts();

  const history = useHistory();

  const handlePayment = useCallback(
    async (value: IGateway) => {
      setLoading(true);

      const resCustomer = await api.get(`customers/${value.identifier}`);
      const customer = resCustomer.data;

      try {
        await api.post('subscriptions', {
          isCardHolder: 0,
          employee: value.employed,
          planUser: value.plan,
          deviceFingerprintID: fingerPrint(),
          customerIdentifier: value.identifier,
          city: customer.city,
          district: customer.neighborhood,
          country: customer.country,
          state: customer.state,
          postalCode: customer.postalCode,
          street: [customer.address],
        });
      } catch (err: any) {
        setErrorPayment(err);
        console.log(err);

        const { errors, paymentError } = err.response.data;

        if (paymentError) {
          if (paymentError.length > 0) {
            addToast(paymentError[0], {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }

        if (errors.length > 0) {
          const error = errors[0];

          if (error.parameter) {
            addToast(error.parameter, {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  const fetchSubscriptions = useCallback(
    async (data: Fetch) => {
      try {
        setLoading(true);

        const response = await api.get('/subscriptions', {
          params: {
            customer_identifier: data.data.customer_identifier,
            status: data.data.status,
            unit_email: data.data.unit_email,
            sort_order: 'desc',
            sort_by: 'id',
            limit,
            page,
          },
        });

        setSubscriptions(response.data.items);
        setPage(response.data.page);
        setLimit(response.data.limit);
        setTotal(response.data.total);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [page, limit],
  );

  const fetchSubscriptionDetail = useCallback(async id => {
    try {
      setLoadingModalDetail(true);
      const response = await api.get(`subscriptions/${id}`);

      setSubscription(response.data.data);

      setIdentifierCustomer(response.data.data.customer.identifier);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingModalDetail(false);
    }
  }, []);

  const update = useCallback(
    async ({ value, id }: any) => {
      try {
        setLoadingSubscriptioUpdate(true);

        const response = await api.put(`subscriptions/${id}`, {
          cancellationReason: value.cancellationReason,
        });

        const indexSubscription = subscriptions.findIndex(
          item => item.id === id,
        );
        subscriptions[indexSubscription] = response.data;

        addToast('Assinatura cancelada com sucesso.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err: any) {
        const { errors } = err.response.data;
        setSubscriptionError(errors);

        addToast('errors', {
          appearance: 'error',
          autoDismiss: true,
        });

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoadingSubscriptioUpdate(false);
      }
    },
    [subscriptions, addToast],
  );

  const updateRenews = useCallback(
    async (id: number) => {
      try {
        setLoadingModalDetail(true);
        await api.post(`/renews/${id}/active`);

        addToast('Atualizado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err: any) {
        const { errors } = err.response.data;
        setErrorUpdate(errors);

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoadingModalDetail(false);
      }
    },
    [addToast],
  );

  const updateAnticipate = useCallback(
    async (id: number) => {
      try {
        setLoadingModalDetail(true);
        await api.post(`/renews/${id}/anticipate`);

        addToast('Atualizado com sucesso', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (err: any) {
        setErrorUpdate(err);

        const { errors, paymentError } = err.response.data;

        if (paymentError) {
          if (paymentError.length > 0) {
            addToast(paymentError[0], {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }

        if (errors.length > 0) {
          const error = errors[0];

          if (error) {
            addToast(error.message || error, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } finally {
        setLoadingModalDetail(false);
      }
    },
    [addToast],
  );

  const revenueRetry = useCallback(async (id: number) => {
    console.log(id);
    try {
      setLoadingModalDetail(true);
      await api.post(`/charges/${id}/retry`);

      addToast('Efetuando tentativa de cobrança.', {
        appearance: 'info',
        autoDismiss: true,
      });
    } catch (err: any) {
      const { errors } = err.response.data;
      setErrorUpdate(errors);

      if (errors.length > 0) {
        const error = errors[0];

        if (error) {
          addToast(error.message || error, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } finally {
      setLoadingModalDetail(false);
    }
  }, []);

  const duePayment = useCallback(async (id: number) => {
    try {
      setLoadingUpdate(true);
      await api.post(`/subscriptions/${id}/due-date`);

      addToast('Vencimento alterado com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err: any) {
      const { errors } = err.response.data;
      setErrorUpdate(errors);

      if (errors.length > 0) {
        const error = errors[0];

        if (error) {
          addToast(error.message || error, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } finally {
      setLoadingUpdate(false);
    }
  }, []);

  return (
    <SubscriptionsContext.Provider
      value={{
        setPage,
        page,
        setLimit,
        limit,
        total,
        update,
        loading,
        revenueRetry,
        duePayment,
        loadingModalDetail,
        setLoadingModalDetail,
        identifierCustomer,
        setIdentifierCustomer,
        updateAnticipate,
        updateRenews,
        errorUpdate,
        errorPayment,
        subscriptions,
        subscription,
        subscriptionId,
        setSubscriptionId,
        subscriptionError,
        handlePayment,
        loadingUpdate,
        fetchSubscriptions,
        fetchSubscriptionDetail,
        loadingSubscriptioUpdate,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

function useSubscriptions(): SubscriptionsContextData {
  const context = useContext(SubscriptionsContext);

  if (!context) {
    throw new Error(
      ' useSubscriptions must be used within an SubscriptionsProvider ',
    );
  }
  return context;
}
export { SubscriptionsProvider, useSubscriptions };
