import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Header = styled.div`
  background: #fff;
  width: 100%;
  box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.1);

  .content {
    display: flex;
    height: 66px;
    width: 100%;
    max-width: 1400px;
    margin: 0px auto;
    align-items: center;
    padding-right: 20px;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 36px;

    .container {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  .profile {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    text-align: right;

    small {
      font-size: 11px;
    }
  }
`;

export const Grid = styled.div`
  width: 100%;
  max-width: 1400px;
  min-width: 900px;
  padding: 36px;
  margin: 0px auto;
`;

export const Logo = styled.div`
  padding-right: 8px;

  img {
    height: 40px;
  }
`;
