import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
// import { startOfMonth, endOfToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useReport } from 'hooks/reports';

export default function DateRangeContainer() {
  // const startMonth = startOfMonth(new Date());
  // const endToday = endOfToday();

  // const [value, setValue] = React.useState<DateRange<Date>>([
  //   startMonth,
  //   endToday,
  // ]);

  const { value, setValue } = useReport();

  return (
    <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Início"
        endText="Final"
        value={value}
        onChange={newValue => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => {
          const initial = {
            ...startProps,
            inputProps: {
              ...startProps.inputProps,
              placeholder: 'dd/mm/aaaa',
            },
          };
          const end = {
            ...endProps,
            inputProps: {
              ...endProps.inputProps,
              placeholder: 'dd/mm/aaaa',
            },
          };
          return (
            <>
              <TextField {...initial} size="small" />
              <Box sx={{ mx: 2 }}> até </Box>
              <TextField {...end} size="small" />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
}
