import React, { useState } from 'react';

import Cards from 'react-credit-cards';

import { useCards } from 'hooks/cards';
import Input from 'components/Input';
import InputPassword from 'components/Input/password';
import Select from 'components/Select';
import { months, years } from 'utils/calendar';
import { customMask } from 'utils/mask';
import { addNewCardSchema } from 'validations';

import { useFormik } from 'formik';
import Button from 'components/Button';
import { Container, Grid } from './styles';

const AddNewCardData: React.FC = () => {
  const { handleCreate } = useCards();
  const [focus, setFocus] = useState(false);

  const identifier = '00758592388';

  const handleSubmit = async (values: any) => {
    if (identifier !== undefined) {
      await handleCreate({ ...values, identifier });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
    },
    validationSchema: addNewCardSchema,
    onSubmit: value => handleSubmit(value),
  });

  return (
    <Container>
      <div className="header">
        <h1>SD LOVER</h1>
        <p>Cadastrar novo cartão</p>
      </div>
      <div className="data-customer">
        <div className="data-customer-detail customer">
          <span>
            <strong>Nome:</strong> Maria Antônia
          </span>
          <span>
            <strong>CPF:</strong> 045.654.654-88
          </span>
        </div>
        <div className="data-customer-detail unit">
          <span>
            <strong>Unidade:</strong> Fortaleza Sul
          </span>
          <span>
            <strong>CNPJ:</strong> 14.456.654/0001-55
          </span>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="data">
          <div className="card">
            <Cards
              cvc={focus ? '***' : formik.values.cvv}
              expiry={`${formik.values.month}${formik.values.year}`}
              focused={formik.values.cvv ? 'cvc' : 'name'}
              name={formik.values.name}
              number={formik.values.number}
            />
          </div>
          <div className="data-card">
            <div className="grid">
              <Grid grid={1}>
                <Input
                  name="name"
                  label="Nome"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.errors.name}
                  shrink
                />

                <Input
                  type="text"
                  name="number"
                  label="Número do Cartão"
                  onChange={(event: any) => {
                    const { value } = event.target;
                    const mask = customMask(value, '9999 9999 9999 9999');
                    formik.setFieldValue('number', mask);
                  }}
                  value={formik.values.number}
                  error={formik.errors.number}
                />
              </Grid>
              <Grid grid={3}>
                <Select
                  name="month"
                  label="Mês"
                  onChange={formik.handleChange}
                  error={formik.errors.month}
                >
                  <option value="">...</option>
                  {months.map((item: any) => (
                    <option key={item.month} value={item.month}>
                      {item.month}
                    </option>
                  ))}
                </Select>
                <Select
                  name="year"
                  label="Ano"
                  onChange={formik.handleChange}
                  error={formik.errors.year}
                >
                  <option value="">...</option>
                  {years(15).map((item: any) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <div className="cvc">
                  <InputPassword
                    name="cvv"
                    label="cvv"
                    onChange={(event: any) => {
                      const { value } = event.target;
                      const mask = customMask(value, '9999');
                      formik.setFieldValue('cvc', mask);
                    }}
                    value={formik.values.cvv}
                    error={formik.errors.cvv}
                    setOnFocus={setFocus}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </div>
        <div className="btn-area">
          <Button color="danger" type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default AddNewCardData;
