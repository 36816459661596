import { usePlans } from 'hooks/Plans';
import react, { useEffect } from 'react';
import { valueToCurrency } from 'utils/mask';

import { Container, Content } from './styles';

const Plans: React.FC = () => {
  const { fetchPlans, plans } = usePlans();

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return (
    <Container>
      <div className="header">
        <strong>Planos:</strong> Todos
      </div>
      <Content>
        <div className="grid">
          {plans.map(item => (
            <div className="card-plan">
              <div className="header">
                <div className="id"> #{item.id}</div>
              </div>

              <div className="plan-info">
                <h2>{item.name}</h2>
                <div className="detail">
                  <span>
                    {`${item.discount}% ${valueToCurrency(
                      item.original_price,
                    )} por `}
                  </span>

                  <span className="green-price">
                    {valueToCurrency(item.price)}
                  </span>
                </div>
              </div>
              <div className="table">
                <div className="tab-info">
                  <div className="service">Serviços</div>
                  {item.services.map(service => (
                    <span>
                      {service.quantity}x {service.name}
                      {service.name.includes('1º') && (
                        <span style={{ marginLeft: '5px', color: '#00b300' }}>
                          Cortesia
                        </span>
                      )}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Content>
    </Container>
  );
};

export default Plans;
