import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 25px;
  justify-content: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  width: 100%;
  padding: 20px 10px;
`;

export const Dotted = styled.div`
  border-bottom: dotted;
  border-width: 2px;
  width: 100%;
  color: #999;
  margin: 5px;
`;

export const SpanFlexRow = styled.span`
  display: flex;
  width: 100%;
  white-space: nowrap;
`;

export const Box = styled.div`
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 15px 25px 25px;
  background: #fff;

  h1 {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 18px;

    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  h3 {
    display: flex;
    border-top: 1px solid #ddd;
    padding-top: 15px;
  }

  .divider {
    margin-top: 20px;
  }
`;

export const ContentPlan = styled.div`
  .flex--column {
    display: flex;
    flex-direction: column;
  }

  .services {
    display: flex;
    flex-direction: column;
    line-height: 12px;
    margin-top: 10px;

    span {
      display: inline-block;
      margin-top: 10px;
    }
  }

  .cancellation {
    .reason--area {
      border: 1px solid #ddd;
      width: 100%;
      padding: 8px;
      border-radius: 5px;
    }
  }
`;

export const ContentRevenues = styled.div`
  display: flex;
  flex-direction: column;
`;
