import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Modal from 'components/Modal';
import { useSubscriptions } from 'hooks/subscriptions';
import { Container } from './styles';

import ModalConfirmCancel from '../modalConfirmCancel';
import {useAuth} from "../../../../hooks/auth";

const Navigation: React.FC = () => {
  const {
    fetchSubscriptionDetail,
    subscription,
    subscriptionId,
    update,
    subscriptionError,
    loadingModalDetail,
  } = useSubscriptions();
  const { user } = useAuth()
  const { pathname } = useLocation();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);

  const id = localStorage.getItem('@ClubSd:subscriptionId');
  const hasRenew = !subscription.isInfinite;

  useEffect(() => {
    if (id) {
      const subId = parseInt(id, 10);
      fetchSubscriptionDetail(subId);
    }
  }, [fetchSubscriptionDetail, id, subscriptionId, subscription.id]);

  const handleCancelSignature = async (value: string) => {
    const values = { id, value };
    await update(values);

    if (!subscriptionError) {
      setIsVisible(false);
    }
    if (id) {
      fetchSubscriptionDetail(parseInt(id , 10));
    }
  };

  const hanleBack = () => {
    history.push('/signatures');
    window.location.reload();
  };

  return (
    <Container>
      <div className="header">Dados do cliente</div>
      <div className="nav">
        <ul>
          <li>
            <Link
              to="/signature/detail"
              className={pathname === '/signature/detail' ? 'active' : ''}
            >
              Resumo detalhado
            </Link>
          </li>
          <li>
            <Link
              to="/signature/revenue"
              className={pathname === '/signature/revenue' ? 'active' : ''}
            >
              Pagamentos
            </Link>
          </li>
          <li>
            <Link
              to="/signature/visits"
              className={pathname === '/signature/visits' ? 'active' : ''}
            >
              Visitas
            </Link>
          </li>
          <li>
            <Link
              to="/signature/fees"
              className={pathname === '/signature/fees' ? 'active' : ''}
            >
              Multas
            </Link>
          </li>
          <li>
            <Link
              to="/signature/refunds"
              className={pathname === '/signature/refunds' ? 'active' : ''}
            >
              Estornos
            </Link>
          </li>
          {hasRenew && (
            <li>
              <Link
                to="/signature/renews"
                className={pathname === '/signature/renews' ? 'active' : ''}
              >
                Renovação
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/signature/cards"
              className={pathname === '/signature/cards' ? 'active' : ''}
            >
              Cartões salvos
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn">
        {subscription?.id && !loadingModalDetail && (
          <>
            {subscription.status === 'success' ||
            subscription.status === 'delayed' ? (
              <Button
                color="danger"
                className="back"
                onClick={() => setIsVisible(true)}
                style={{ width: '90%' }}
              >
                Cancelar assinatura
              </Button>
            ) : (
              ''
            )}
          </>
        )}

        <Button className="back" onClick={hanleBack} style={{ width: '90%' }}>
          Voltar
        </Button>
      </div>

      {isVisible && (
        <Modal status={isVisible} handleClose={setIsVisible}>
          <ModalConfirmCancel
            show={isVisible}
            subscription={subscription}
            setShow={setIsVisible}
            handleCancelSignature={handleCancelSignature}
          />
        </Modal>
      )}
    </Container>
  );
};

export default Navigation;
