import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 280px;
  border-right: 1px solid #eee;
  background-color: #fafafa;
  height: calc(100vh - 180px);
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  overflow-y: auto;

  .header {
    display: flex;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #fff;
    background-color: var(--primary);
    color: #fff;
    border-top-left-radius: 12px;
    font-size: 20px;
  }

  .btn {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;

    button + button {
      margin-top: 10px;
    }
  }

  .nav {
    display: flex;
    width: 100%;
    margin-top: 20px;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      list-style: none;

      li {
        a {
          display: flex;
          padding: 20px 20px;
          border-left: 2px solid transparent;
          cursor: pointer;
          transition: all ease 0.2s;

          :hover {
            color: var(--primary);
            background-color: #eee;
            border-left: 2px solid var(--primary);
          }
        }
      }

      .active {
        color: var(--primary);
        background-color: #eee;
        border-left: 2px solid var(--primary);
      }
    }
  }
`;
