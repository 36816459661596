import React from 'react';

import TextField from '@material-ui/core/TextField';

interface ISelect {
  disabled?: boolean;
  label: string;
  name: string;
  value?: any;
  error?: string;
  onChange?: any;
  onClick?: any;
  size?: 'small' | 'medium';
}

const Select: React.FC<ISelect> = ({
  disabled,
  label,
  name,
  size = 'medium',
  children,
  error,
  onChange,
  onClick,
  value,
  ...rest
}) => {
  return (
    <TextField
      id="outlined-select-currency-native"
      name={name}
      select
      disabled={disabled}
      error={!!error}
      label={label}
      size={size}
      value={value}
      onChange={onChange}
      onClick={onClick}
      style={{ width: '100%' }}
      helperText={error}
      SelectProps={{
        native: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      {...rest}
    >
      {children}
    </TextField>
  );
};

export default Select;
